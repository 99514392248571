import {
  CREATE_RMA_REQUEST,
  CREATE_RMA_SUCCESS,
  CREATE_RMA_FAILURE,
  LOAD_RMA_MATCHES_REQUEST,
  LOAD_RMA_MATCHES_SUCCESS,
  LOAD_RMA_MATCHES_FAILURE,
  CLEAR_ALL_RMAS,
} from '../actions/types';

const initialState = {
  matchingPOData: null,
  matchingSOData: null,
  matchingInvetoryItems: null,
  loading: false,
  errors: null,
};

const paymentReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case LOAD_RMA_MATCHES_REQUEST:
    case CREATE_RMA_REQUEST: {
      return { ...state, loading: true };
    }
    case LOAD_RMA_MATCHES_SUCCESS: {
      return {
        ...state,
        ...payload,
        loading: false,
      };
    }
    case CLEAR_ALL_RMAS: {
      return initialState;
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default paymentReducer;
