import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import Action from '../common/action';
import DynamicElement from '../common/dynamicElement';
import Fade from 'react-reveal/Fade';
import { useInView } from 'react-intersection-observer';
const textLeftYoutubeRight = ({
  youtubeLink,
  action,
  message,
  title,
  backgroundColor,
  textColor,
  titleElement,
  id,
}) => {
  const { ref, inView } = useInView({
    threshold: 0,
    rootMargin: '400px',
    triggerOnce: true,
  });

  return (
    <Fade bottom>
      <section id={id} className={cn({ [backgroundColor]: backgroundColor })} ref={ref}>
        <div className="content-wrapper flex items-center flex-col md:flex-row">
          <div
            className={cn('w-full md:w-6/12 text-center md:text-right mb-16 md:mb-0 px-5 order-1 md:order-2', {
              [textColor]: textColor,
            })}
          >
            <DynamicElement
              element={titleElement}
              content={title}
              className="text-2xl sm:text-4xl md:text-5xl font-medium mb-10 leading-snug sm:leading-snug md:leading-snug"
            />
            <p className="leading-loose text-base sm:text-lg">{message}</p>
            {action && <Action {...action} className="m-auto md:ml-auto md:mr-0 mt-5" />}
          </div>
          <div className="w-full md:w-6/12 px-5 order-1 md:order-2">
            {inView && (
              <iframe
                className="m-auto w-full h-auto min-h-300"
                src={youtubeLink}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen="allowFullScreen"
              />
            )}
          </div>
        </div>
      </section>
    </Fade>
  );
};

textLeftYoutubeRight.defaultProps = {
  textColor: 'text-ti-black',
  backgroundColor: 'bg-white',
  titleElement: 'p',
  action: null,
};

textLeftYoutubeRight.propTypes = {
  youtubeLink: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  action: PropTypes.shape({}),
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  titleElement: PropTypes.string,
  id: PropTypes.string.isRequired,
};

export default textLeftYoutubeRight;
