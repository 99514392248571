import { LOAD_DOMAIN_REQUEST, LOAD_DOMAIN_SUCCESS, LOAD_DOMAIN_FAILURE, SET_DOMAIN } from '../actions/types';

const initialState = {
  loading: true,
  features: [],
  logo: null,
  error: null,
};

const domainReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_DOMAIN: {
      return {
        ...state,
        ...payload,
        loading: false,
      };
    }

    case LOAD_DOMAIN_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case LOAD_DOMAIN_SUCCESS: {
      return {
        ...state,
        ...payload,
        loading: false,
      };
    }
    case LOAD_DOMAIN_FAILURE: {
      return {
        ...state,
        error: payload,
        loading: false,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default domainReducer;
