import {
  LOAD_PAYMENTS_FAILURE,
  LOAD_PAYMENTS_REQUEST,
  LOAD_PAYMENTS_SUCCESS,
  LOAD_PAYMENT_FAILURE,
  LOAD_PAYMENT_REQUEST,
  LOAD_PAYMENT_SUCCESS,
  UPDATE_PAYMENT_REQUEST,
  UPDATE_PAYMENT_SUCCESS,
  UPDATE_PAYMENT_FAILURE,
  UPDATE_PAY_PAYMENT_RECORD_REQUEST,
  UPDATE_PAY_PAYMENT_RECORD_SUCCESS,
  UPDATE_PAY_PAYMENT_RECORD_FAILURE,
  CREATE_PAYMENT_REQUEST_REQUEST,
  CREATE_PAYMENT_REQUEST_SUCCESS,
  CREATE_PAYMENT_REQUEST_FAILURE,
  CREATE_PAYMENT_REMINDER_REQUEST,
  CREATE_PAYMENT_REMINDER_SUCCESS,
  CREATE_PAYMENT_REMINDER_FAILURE,
  CLEAR_PAYMENT_RECORD,
  LOAD_PAYMENTS_STATISTICS_REQUEST,
  LOAD_PAYMENTS_STATISTICS_SUCCESS,
  LOAD_PAYMENTS_STATISTICS_FAILURE,
  UPDATE_PAYMENT_APPROVE_DECLINE_REQUEST,
  UPDATE_PAYMENT_APPROVE_DECLINE_SUCCESS,
  UPDATE_PAYMENT_APPROVE_DECLINE_FAILURE,
  UPDATE_PAYMENTS_STATUS_REQUEST,
  UPDATE_PAYMENTS_STATUS_SUCCESS,
  UPDATE_PAYMENTS_STATUS_FAILURE,
  CLEAR_ALL_PAYMENTS,
} from '../actions/types';

const initialState = {
  paymentRecord: null,
  payments: {
    items: [],
    count: 0,
  },
  loading: false,
  error: {},
  paymentStatistics: {},
};

const paymentReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case CLEAR_ALL_PAYMENTS: {
      return {
        ...state,
        payments: {
          items: [],
          count: 0,
        },
      };
    }
    case CLEAR_PAYMENT_RECORD: {
      return {
        ...state,
        paymentRecord: null,
        loading: false,
        error: {},
      };
    }
    case UPDATE_PAYMENTS_STATUS_REQUEST:
    case UPDATE_PAYMENT_APPROVE_DECLINE_REQUEST:
    case LOAD_PAYMENTS_STATISTICS_REQUEST:
    case CREATE_PAYMENT_REMINDER_REQUEST:
    case CREATE_PAYMENT_REQUEST_REQUEST:
    case UPDATE_PAY_PAYMENT_RECORD_REQUEST:
    case LOAD_PAYMENT_REQUEST:
    case UPDATE_PAYMENT_REQUEST:
    case LOAD_PAYMENTS_REQUEST: {
      return {
        ...state,
        loading: true,
        error: {},
      };
    }
    case LOAD_PAYMENTS_SUCCESS: {
      return {
        ...state,
        payments: {
          count: payload.count,
          items: [...state.payments.items, ...payload.items],
        },
        loading: false,
        error: {},
      };
    }
    case CREATE_PAYMENT_REMINDER_SUCCESS:
    case UPDATE_PAYMENT_APPROVE_DECLINE_SUCCESS:
    case UPDATE_PAYMENT_SUCCESS: {
      const matchingPaymentIndex = state.payments.items.findIndex(({ id }) => id === payload.id);
      const paymentsCopy = [...state.payments.items];
      if (matchingPaymentIndex > -1) {
        paymentsCopy.splice(matchingPaymentIndex, 1, payload);
      }
      return {
        ...state,
        payments: {
          items: paymentsCopy,
          count: state.payments.count,
        },
        loading: false,
        error: {},
      };
    }
    case LOAD_PAYMENT_SUCCESS: {
      return {
        ...state,
        paymentRecord: payload,
        loading: false,
        error: {},
      };
    }
    case CREATE_PAYMENT_REQUEST_SUCCESS:
    case UPDATE_PAY_PAYMENT_RECORD_SUCCESS: {
      return {
        ...state,
        paymentRecord: payload,
        loading: false,
        error: {},
      };
    }
    case LOAD_PAYMENTS_STATISTICS_SUCCESS: {
      return {
        ...state,
        loading: false,
        paymentStatistics: payload,
      };
    }
    case UPDATE_PAYMENTS_STATUS_SUCCESS: {
      return {
        ...state,
        payments: {
          items: state.payments.items.map(({ transactionId, ...rest }) => {
            if (payload?.successfullUpdates?.[transactionId]) {
              return { status: payload.successfullUpdates[transactionId].status, transactionId, ...rest };
            }
            return { transactionId, ...rest };
          }),
          count: state.payments.count,
        },
        failedUpdates: payload.failedUpdates,
        loading: false,
      };
    }
    case UPDATE_PAYMENTS_STATUS_FAILURE:
    case LOAD_PAYMENTS_STATISTICS_FAILURE:
    case CREATE_PAYMENT_REMINDER_FAILURE:
    case CREATE_PAYMENT_REQUEST_FAILURE:
    case UPDATE_PAY_PAYMENT_RECORD_FAILURE:
    case LOAD_PAYMENT_FAILURE:
    case UPDATE_PAYMENT_FAILURE:
    case UPDATE_PAYMENT_APPROVE_DECLINE_FAILURE:
    case LOAD_PAYMENTS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: payload,
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
};

export default paymentReducer;
