import PropTypes from 'prop-types';
import Image from 'next/image';
import SpanGenerator from '../common/spanGenerator';
import Action from '../common/action';

const Hero = ({ floatingText, title, subTitle, action1, action2, footerText, image, alt, id }) => (
  <section id={id} className="bg-black relative h-600 flex">
    {image && <Image src={image} layout="fill" objectFit="cover" priority className="absolute z-10" alt={alt} />}
    <div className="background-overlay-8 w-full z-20 relative">
      <div id="home-hero-text-container" className="text-white text-center content-wrapper">
        {floatingText && (
          <p
            className="font-semibold text-sm md:text-base md:leading-normal leading-loose"
            style={{
              textShadow: '6px 6px 0px rgba(0,0,0,0.2)',
            }}
          >
            {floatingText}
          </p>
        )}
        <SpanGenerator
          content={title}
          contentType="h1"
          textClassName="font-semibold md:text-7xl my-3 md:text-6xl text-4xl"
          spanClassName="text-ti-green"
          fontCase="upper"
          style={{
            textShadow: '6px 6px 0px rgba(0,0,0,0.2)',
          }}
        />
        {subTitle && <h2 className="md:text-lg text-base mb-5">{subTitle}</h2>}
        <div id="hero-action-container" className="flex flex-row justify-center items-center mt-10">
          {action1 && <Action {...action1} className="xs:text-sm" />}
          {action2 && (
            <Action
              {...action2}
              className="xs:text-sm ml-6"
              style={{
                textShadow: '6px 6px 0px rgba(0,0,0,0.2)',
              }}
            />
          )}
        </div>
        {footerText && (
          <p
            className="text-xl font-semibold mt-5"
            style={{
              textShadow: '6px 6px 0px rgba(0,0,0,0.2)',
            }}
          >
            1-800-359-0530
          </p>
        )}
      </div>
    </div>
  </section>
);

Hero.defaultProps = {
  floatingText: null,
  subTitle: null,
  action1: null,
  action2: null,
  footerText: null,
  background: null,
  image: null,
  alt: '',
};

Hero.propTypes = {
  floatingText: PropTypes.string,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  action1: PropTypes.shape({
    type: PropTypes.string,
    link: PropTypes.string,
    text: PropTypes.string,
    external: PropTypes.bool,
  }),
  action2: PropTypes.shape({
    type: PropTypes.string,
    link: PropTypes.string,
    text: PropTypes.string,
    external: PropTypes.bool,
  }),
  footerText: PropTypes.string,
  background: PropTypes.string,
  id: PropTypes.string.isRequired,
  priorityImage: PropTypes.bool,
  image: PropTypes.string,
  alt: PropTypes.string,
};

export default Hero;
