import { useState } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import ImageCarousel from '../components/common/imageCarousel';

const useImageCarousel = ({ items, imagePageing, sliderClass }) => {
  const router = useRouter();
  const [activeItem, setActiveItem] = useState(0);

  return {
    activeItem,
    setActiveItem,
    Carousel: (
      <ImageCarousel
        router={router}
        sliderClass={sliderClass}
        items={items}
        activeItem={activeItem}
        setActiveItem={setActiveItem}
        imagePageing={imagePageing}
      />
    ),
  };
};

useImageCarousel.defaultProps = {
  imagePageing: false,
  containerClass: '',
  items: [],
};

useImageCarousel.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})),
  imagePageing: PropTypes.bool,
  containerClass: PropTypes.string,
};

export default useImageCarousel;
