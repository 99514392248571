import PropTypes from 'prop-types';

const YoutubeVideos = ({ id }) => (
  <section id={id}>
    <div className="content-wrapper">
      <h4 className="text-ti-space-grey text-center text-2xl md:text-4xl font-semibold mt-20 mb-10 leading-tight">
        LEARN ABOUT THE DROMIGHT™ TALON
      </h4>
      <div className="grid grid-rows-4 grid-cols-1 md:grid-rows-2 md:grid-cols-2">
        <div className="p-5">
          <iframe
            className="m-auto w-full h-auto min-h-220"
            src="https://www.youtube.com/embed/LTAXHbVjlac"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen="allowFullScreen"
          />
        </div>
        <div className="p-5">
          <iframe
            className="m-auto w-full h-auto min-h-220"
            src="https://www.youtube.com/embed/woulTTyFL6E"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen="allowFullScreen"
          />
        </div>
        <div className="p-5">
          <iframe
            className="m-auto w-full h-auto min-h-220"
            src="https://www.youtube.com/embed/TLVFtpJMwvE"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen="allowFullScreen"
          />
        </div>
        <div className="p-5">
          <iframe
            className="m-auto w-full h-auto min-h-220"
            src="https://www.youtube.com/embed/W1_D1Ea3glU"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen="allowFullScreen"
          />
        </div>
      </div>
    </div>
  </section>
);

YoutubeVideos.propTypes = {
  id: PropTypes.string.isRequired,
};

export default YoutubeVideos;
