import { useInView } from 'react-intersection-observer';

const YouTubeVideo = ({ id, video, title }) => {
  const { ref, inView } = useInView({
    threshold: 0,
    rootMargin: '400px',
    triggerOnce: true,
  });
  return (
    <section id={id} ref={ref}>
      <div className="content-wrapper">
        {title && <p className="text-ti-space-grey font-semibold text-3xl mb-10">{title}</p>}
        {inView && (
          <iframe
            className="m-auto w-full h-auto min-h-220 md:min-h-600"
            src={video}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen="allowFullScreen"
          />
        )}
      </div>
    </section>
  );
};
export default YouTubeVideo;
