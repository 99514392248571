import classNames from 'classnames';
import Image from 'next/image';

const AccessoryGrid = ({ id, accessories, title }) => {
  const goTo = (link) => () => {
    window.open(link, '_blank', 'noopener noreferrer');
  };

  return (
    <section id={id}>
      <div className="content-wrapper">
        {title && <p className="text-ti-space-grey font-semibold text-3xl mb-10 text-center">{title}</p>}
        <div className="flex flex-wrap gap-y-5 h-full w-full">
          {accessories.map(({ name, image, quantity, link }, index) => (
            <div key={name} className="p-5 mx-auto w-36 sm:w-48">
              <button
                className={classNames('bg-ti-grey h-auto w-full', {
                  'can-hover:hover:bg-dji-blue can-hover:hover:bg-opacity-60': link,
                  'cursor-default': !link,
                })}
                onClick={link ? goTo(link) : null}
                data-tip={link ? 'Buy Now' : null}
              >
                <Image
                  src={image.src}
                  alt={image.alt}
                  width={200}
                  height={200}
                  layout="responsive"
                  objectFit="cover"
                  lazyBoundary="500px"
                />
              </button>
              <p className="font-semibold mt-2 sm:text-normal  text-sm">{name}</p>
              {quantity && <p>x{quantity}</p>}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default AccessoryGrid;
