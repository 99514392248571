import Head from 'next/head';
import { useState, useMemo, useEffect } from 'react';
import { ImYoutube } from 'react-icons/im';
import { IoIosArrowForward } from 'react-icons/io';
import ClipLoader from 'react-spinners/ClipLoader';
import { useInView } from 'react-intersection-observer';
import cn from 'classnames';
import PropTypes from 'prop-types';
import DynamicElement from '../common/dynamicElement/DynamicElement';
import Fade from 'react-reveal/Fade';

const TwitterInteractive = ({ message, title, background, id }) => {
  const [loaded, setLoaded] = useState(false);
  const onLoad = () => {
    const checkExist = setInterval(async () => {
      if (typeof window !== 'undefined' && window.twttr) {
        clearInterval(checkExist);
        await twttr.widgets.createTweet('1402310067261636611', document.getElementById('tweet-container')).then(() => {
          setLoaded(true);
        });
      }
    }, 100);
  };
  const { ref, inView } = useInView({
    threshold: 0,
    rootMargin: '100px',
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView && !loaded) {
      onLoad();
    }
  }, [inView, loaded]);

  const TextContainer = useMemo(
    () => (
      <div className="text-center md:text-left mb-10 md:mb-0">
        <p className="font-semibold">DroMight™</p>
        <p className="text-3xl md:text-4xl font-bold mt-3 mb-10 text-ti-space-grey">{title}</p>
        <p className="leading-loose text-ti-space-grey w-full md:w-5/6 mb-5">{message}</p>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://store-terrestrialimaging-com.3dcartstores.com/DroMight"
          className="text-ti-link underline cursor-pointer flex items-center justify-center md:justify-start"
        >
          Shop All DroMight™ Products
          <IoIosArrowForward />
        </a>
      </div>
    ),
    [],
  );

  return (
    <Fade bottom>
      <section id={id} className={background} ref={ref}>
        <div className="content-wrapper">
          <DynamicElement
            element="h2"
            content="THE BEST DROP SYSTEM FOR YOUR DJI MATRICE 300 OR 200 SERIES"
            className="text-ti-black mb-16 text-xl sm:text-2xl md:text-3xl lg:text-4xl leading-normal text-center md:text-left opacity-60"
          />
          <div className="flex flex-col md:flex-row">
            <div className="md:min-h-576 w-full md:w-6/12 md:text-left px-5 flex flex-col justify-around order-2 md:order-1">
              <div className="hidden md:block">{TextContainer}</div>
              <div>
                <p className="font-semibold mb-5">Our videos featuring the Talon Drop System:</p>
                <a
                  className="font-semibold flex items-center bg-white rounded-xl shadow-lg p-5 w-full md:w-5/6 my-2 text-black"
                  href="https://www.youtube.com/watch?v=W1_D1Ea3glU"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>
                    <ImYoutube className="text-4xl text-youTube mr-5" />
                  </span>
                  Installing the Dromight™ Talon Drop System
                </a>
                <a
                  className="font-semibold flex items-center bg-white rounded-xl shadow-lg p-5 w-full md:w-5/6 my-2 text-black"
                  href="https://www.youtube.com/watch?v=TLVFtpJMwvE&t=15s"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>
                    <ImYoutube className="text-4xl text-youTube mr-5" />
                  </span>
                  Dromight™ Talon Drop System Demo
                </a>
              </div>
            </div>
            <div
              className={cn('w-full md:w-6/12 px-5 order-1 md:order-2 mb-10 md:mb-0', {
                'min-h-full': loaded,
                'min-h-576': !loaded,
              })}
            >
              <div className="block md:hidden">{TextContainer}</div>
              <div
                className={cn('bg-white rounded-xl shadow-lg md:shadow-xl m-auto', { 'h-full': !loaded })}
                style={{ maxWidth: '550px' }}
              >
                {!loaded && (
                  <div className={cn('flex justify-center items-center w-full', { 'h-full min-h-576': !loaded })}>
                    <ClipLoader color="#1DA1F2" />
                  </div>
                )}
                {inView && (
                  <>
                    <Head>
                      <script async src="https://platform.twitter.com/widgets.js" charset="utf-8" />
                    </Head>
                    <div
                      id="tweet-container"
                      className={cn({ invisible: !loaded, 'fadein twitter-card block': loaded })}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fade>
  );
};

TwitterInteractive.defaultProps = {
  background: 'ti-grey',
};

TwitterInteractive.propTypes = {
  message: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  background: PropTypes.string,
  id: PropTypes.string.isRequired,
};

export default TwitterInteractive;
