import classNames from 'classnames';
import Image from 'next/image';

const columns = {
  1: 'col-span-1',
  2: 'col-span-2',
  3: 'col-span-3',
  4: 'col-span-4',
  5: 'col-span-5',
  6: 'col-span-6',
  7: 'col-span-7',
  8: 'col-span-8',
  9: 'col-span-9',
  10: 'col-span-10',
};

const ProductGrid = ({ id, tiles, highlights, highlightsTitle, highlightsBackgroundColor }) => {
  return (
    <section id={id}>
      <div className="content-wrapper">
        <div className="grid grid-cols-10 gap-2">
          {tiles.map(({ title, description, image, colSpan, rowSpan, bgOpacity, textPosition }) => (
            <div
              key={title}
              className={classNames('relative b-md:col-span-10', {
                [`${columns[colSpan]}`]: colSpan,
                // [`${rows[rowSpan]}`]: rowSpan,
              })}
            >
              <div
                className="w-full h-full px-10 py-40 mb-60"
                style={{
                  background: 'black',
                  background: `rgba(0,0,0,${bgOpacity || 0.1})`,
                  zIndex: 1,
                }}
              >
                <div
                  className={classNames('!-mt-32', {
                    'absolute bottom-10': textPosition === 'bottom',
                  })}
                >
                  <h4 className="text-white drop-shadow-md text-3xl font-bold">{title}</h4>
                  <p className="text-white drop-shadow-md mt-5 ">{description}</p>
                </div>
              </div>
              {image?.src && (
                <Image
                  src={image.src}
                  alt={image.alt}
                  placeholder="blur"
                  blurDataURL={image?.blurDataURL}
                  objectPosition="center"
                  objectFit="cover"
                  layout="fill"
                  className="-z-10"
                  lazyBoundary="500px"
                />
              )}
            </div>
          ))}
        </div>
        {highlights?.length > 0 && (
          <div className="bg-black text-white sm:px-10 py-20" style={{ backgroundColor: highlightsBackgroundColor }}>
            {highlightsTitle && <p className="text-white text-2xl md:text-3xl text-center mb-10">{highlightsTitle}</p>}
            <div className="flex flex-wrap gap-y-5 relative">
              {highlights.map(({ image: { src, alt, height, width }, description }) => (
                <div key={description} className="p-5 w-1/2 md:w-1/3 lg:w-1/4 mx-auto justify-center">
                  <div className="m-auto w-20 h-20 md:w-32 md:h-32 flex items-center justify-center">
                    <Image
                      src={src}
                      alt={alt}
                      placeholder="blur"
                      blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mM89fhjPQAIPAMfE8cPHQAAAABJRU5ErkJggg=="
                      width={width || 120}
                      height={height || 120}
                      lazyBoundary="500px"
                      objectFit="contain"
                    />
                  </div>
                  <p className="font-semibold w-full sm:w-3/4 text-center sm:text-normal text-sm mx-auto mt-5">
                    {description}
                  </p>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default ProductGrid;
