import DynamicElement from '../common/dynamicElement';
import cn from 'classnames';
import { useRouter } from 'next/router';
import { v4 as uuid } from 'uuid';

const Specs = ({ title, titleElement, specs, notes = [], id }) => {
  const router = useRouter();

  return (
    <section id={id} className="bg-ti-grey py-10">
      <div className="content-wrapper">
        <DynamicElement
          element={titleElement}
          content={title}
          className="font-semibold text-2xl md:text-4xl my-5 text-ti-space-grey"
        />
        <hr className="mb-10 w-full" />
        {specs.map(({ title, details }, titleIndex) => (
          <div key={uuid()} className={cn({ 'mt-20': titleIndex !== 0 })}>
            <p className="font-semibold text-ti-space-grey text-xl md:text-3xl mb-4">{title}</p>
            <hr className="w-full my-2" />
            {details.map(({ spec, data, link, noteNumber }) => (
              <div key={uuid()}>
                <div className="flex flex-wrap justify-between">
                  <div className="flex w-4/12">
                    <p className="font-semibold text-ti-space-grey text-xs sm:text-sm md:text-lg mb-auto">{spec}</p>
                    {noteNumber ? <p className="text-xs sm:text-sm ml-1">{`(${noteNumber})`}</p> : ''}
                  </div>
                  <div className="w-8/12 ml-auto flex items-center">
                    {typeof data !== 'object' ? (
                      <p
                        className={cn(
                          'text-ti-space-grey text-xs sm:text-sm whitespace-pre text-left whitespace-pre-line pl-2',
                          {
                            'underline text-ti-link': link,
                          },
                        )}
                        onClick={link ? () => router.push(link) : null}
                        role={link ? 'button' : 'text'}
                      >
                        {data}
                      </p>
                    ) : (
                      data.map(({ text, link }) => (
                        <p
                          key={uuid()}
                          className={cn(
                            'text-ti-space-grey text-xs sm:text-sm whitespace-pre text-left whitespace-pre-line pl-2',
                            {
                              'underline text-ti-link': link,
                            },
                          )}
                          onClick={link ? () => router.push(link) : null}
                          role={link ? 'button' : 'text'}
                        >
                          {text}
                        </p>
                      ))
                    )}
                  </div>
                </div>
                <hr className="w-full my-2" />
              </div>
            ))}
          </div>
        ))}
        {notes?.length > 0 && (
          <div className="mt-20">
            <p className="font-semibold text-ti-space-grey text-xl md:text-3xl">Notes</p>
            <hr className="w-full my-2" />
            {notes.map(({ note, noteNumber }) => (
              <div key={uuid()} className="my-5">
                <p className="text-ti-space-grey text-sm">
                  ({noteNumber}) {note}
                </p>
              </div>
            ))}
          </div>
        )}
      </div>
    </section>
  );
};

export default Specs;
