import axios from 'axios';
// import { setupCache } from 'axios-cache-adapter';
import { errorToast } from '../utils/toast';
import { getReasonPhrase } from 'http-status-codes';
import { supabase } from '../utils/supabaseClient';
import throttle from 'lodash/throttle';

const throttledLogout = throttle(async () => {
  errorToast('Your session has expired and you have been logged out')();
  await supabase.auth.signOut();
}, 1000 * 20);

// const cache = setupCache({
//   maxAge: 15 * 60 * 1000,
//   invalidate: async (config, request) => {
//     if (!request.useCache) {
//       await config.store.removeItem(config.uuid);
//     }
//   },
// });

const http = axios.create({
  // adapter: cache.adapter,
  baseURL: '/api/',
});

const verbs = {
  CREATE: 'post',
  LOAD: 'get',
  UPDATE: 'put',
};

export const CALL_API = 'Call API';

const apiMiddlware = () => (next) => (action) => {
  const { [CALL_API]: callAPI, ...params } = action;

  if (typeof callAPI === 'undefined') {
    return next(action);
  }

  const {
    endpoint,
    headers = {},
    types,
    schema = (d) => d,
    useCache = false,
    body = {},
    successCallback = null,
    failureCallback = null,
  } = callAPI;

  const actionWith = (nextAction) => {
    const { [CALL_API]: del, ...finalAction } = { ...action, ...nextAction };
    return finalAction;
  };
  const [requestType, successType, failureType] = types;
  const verb = verbs[requestType.split('_').shift()];

  next(actionWith({ type: requestType, payload: body }));
  let options = null;
  if (verb === verbs.LOAD) {
    options = {
      cache: {
        exclude: {
          filter: () => !useCache,
          query: !Object.keys(params).length,
        },
      },
      headers,
      params,
    };
  } else {
    options = { ...params };
  }

  return http[verb](endpoint, body, options).then(
    ({ data }) => {
      if (successCallback) {
        successCallback(data);
      }
      return next(
        actionWith({
          payload: schema(data),
          type: successType,
        }),
      );
    },
    (error) => {
      if (error?.response?.data?.redirect) {
        console.log('error: ', error?.response?.data?.redirect);
        throttledLogout();
        return;
      }

      if (error?.response) {
        if (error.response?.data?.message || error?.response?.data?.errorMessage) {
          errorToast(error?.response?.data?.message || error?.response?.data?.errorMessage)();
        } else {
          errorToast(getReasonPhrase(error.response.status))();
        }
      }
      if (failureCallback) {
        failureCallback(error);
      }
      return next(
        actionWith({
          payload: error?.response?.data,
          type: failureType,
        }),
      );
    },
  );
};

export default apiMiddlware;
