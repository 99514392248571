import {
  LOAD_SHIELD_RENEW_ELIGIBLE_EQUIPMENT_REQUEST,
  LOAD_SHIELD_RENEW_ELIGIBLE_EQUIPMENT_SUCCESS,
  LOAD_SHIELD_RENEW_ELIGIBLE_EQUIPMENT_FAILURE,
  CLEAR_ELIGIBLE_EQUIPMENT,
} from '../actions/types';

const initialState = {
  eligibleEquipment: [],
  error: null,
  loading: false,
};

const djiShieldRenewReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case LOAD_SHIELD_RENEW_ELIGIBLE_EQUIPMENT_REQUEST: {
      return {
        ...state,
        error: null,
        loading: true,
      };
    }
    case LOAD_SHIELD_RENEW_ELIGIBLE_EQUIPMENT_SUCCESS: {
      return {
        ...state,
        eligibleEquipment: payload,
        error: null,
        loading: false,
      };
    }
    case LOAD_SHIELD_RENEW_ELIGIBLE_EQUIPMENT_FAILURE: {
      return {
        ...state,
        error: payload,
        loading: false,
      };
    }
    case CLEAR_ELIGIBLE_EQUIPMENT: {
      return {
        ...initialState,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default djiShieldRenewReducer;
