import { CALL_API } from '../middleware/api';
import {
  CREATE_AD_RECORD_REQUEST,
  CREATE_AD_RECORD_SUCCESS,
  CREATE_AD_RECORD_FAILURE,
  UPDATE_AD_RECORD_REQUEST,
  UPDATE_AD_RECORD_SUCCESS,
  UPDATE_AD_RECORD_FAILURE,
  LOAD_AD_RECORDS_REQUEST,
  LOAD_AD_RECORDS_SUCCESS,
  LOAD_AD_RECORDS_FAILURE,
  CLEAR_AD_RECORD,
} from './types';

export const createAdRecord = (identifier) => ({
  [CALL_API]: {
    endpoint: 'identifierTracking/createRecord',
    types: [CREATE_AD_RECORD_REQUEST, CREATE_AD_RECORD_SUCCESS, CREATE_AD_RECORD_FAILURE],
    useCache: false,
    body: { identifier },
  },
  type: 'createAdRecord',
});

export const updateAdRecord = ({ id, identifier, conversion, successCallback }) => ({
  [CALL_API]: {
    endpoint: 'identifierTracking/updateRecord',
    types: [UPDATE_AD_RECORD_REQUEST, UPDATE_AD_RECORD_SUCCESS, UPDATE_AD_RECORD_FAILURE],
    useCache: false,
    successCallback,
    body: { id, identifier, conversion },
  },
  type: 'updateAdRecord',
});

export const getAdRecords = ({ successCallback, failureCallback }) => ({
  [CALL_API]: {
    endpoint: 'identifierTracking/getRecords',
    types: [LOAD_AD_RECORDS_REQUEST, LOAD_AD_RECORDS_SUCCESS, LOAD_AD_RECORDS_FAILURE],
    useCache: false,
    successCallback,
    failureCallback,
  },
  type: 'getAdRecords',
});

export const clearAdRecord = () => (dispatch) => {
  dispatch({ type: CLEAR_AD_RECORD });
};
