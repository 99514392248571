import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Image from 'next/image';
import Action from '../common/action';
import DynamicElement from '../common/dynamicElement';
import useWindowSize from '../../hooks/useWindowSize';

const FeaturedCategories = ({ title, categories, id, titleElement }) => {
  const { width: windowWidth } = useWindowSize();
  const [largeScreen, setLargeScreen] = useState(false);

  useEffect(() => {
    if (windowWidth >= 1280 && !largeScreen) {
      setLargeScreen(true);
    }

    if (windowWidth < 1280 && largeScreen) {
      setLargeScreen(false);
    }
  }, [windowWidth]);

  return (
    <section id={id} className="bg-ti-mid-grey">
      <div id="container-wrapper" className="content-wrapper">
        <DynamicElement
          content={title}
          element={titleElement}
          className="font-bold text-center lg:text-left text-3xl mb-14"
        />
        <div className="grid grid-cols-1 grid-rows-4 md:grid-cols-4 md:grid-rows-2 lg:grid-rows-1 lg:grid-cols-3 gap-4">
          {categories?.map(
            (
              {
                title: categoryTitle,
                image,
                alt,
                action1,
                action2,
                titleElement: categoryTitleElement = 'p',
                width,
                height,
              },
              index,
            ) => (
              <div
                key={`${categoryTitle}-category`}
                className={cn('bg-ti-black py-6 rounded-xl shadow-xl text-white relative', {
                  'col-span-2 row-span-1 lg:col-span-1 lg:row-span-2': index === 0,
                  'col-span-2 row-span-1 lg:col-span-1 lg:col-span-1': index === 1 || index === 2,
                  'col-span-2 row-span-1 lg:col-span-1 lg:col-span-2': index === 3,
                })}
              >
                <div className="my-10">
                  <DynamicElement
                    content={categoryTitle}
                    element={categoryTitleElement}
                    className="text-2xl font-semibold text-center mb-5"
                  />
                  <div className="flex justify-center">
                    {action1 && <Action {...action1} className="font-semibold sm:text-xl text-ti-green" />}
                    {action2 && <Action {...action2} className="ml-6 text-ti-green sm:text-xl font-semibold" />}
                  </div>
                </div>
                <div className="flex items-center justify-center">
                  <div className="image-container">
                    {largeScreen ? (
                      <Image alt={alt} src={image} layout="fill" className="m-auto image" />
                    ) : (
                      <Image alt={alt} layout="fill" src={image.replace('.', '-medium.')} className="m-auto image" />
                    )}
                  </div>
                </div>
              </div>
            ),
          )}
        </div>
      </div>
    </section>
  );
};

FeaturedCategories.defaultProps = {
  titleElement: 'p',
};

FeaturedCategories.propTypes = {
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  categories: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  titleElement: PropTypes.string,
};

export default FeaturedCategories;
