import Action from '../common/action';

const ComingSoon = ({ title, message, action }) => (
  <div className="content-wrapper text-center">
    <h1 className="text-3xl font-semibold">{title}</h1>
    <h2 className="text-2xl py-5">{message}</h2>
    <Action {...action} className="w-60 m-auto" />
  </div>
);

export default ComingSoon;
