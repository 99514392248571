import Link from 'next/link';
import { IoIosArrowForward } from 'react-icons/io';
import cn from 'classnames';

const Action = ({
  type,
  link,
  text,
  target,
  external,
  className,
  overrideBaseCss = false,
  hiddenText = null,
  subject = null,
  showArrow = true,
  ...rest
}) => {
  const generateHiddenText = () => {
    if (hiddenText) {
      return hiddenText;
    } else if (text.toLowerCase().includes('buy') && subject) {
      return `Shop for the ${subject}`;
    } else if (text.toLowerCase().includes('learn') && subject) {
      return `Learn more about the ${subject}`;
    }
    return '';
  };

  if (external) {
    return (
      <a
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        aria-label={generateHiddenText()}
        className={cn({
          'font-semibold flex items-center': !overrideBaseCss,
          'can-hover:hover:underline can-hover:hover:cursor-pointer': type === 'link' && !overrideBaseCss,
          'ti-button ripple justify-center': type === 'button' && !overrideBaseCss,
          [className]: className,
        })}
        {...rest}
      >
        {text}
        {type !== 'button' && showArrow && <IoIosArrowForward className="font-semibold" />}
      </a>
    );
  } else {
    if (type === 'scrollButton' || type === 'scrollLink') {
      const scrollTo = () => {
        const scrollTarget = document.getElementById(target);
        if (scrollTarget) {
          scrollTarget.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
        }
      };
      return (
        <button
          aria-label={generateHiddenText()}
          type="button"
          className={cn({
            'font-semibold flex items-center': !overrideBaseCss,
            'ti-button ripple justify-center': type === 'scrollButton' && !overrideBaseCss,
            'can-hover:hover:underline can-hover:hover:cursor-pointer outline-none focus:outline-none':
              type === 'scrollLink' && !overrideBaseCss,
            [className]: className,
          })}
          onClick={rest.onClick ? rest.onClick : scrollTo}
          {...rest}
        >
          {text}
          {type !== 'scrollButton' && showArrow && <IoIosArrowForward className="font-semibold text-xl" />}
        </button>
      );
    }
    return (
      <Link href={link}>
        <a
          aria-label={`${text} ${generateHiddenText()}`}
          className={cn({
            'font-semibold flex items-center': !overrideBaseCss,
            'can-hover:hover:underline can-hover:hover:cursor-pointer': type === 'link' && !overrideBaseCss,
            'ti-button ripple justify-center': type === 'button' && !overrideBaseCss,
            [className]: className,
          })}
          {...rest}
        >
          {text}
          {type !== 'button' && showArrow && <IoIosArrowForward className="font-semibold text-xl" />}
        </a>
      </Link>
    );
  }
};

export default Action;
