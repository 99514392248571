import { useEffect, useState } from 'react';
import { MdExpandMore } from 'react-icons/md';
import throttle from 'lodash/throttle';
import cn from 'classnames';

const Accordion = ({
  children,
  className,
  summary,
  forceClose,
  forceOpen = false,
  disabled = false,
  iconClass = null,
  showIcon = true,
  childContainerClass = null,
  hrDivider = true,
  summaryContainerClass = null,
  expandedSummaryClass = null,
  expandedChildContainerClass = null,
}) => {
  const [expanded, setExpanded] = useState(forceOpen);

  const handleClick = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    if (forceClose) {
      setExpanded(false);
    }
  }, [forceClose]);

  const throttledClick = throttle(handleClick, 6000, { leading: true });
  return (
    <div id="accordion" className={`cursor-pointer my-2 ${className}`} disabled={disabled}>
      <div
        id="accordion-summary"
        className={cn(`flex justify-between items-center pt-2 pb-2 w-full relative ${summaryContainerClass}`, {
          [expandedSummaryClass]: expanded,
        })}
        role="button"
        onClick={throttledClick}
      >
        {typeof summary === 'function' ? summary(expanded) : summary}
        {showIcon && (
          <MdExpandMore
            className={cn(`text-2xl transform duration-200 absolute right-2 ${iconClass}`, { '-rotate-180': expanded })}
          />
        )}
      </div>
      {hrDivider && expanded && <hr className="w-full" />}
      <div
        className={cn(`break-words py-2 px-4 fadein ${childContainerClass}`, {
          hidden: !expanded,
          [expandedChildContainerClass]: expanded,
        })}
      >
        {children}
      </div>
    </div>
  );
};

export default Accordion;
