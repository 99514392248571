import { combineReducers } from 'redux';
import contactForm from './contactFormReducer';
import payment from './paymentReducer';
import events from './eventsReducer';
import ad from './adReducer';
import domain from './domainReducer';
import users from './usersReducer';
import product from './productReducer';
import inventory from './inventoryReducer';
import salesOrders from './salesOrderReducer';
import purchaseOrders from './purchaseOrdersReducer';
import assetManagement from './assetManagementReducer';
import shieldRenewEligible from './djiShieldRenewReducer';
import serviceTracker from './serviceTrackerReducer';
import rma from './rmaReducer';
import { DESTROY_SESSION } from '../actions/types';

const appReducer = combineReducers({
  contactForm,
  payment,
  events,
  ad,
  domain,
  users,
  product,
  inventory,
  salesOrders,
  purchaseOrders,
  assetManagement,
  shieldRenewEligible,
  serviceTracker,
  rma,
});

const rootReducer = (state, action) => {
  if (action.type === DESTROY_SESSION) {
    localStorage.removeItem('persist:root');
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};
export default rootReducer;
