import { useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useRouter } from 'next/router';
import ReCAPTCHA from 'react-google-recaptcha';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import PropTypes from 'prop-types';
import FormInput from './FormInput';
import FormSelect from './FormSelect';
import FormTextArea from './FormTextArea';
import FormMultiselect from './FormMultiSelect';
import FormFileUpload from './FormFileUpload';
import { emailRegex } from '../../../utils/regex';

const formOptions = {
  FormInput,
  FormSelect,
  FormTextArea,
  FormFileUpload,
  FormMultiselect,
};

const initialState = (formItems, page, persistedState) =>
  formItems.reduce((accu, { name }) => ({ ...accu, [name]: persistedState?.[name] ?? '' }), { page });

const ContactForm = ({
  formItems,
  submitFormRequest,
  onHandleError,
  persistFormData,
  containerClass,
  updateAdRecord,
  clearAdRecord,
  adId,
  adIdentifier,
  useCaptcha,
}) => {
  const router = useRouter();
  const { ref: captchaRef, inView } = useInView({
    threshold: 0,
    rootMargin: '500px',
    triggerOnce: true,
  });

  const persistedState = useSelector(({ contactForm: { formData = {} } }) => formData) || {};
  const { page = null, ...restPersistedState } = persistedState;

  const [formData, setFormData] = useState(
    initialState(formItems, router.asPath, router.asPath === page ? restPersistedState : { page: router.asPath }),
  );
  const [formErrors, setFormErrors] = useState({});

  const handleFieldChange = ({ target: { name, value } }) => {
    if (formErrors[name]) {
      setFormErrors({ ...formErrors, [name]: null });
    }
    const updatedFormData = { ...formData, [name]: value };
    setFormData(updatedFormData);
    persistFormData(updatedFormData);
  };

  const onCaptchaChange = (value) => {
    formData.captcha = value;
    formErrors.captcha = null;
  };

  const successCallback = (data) => () => {
    if (adId) {
      updateAdRecord({ id: adId, identifier: adIdentifier, conversion: data, successCallback: clearAdRecord });
    }

    router.push(`/formSubmitted?firstName=${data.firstName}`);
  };

  const onSubmit = (e) => {
    let hasError = false;

    const setError = () => {
      if (!hasError) {
        hasError = true;
      }
    };

    const errors = formItems.reduce((accu, { name, required }) => {
      if (name === 'email' && formData.email) {
        const validEmail = emailRegex.test(String(formData[name]).toLowerCase());
        if (!validEmail) {
          setError();
          return { ...accu, [name]: 'Please make sure to use a valid email address' };
        }
      }
      if (required && !formData[name]) {
        setError();
        return { ...accu, [name]: 'This Field Is Required' };
      }

      if (name === 'fileUpload' && (!formData[name] || !formData[name].length) && required) {
        setError();
        return { ...accu, [name]: 'Please include your PDF file(s)' };
      }
      return accu;
    }, {});

    if (useCaptcha && !formData.captcha) {
      errors.captcha = 'Please complete the Google Captcha';
      hasError = true;
    }

    setFormErrors(errors);
    if (!hasError) {
      submitFormRequest({
        ...formData,
        ...(adId ? { adIdentifier } : {}),
        page: router.asPath,
        successCallback: successCallback(formData),
        failureCallback: onHandleError('There was an error submitting your inquiry'),
      });
    }
  };

  return (
    <div id="contact-form">
      <div
        className={cn('flex flex-wrap rounded-lg px-5 py-10 sm:px-10 w-full bg-white', {
          [containerClass]: containerClass,
          'shadow-xl': !containerClass,
        })}
      >
        {formItems.map(({ type, width, name, value, disabled = false, ...rest }) => {
          if (value && !formData[name]) {
            setFormData({ ...formData, [name]: value });
          }
          const FormItem = formOptions[`Form${type.charAt(0).toUpperCase() + type.slice(1)}`];
          return (
            <FormItem
              key={name}
              containerClasses={cn('w-full sm:px-3 mb-6 relative', {
                'md:w-1/2': width === 'half',
              })}
              name={name}
              id={name}
              value={formData[name]}
              onChange={handleFieldChange}
              error={formErrors[name]}
              disabled={disabled}
              {...rest}
            />
          );
        })}
        {useCaptcha && (
          <div className="flex flex-col items-center justify-center w-full my-2" ref={captchaRef}>
            {inView && <ReCAPTCHA sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY} onChange={onCaptchaChange} />}
            {formErrors.captcha && <p className="text-red-500 text-xs italic">{formErrors.captcha}</p>}
            <button className="ti-button m-auto w-80 ripple mt-2" type="button" onClick={onSubmit}>
              Submit
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

ContactForm.defaultProps = {
  useCaptcha: true,
};

ContactForm.propTypes = {
  formItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  useCaptcha: PropTypes.bool,
};

export default ContactForm;
