import { useState } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import BasicCarousel from '../components/common/basicCarousel';

const useBasicCarousel = ({ items, sliderClass, slidesToShow }) => {
  const router = useRouter();
  const [activeItem, setActiveItem] = useState(0);

  return {
    activeItem,
    setActiveItem,
    Carousel: (
      <BasicCarousel
        router={router}
        sliderClass={sliderClass}
        items={items}
        activeItem={activeItem}
        setActiveItem={setActiveItem}
        slidesToShow={slidesToShow}
      />
    ),
  };
};

useBasicCarousel.defaultProps = {
  containerClass: '',
  items: [],
  slidesToShow: 1,
};

useBasicCarousel.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})),
  containerClass: PropTypes.string,
  slidesToShow: PropTypes.number,
};

export default useBasicCarousel;
