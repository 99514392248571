import {
  CLEAR_OPEN_PURCHASE_ORDERS,
  CLEAR_PURCHASE_ORDER,
  CLEAR_ALL_PURCHASE_ORDERS,
  LOAD_PURCHASE_ORDER_BY_PO_REQUEST,
  LOAD_PURCHASE_ORDER_BY_PO_SUCCESS,
  LOAD_PURCHASE_ORDER_BY_PO_FAILURE,
  UPDATE_PURCHASE_ORDER_BY_IDENTIFIER_REQUEST,
  UPDATE_PURCHASE_ORDER_BY_IDENTIFIER_SUCCESS,
  UPDATE_PURCHASE_ORDER_BY_IDENTIFIER_FAILURE,
  CREATE_PROCESS_PO_REQUEST,
  CREATE_PROCESS_PO_SUCCESS,
  CREATE_PROCESS_PO_FAILURE,
  LOAD_DOMAIN_PO_REQUEST,
  LOAD_DOMAIN_PO_SUCCESS,
  LOAD_DOMAIN_PO_FAILURE,
  LOAD_INCOMPLETE_DOMAIN_PO_REQUEST,
  LOAD_INCOMPLETE_DOMAIN_PO_SUCCESS,
  LOAD_INCOMPLETE_DOMAIN_PO_FAILURE,
  LOAD_TOTAL_DOMAIN_PO_REQUEST,
  LOAD_TOTAL_DOMAIN_PO_SUCCESS,
  LOAD_TOTAL_DOMAIN_PO_FAILURE,
  UPDATE_DELETE_PO_REQUEST,
  UPDATE_DELETE_PO_SUCCESS,
  UPDATE_DELETE_PO_FAILURE,
  UPDATE_PO_REQUEST,
  UPDATE_PO_SUCCESS,
  UPDATE_PO_FAILURE,
  LOAD_VENDERS_REQUEST,
  LOAD_VENDERS_SUCCESS,
  LOAD_VENDERS_FAILURE,
  CLEAR_ALL_VENDERS,
  CREATE_VENDER_REQUEST,
  CREATE_VENDER_SUCCESS,
  CREATE_VENDER_FAILURE,
  UPDATE_VENDER_REQUEST,
  UPDATE_VENDER_SUCCESS,
  UPDATE_VENDER_FAILURE,
  UPDATE_PO_ITEM_REQUEST,
  UPDATE_PO_ITEM_SUCCESS,
  UPDATE_PO_ITEM_FAILURE,
  UPDATE_DELETE_PO_ITEM_REQUEST,
  UPDATE_DELETE_PO_ITEM_SUCCESS,
  UPDATE_DELETE_PO_ITEM_FAILURE,
} from '../actions/types';
import uniqBy from 'lodash/uniqBy';

const initialState = {
  purchaseOrders: { items: [], count: 0 },
  purchaseOrder: null,
  totalPurchaseOrdersStatistics: {},
  venders: [],
  loading: false,
  errors: {},
};

const paymentReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case UPDATE_DELETE_PO_ITEM_REQUEST:
    case UPDATE_PO_ITEM_REQUEST:
    case CREATE_VENDER_REQUEST:
    case UPDATE_VENDER_REQUEST:
    case LOAD_VENDERS_REQUEST:
    case UPDATE_PURCHASE_ORDER_BY_IDENTIFIER_REQUEST:
    case LOAD_PURCHASE_ORDER_BY_PO_REQUEST:
    case UPDATE_PO_REQUEST:
    case UPDATE_DELETE_PO_REQUEST:
    case LOAD_TOTAL_DOMAIN_PO_REQUEST:
    case LOAD_INCOMPLETE_DOMAIN_PO_REQUEST:
    case LOAD_DOMAIN_PO_REQUEST:
    case CREATE_PROCESS_PO_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case UPDATE_DELETE_PO_SUCCESS: {
      return {
        ...state,
        purchaseOrders: {
          items: state.purchaseOrders.items.filter(({ id }) => id !== payload.id),
          count: state.purchaseOrders.count - 1,
        },
        loading: false,
      };
    }
    case UPDATE_PURCHASE_ORDER_BY_IDENTIFIER_SUCCESS: {
      return {
        ...state,
        loading: false,
        purchaseOrders: {
          items: uniqBy([...state.purchaseOrders.items, payload], 'id').filter(({ status }) => status === 'closed'),
          count: state.purchaseOrders.count,
        },
        purchaseOrder: payload,
        errors: {},
      };
    }
    case CREATE_PROCESS_PO_SUCCESS: {
      return {
        ...state,
        loading: false,
        errors: {},
        purchaseOrders: {
          items: [payload, ...state.purchaseOrders.items],
          count: state.purchaseOrders.count + 1,
        },
      };
    }
    case UPDATE_PO_SUCCESS: {
      return {
        ...state,
        purchaseOrder: { ...payload, order: state.purchaseOrder.order },
        errors: {},
        loading: false,
      };
    }
    case UPDATE_PO_ITEM_SUCCESS:
    case UPDATE_DELETE_PO_ITEM_SUCCESS: {
      const matchingPurchaseOrderIndex = state?.purchaseOrders?.items?.findIndex(({ id }) => id === payload.id);
      let newPurchaseOrders = [...state.purchaseOrders.items];

      if (matchingPurchaseOrderIndex > -1) {
        newPurchaseOrders.splice(matchingPurchaseOrderIndex, 1, payload);
      } else {
        newPurchaseOrders = [...newPurchaseOrders, payload];
      }

      return {
        ...state,
        purchaseOrder: payload,
        purchaseOrders: {
          items: newPurchaseOrders,
          count: matchingPurchaseOrderIndex > -1 ? state.purchaseOrders.count : state.purchaseOrders.count + 1,
        },
        errors: {},
        loading: false,
      };
    }
    case LOAD_VENDERS_SUCCESS: {
      return {
        ...state,
        venders: payload,
        loading: false,
        error: {},
      };
    }
    case LOAD_PURCHASE_ORDER_BY_PO_SUCCESS: {
      return {
        ...state,
        loading: false,
        errors: { purchaseOrder: null },
        purchaseOrder: payload,
      };
    }
    case LOAD_PURCHASE_ORDER_BY_PO_FAILURE: {
      return {
        ...state,
        loading: false,
        errors: { purchaseOrder: payload },
      };
    }
    case UPDATE_VENDER_SUCCESS: {
      return {
        ...state,
        loading: false,
        venders: state.venders.map(({ id, ...rest }) => {
          if (+id === +payload.id) {
            return payload;
          } else {
            return { id, ...rest };
          }
        }),
        error: {},
      };
    }
    case CREATE_VENDER_SUCCESS: {
      return {
        ...state,
        venders: [payload, ...state.venders],
        loading: false,
        error: {},
      };
    }
    case LOAD_DOMAIN_PO_SUCCESS: {
      return {
        ...state,
        loading: false,
        errors: {},
        purchaseOrders: { items: [...state.purchaseOrders.items, ...payload.items], count: payload.count },
      };
    }
    case CLEAR_ALL_VENDERS: {
      return {
        ...state,
        venders: [],
      };
    }
    case CLEAR_PURCHASE_ORDER: {
      return {
        ...state,
        purchaseOrder: null,
      };
    }
    case LOAD_INCOMPLETE_DOMAIN_PO_SUCCESS: {
      return {
        ...state,
        loading: false,
        errors: {},
        incompletePOs: payload,
      };
    }
    case LOAD_TOTAL_DOMAIN_PO_SUCCESS: {
      return {
        ...state,
        loading: false,
        totalPurchaseOrdersStatistics: payload,
        errors: {},
      };
    }
    case CLEAR_OPEN_PURCHASE_ORDERS: {
      return { ...state, openPurchaseOrders: { items: [], count: 0 } };
    }
    case CLEAR_ALL_PURCHASE_ORDERS: {
      return {
        ...state,
        purchaseOrders: { items: [], count: 0 },
      };
    }
    case UPDATE_PO_ITEM_FAILURE:
    case UPDATE_DELETE_PO_ITEM_FAILURE:
    case UPDATE_VENDER_FAILURE:
    case CREATE_VENDER_FAILURE:
    case LOAD_VENDERS_FAILURE:
    case UPDATE_PO_FAILURE:
    case UPDATE_DELETE_PO_FAILURE:
    case LOAD_TOTAL_DOMAIN_PO_FAILURE:
    case LOAD_INCOMPLETE_DOMAIN_PO_FAILURE:
    case CREATE_PROCESS_PO_FAILURE:
    case LOAD_DOMAIN_PO_FAILURE:
    case UPDATE_PURCHASE_ORDER_BY_IDENTIFIER_FAILURE: {
      return {
        ...state,
        loading: false,
        errors: payload,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default paymentReducer;
