import {
  LOAD_ASSETS_REQUEST,
  LOAD_ASSETS_SUCCESS,
  LOAD_ASSETS_FAILURE,
  CREATE_ASSET_REQUEST,
  CREATE_ASSET_SUCCESS,
  CREATE_ASSET_FAILURE,
  LOAD_ASSET_STATUSES_REQUEST,
  LOAD_ASSET_STATUSES_SUCCESS,
  LOAD_ASSET_STATUSES_FAILURE,
  CLEAR_ASSET_STATUSES,
  UPDATE_DELETE_ASSET_REQUEST,
  UPDATE_DELETE_ASSET_SUCCESS,
  UPDATE_DELETE_ASSET_FAILURE,
  UPDATE_ASSIGN_ASSET_REQUEST,
  UPDATE_ASSIGN_ASSET_SUCCESS,
  UPDATE_ASSIGN_ASSET_FAILURE,
  LOAD_ASSET_HISTORY_REQUEST,
  LOAD_ASSET_HISTORY_SUCCESS,
  LOAD_ASSET_HISTORY_FAILURE,
  LOAD_TICKETS_REQUEST,
  LOAD_TICKETS_SUCCESS,
  LOAD_TICKETS_FAILURE,
  LOAD_TICKET_HISTORY_REQUEST,
  LOAD_TICKET_HISTORY_SUCCESS,
  CREATE_TICKET_REQUEST,
  CREATE_TICKET_SUCCESS,
  CREATE_TICKET_FAILURE,
  LOAD_TICKET_HISTORY_FAILURE,
  CLEAR_ASSETS,
  UPDATE_TICKET_REQUEST,
  UPDATE_TICKET_SUCCESS,
  UPDATE_TICKET_FAILURE,
  CLEAR_TICKETS,
  CLEAR_ACCESSORIES,
  LOAD_ACCESSORIES_REQUEST,
  LOAD_ACCESSORIES_FAILURE,
  LOAD_ACCESSORIES_SUCCESS,
  CREATE_ACCESSORY_REQUEST,
  CREATE_ACCESSORY_SUCCESS,
  CREATE_ACCESSORY_FAILURE,
  LOAD_ACCESSORY_HISTORY_REQUEST,
  LOAD_ACCESSORY_HISTORY_SUCCESS,
  LOAD_ACCESSORY_HISTORY_FAILURE,
  CLEAR_KITS,
  CREATE_KIT_REQUEST,
  CREATE_KIT_SUCCESS,
  CREATE_KIT_FAILURE,
  LOAD_KITS_REQUEST,
  LOAD_KITS_SUCCESS,
  LOAD_KITS_FAILURE,
  LOAD_KIT_HISTORY_REQUEST,
  LOAD_KIT_HISTORY_SUCCESS,
  LOAD_KIT_HISTORY_FAILURE,
  UPDATE_ASSIGN_ACCESSORIES_REQUEST,
  UPDATE_ASSIGN_ACCESSORIES_SUCCESS,
  UPDATE_ASSIGN_ACCESSORIES_FAILURE,
  UPDATE_ASSIGN_KIT_REQUEST,
  UPDATE_ASSIGN_KIT_SUCCESS,
  UPDATE_ASSIGN_KIT_FAILURE,
  LOAD_ASSET_REQUEST,
  LOAD_ASSET_SUCCESS,
  LOAD_ASSET_FAILURE,
  LOAD_ACCESSORY_REQUEST,
  LOAD_ACCESSORY_SUCCESS,
  LOAD_ACCESSORY_FAILURE,
  LOAD_KIT_REQUEST,
  LOAD_KIT_SUCCESS,
  LOAD_KIT_FAILURE,
  UPDATE_TICKET_ASSIGN_TO_SELF_REQUEST,
  UPDATE_TICKET_ASSIGN_TO_SELF_SUCCESS,
  UPDATE_TICKET_ASSIGN_TO_SELF_FAILURE,
  LOAD_TICKET_REQUEST,
  LOAD_TICKET_SUCCESS,
  LOAD_TICKET_FAILURE,
  CREATE_MAINTENANCE_LOG_REQUEST,
  CREATE_MAINTENANCE_LOG_SUCCESS,
  CREATE_MAINTENANCE_LOG_FAILURE,
} from '../actions/types';

const initialState = {
  loading: false,
  errors: {},
  assets: { items: [], count: 0 },
  accessories: { items: [], count: 0 },
  kits: { items: [], count: 0 },
  tickets: { items: [], count: 0 },
  history: { items: [], count: 0 },
  statuses: [],
};

const assetManagementReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case CREATE_MAINTENANCE_LOG_REQUEST:
    case LOAD_TICKET_REQUEST:
    case UPDATE_TICKET_ASSIGN_TO_SELF_REQUEST:
    case LOAD_KIT_REQUEST:
    case LOAD_ACCESSORY_REQUEST:
    case LOAD_ASSET_REQUEST:
    case UPDATE_ASSIGN_KIT_REQUEST:
    case UPDATE_ASSIGN_ACCESSORIES_REQUEST:
    case LOAD_KIT_HISTORY_REQUEST:
    case CREATE_KIT_REQUEST:
    case LOAD_KITS_REQUEST:
    case LOAD_ACCESSORY_HISTORY_REQUEST:
    case CREATE_ACCESSORY_REQUEST:
    case LOAD_ACCESSORIES_REQUEST:
    case UPDATE_TICKET_REQUEST:
    case CREATE_TICKET_REQUEST:
    case LOAD_TICKETS_REQUEST:
    case LOAD_TICKET_HISTORY_REQUEST:
    case LOAD_ASSET_HISTORY_REQUEST:
    case UPDATE_ASSIGN_ASSET_REQUEST:
    case UPDATE_DELETE_ASSET_REQUEST:
    case LOAD_ASSET_STATUSES_REQUEST:
    case CREATE_ASSET_REQUEST:
    case LOAD_ASSETS_REQUEST: {
      return { ...state, loading: true };
    }
    case LOAD_ASSET_STATUSES_SUCCESS: {
      return {
        ...state,
        loading: false,
        statuses: [...state.statuses, ...payload],
      };
    }
    case LOAD_ASSET_SUCCESS: {
      return {
        ...state,
        loading: false,
        assets: { items: [...state.assets.items, ...payload], count: state.assets.count },
      };
    }
    case LOAD_KIT_SUCCESS: {
      return {
        ...state,
        loading: false,
        kits: { items: [...state.kits.items, ...payload], count: state.kits.count },
      };
    }
    case LOAD_ACCESSORY_SUCCESS: {
      return {
        ...state,
        loading: false,
        accessories: { items: [...state.accessories.items, ...payload], count: state.accessories.count },
      };
    }
    case LOAD_ASSETS_SUCCESS: {
      return {
        ...state,
        loading: false,
        assets: { items: [...state.assets.items, ...payload.items], count: payload.count },
      };
    }
    case LOAD_TICKET_SUCCESS: {
      return {
        ...state,
        loading: false,
        tickets: { items: [...state.tickets.items, ...payload], count: state.tickets.count },
      };
    }
    case LOAD_ACCESSORIES_SUCCESS: {
      return {
        ...state,
        loading: false,
        accessories: { items: [...state.accessories.items, ...payload.items], count: payload.count },
      };
    }
    case LOAD_KITS_SUCCESS: {
      return {
        ...state,
        loading: false,
        kits: { items: [...state.kits.items, ...payload.items], count: payload.count },
      };
    }
    case LOAD_TICKETS_SUCCESS: {
      return {
        ...state,
        loading: false,
        tickets: { items: [...state.tickets.items, ...payload.items], count: payload.count },
      };
    }
    case UPDATE_DELETE_ASSET_SUCCESS: {
      return {
        ...state,
        loading: false,
        assets: { items: state.assets.items.filter(({ id }) => id !== payload.id), count: state.assets.count - 1 },
      };
    }
    case CREATE_ACCESSORY_SUCCESS: {
      const matchingIndex = state.accessories.items.findIndex(({ id }) => payload.id === id);

      if (matchingIndex >= 0) {
        const itemsCopy = [...state.accessories.items];
        itemsCopy.splice(matchingIndex, 1, payload);

        return {
          ...state,
          loading: false,
          accessories: { items: itemsCopy, count: state.accessories.count },
        };
      } else {
        return {
          ...state,
          loading: false,
          accessories: { items: [payload, ...state.accessories.items], count: state.accessories.count + 1 },
        };
      }
    }
    case CREATE_ASSET_SUCCESS: {
      return {
        ...state,
        loading: false,
        assets: { items: [payload, ...state.assets.items], count: state.assets.count + 1 },
      };
    }
    case CREATE_KIT_SUCCESS: {
      return {
        ...state,
        loading: false,
        kits: { items: [payload, ...state.kits.items], count: state.kits.count + 1 },
      };
    }
    case UPDATE_ASSIGN_KIT_SUCCESS:
    case LOAD_KIT_HISTORY_SUCCESS: {
      const matchingIndex = state.kits.items.findIndex(({ id }) => payload.id === id);
      const itemsCopy = [...state.kits.items];

      itemsCopy.splice(matchingIndex, 1, payload);

      return {
        ...state,
        loading: false,
        kits: { items: itemsCopy, count: state.kits.count },
      };
    }
    case UPDATE_ASSIGN_ACCESSORIES_SUCCESS:
    case LOAD_ACCESSORY_HISTORY_SUCCESS: {
      const matchingIndex = state.accessories.items.findIndex(({ id }) => payload.id === id);
      const itemsCopy = [...state.accessories.items];

      itemsCopy.splice(matchingIndex, 1, payload);

      return {
        ...state,
        loading: false,
        accessories: { items: itemsCopy, count: state.accessories.count },
      };
    }
    case CREATE_TICKET_SUCCESS: {
      return {
        ...state,
        loading: false,
        tickets: { items: [payload, ...state.tickets.items], count: state.tickets.count + 1 },
      };
    }
    case UPDATE_TICKET_ASSIGN_TO_SELF_SUCCESS:
    case UPDATE_TICKET_SUCCESS:
    case LOAD_TICKET_HISTORY_SUCCESS: {
      const matchingIndex = state.tickets.items.findIndex(({ id }) => payload.id === id);
      const itemsCopy = [...state.tickets.items];

      itemsCopy.splice(matchingIndex, 1, payload);

      return {
        ...state,
        loading: false,
        tickets: { items: itemsCopy, count: state.tickets.count },
      };
    }
    case CREATE_MAINTENANCE_LOG_SUCCESS:
    case LOAD_ASSET_HISTORY_SUCCESS:
    case UPDATE_ASSIGN_ASSET_SUCCESS: {
      const matchingIndex = state.assets.items.findIndex(({ id }) => payload.id === id);
      const itemsCopy = [...state.assets.items];

      itemsCopy.splice(matchingIndex, 1, { ...payload, order: itemsCopy[matchingIndex]?.order });

      return {
        ...state,
        loading: false,
        assets: { items: itemsCopy, count: state.assets.count },
      };
    }
    case CREATE_MAINTENANCE_LOG_FAILURE:
    case LOAD_TICKET_FAILURE:
    case UPDATE_TICKET_ASSIGN_TO_SELF_FAILURE:
    case LOAD_KIT_FAILURE:
    case LOAD_ACCESSORY_FAILURE:
    case LOAD_ASSET_FAILURE:
    case UPDATE_ASSIGN_KIT_FAILURE:
    case UPDATE_ASSIGN_ACCESSORIES_FAILURE:
    case LOAD_KIT_HISTORY_FAILURE:
    case CREATE_KIT_FAILURE:
    case LOAD_KITS_FAILURE:
    case LOAD_ACCESSORY_HISTORY_FAILURE:
    case CREATE_ACCESSORY_FAILURE:
    case LOAD_ACCESSORIES_FAILURE:
    case UPDATE_TICKET_FAILURE:
    case CREATE_TICKET_FAILURE:
    case LOAD_TICKETS_FAILURE:
    case LOAD_TICKET_HISTORY_FAILURE:
    case LOAD_ASSET_HISTORY_FAILURE:
    case UPDATE_ASSIGN_ASSET_FAILURE:
    case UPDATE_DELETE_ASSET_FAILURE:
    case LOAD_ASSET_STATUSES_FAILURE:
    case CREATE_ASSET_FAILURE:
    case LOAD_ASSETS_FAILURE: {
      return { ...state, loading: false, errors: payload };
    }
    case CLEAR_ASSET_STATUSES: {
      return {
        ...state,
        statuses: [],
      };
    }
    case CLEAR_TICKETS: {
      return {
        ...state,
        tickets: {
          items: [],
          count: 0,
        },
      };
    }
    case CLEAR_ASSETS: {
      return {
        ...state,
        assets: {
          items: [],
          count: 0,
        },
      };
    }
    case CLEAR_ACCESSORIES: {
      return {
        ...state,
        accessories: {
          items: [],
          count: 0,
        },
      };
    }
    case CLEAR_KITS: {
      return {
        ...state,
        kits: {
          items: [],
          count: 0,
        },
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default assetManagementReducer;
