import cn from 'classnames';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';

const FormSelect = ({
  name,
  containerClasses,
  label,
  id,
  required,
  Icon,
  options,
  error,
  value,
  onChange,
  placeholder,
  disabled,
  labelClass,
}) => (
  <div className={containerClasses}>
    <label className={`block uppercase tracking-wide text-black text-xs font-bold mb-2 ${labelClass}`} htmlFor={id}>
      {label} {required && <span className="text-ti-green">*</span>}
    </label>
    {Icon && <div className="absolute mt-4 ml-4 z-10">{Icon}</div>}
    <div className="relative">
      <select
        required
        className={cn(
          'focus:ring-0 border-input-grey text-black appearance-none block w-full border-2 rounded py-3 mb-1 leading-tight focus:outline-none focus:border-ti-green overflow-ellipsis cursor-pointer',
          {
            'border-red-500': error,
            'text-ti-placeholder-grey': !value,
            'pl-4 pr-6': !Icon,
            'pr-4 pl-10': Icon,
            'cursor-not-allowed': disabled,
          },
        )}
        id={id}
        onChange={onChange}
        value={value || 'DEFAULT'}
        name={name}
        disabled={disabled}
      >
        <option value="DEFAULT" disabled hidden>
          {placeholder}
        </option>
        {options?.map((opt) => (
          <option key={`${label}-${opt}`} value={opt}>
            {opt}
          </option>
        ))}
      </select>
    </div>
    {error && (
      <Fade bottom timeout={350}>
        <p className="text-red-500 text-xs italic my-1">{error}</p>
      </Fade>
    )}
  </div>
);

FormSelect.defaultProps = {
  value: null,
  error: null,
  containerClasses: '',
  required: false,
  Icon: null,
};

FormSelect.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
  containerClasses: PropTypes.string,
  required: PropTypes.bool,
  Icon: PropTypes.element,
};

export default FormSelect;
