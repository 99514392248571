import { useMemo } from 'react';
import PropTypes from 'prop-types';
import Image from 'next/image';
import Action from '../common/action';
import DynamicElement from '../common/dynamicElement';
import useBasicCarousel from '../../hooks/useBasicCarousel';

const PopularList = ({ title, list, id, titleElement }) => {
  const listContent = useMemo(
    () =>
      list?.map(({ title: itemTitle, subTitle, image, action, titleElement: itemTitleElement = 'p', ...restItem }) => (
        <div
          key={itemTitle}
          className="bg-ti-grey rounded shadow py-10 px-5 h-full flex flex-col justify-between items-center relative"
        >
          <div>
            <DynamicElement
              element={itemTitleElement}
              content={itemTitle}
              className="font-bold text-xl md:text-base lg:text-xl text-ti-link can-hover:hover:underline"
              {...restItem}
            />
            <p className="font-semibold text-lg md:text-base lg:text-lg my-4">{subTitle}</p>
          </div>
          <Image
            alt="DroMight™ drop system"
            src={image}
            className="m-auto"
            width={300}
            height={300}
            lazyBoundary="500px"
          />
          <div>{action && <Action {...action} className="justify-center mt-10 m-auto xs:w-40" />}</div>
        </div>
      )),

    [list],
  );

  const { Carousel } = useBasicCarousel({
    items: listContent,
    slidesToShow: 1,
    sliderClass: 'w-full text-center',
  });

  return (
    <section id={id} className="bg-gradient-to-b from-white to-ti-mid-grey">
      <div id="container-wrapper" className="content-wrapper md:pt-0">
        <DynamicElement
          element={titleElement}
          content={title}
          className="font-bold text-center md:text-left text-3xl mb-10"
        />
        <div className="sm:grid hidden grid-col grid-cols-1 grid-rows-4 sm:grid-cols-2 sm:grid-rows-2 md:grid-cols-4 md:grid-rows-1 gap-4 text-black flex items-center text-center">
          {listContent}
        </div>
        <div className="sm:hidden">{Carousel}</div>
      </div>
    </section>
  );
};

PopularList.defaultProps = {
  titleElement: 'p',
};

PopularList.propTypes = {
  title: PropTypes.string.isRequired,
  list: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  id: PropTypes.string.isRequired,
  titleElement: PropTypes.string,
};

export default PopularList;
