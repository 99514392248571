import {
  CREATE_MANUFACTURER_REQUEST,
  CREATE_MANUFACTURER_SUCCESS,
  CREATE_MANUFACTURER_FAILURE,
  LOAD_MANUFACTURERS_REQUEST,
  LOAD_MANUFACTURERS_SUCCESS,
  LOAD_MANUFACTURERS_FAILURE,
  UPDATE_MANUFACTURER_REQUEST,
  UPDATE_MANUFACTURER_SUCCESS,
  UPDATE_MANUFACTURER_FAILURE,
  CREATE_PRODUCT_REQUEST,
  CREATE_PRODUCT_SUCCESS,
  CREATE_PRODUCT_FAILURE,
  LOAD_ALL_PRODUCTS_REQUEST,
  LOAD_ALL_PRODUCTS_SUCCESS,
  LOAD_ALL_PRODUCTS_FAILURE,
  UPDATE_PRODUCT_REQUEST,
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_FAILURE,
  CREATE_INVENTORY_SUCCESS,
  CLEAR_ALL_PRODUCTS,
  CREATE_CATEGORY_REQUEST,
  CREATE_CATEGORY_SUCCESS,
  CREATE_CATEGORY_FAILURE,
  UPDATE_CATEGORY_REQUEST,
  UPDATE_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_FAILURE,
  LOAD_CATEGORIES_REQUEST,
  LOAD_CATEGORIES_SUCCESS,
  LOAD_CATEGORIES_FAILURE,
} from '../actions/types';

const initialState = {
  categories: [],
  manufacturers: [],
  loading: false,
  error: {},
  products: { items: [], count: 0 },
};

const productReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case CLEAR_ALL_PRODUCTS: {
      return {
        ...state,
        products: { items: [], count: 0 },
      };
    }

    case CREATE_CATEGORY_REQUEST:
    case UPDATE_CATEGORY_REQUEST:
    case LOAD_CATEGORIES_REQUEST:
    case UPDATE_PRODUCT_REQUEST:
    case LOAD_ALL_PRODUCTS_REQUEST:
    case CREATE_PRODUCT_REQUEST:
    case UPDATE_MANUFACTURER_REQUEST:
    case LOAD_MANUFACTURERS_REQUEST:
    case CREATE_MANUFACTURER_REQUEST: {
      return {
        ...state,
        loading: true,
        error: {},
      };
    }
    case UPDATE_CATEGORY_SUCCESS: {
      return {
        ...state,
        loading: false,
        categories: state.categories.map(({ id, ...rest }) => {
          if (+id === +payload.id) {
            return payload;
          } else {
            return { id, ...rest };
          }
        }),
        error: {},
      };
    }
    case UPDATE_MANUFACTURER_SUCCESS: {
      return {
        ...state,
        loading: false,
        manufacturers: state.manufacturers.map(({ id, ...rest }) => {
          if (+id === +payload.id) {
            return payload;
          } else {
            return { id, ...rest };
          }
        }),
        error: {},
      };
    }
    case CREATE_CATEGORY_SUCCESS: {
      return {
        ...state,
        categories: [payload, ...state.categories],
        loading: false,
        error: {},
      };
    }
    case CREATE_MANUFACTURER_SUCCESS: {
      return {
        ...state,
        manufacturers: [payload, ...state.manufacturers],
        loading: false,
        error: {},
      };
    }
    case CREATE_PRODUCT_SUCCESS: {
      return {
        ...state,
        loading: false,
        products: { count: state.products.count + 1, items: [payload, ...state.products.items] },
      };
    }
    case CREATE_INVENTORY_SUCCESS: {
      const matchingProductIndex = state.products.items.findIndex(({ id }) => id === payload.product.id);
      const itemsCopy = [...state.products.items];
      if (matchingProductIndex > -1) {
        itemsCopy.splice(matchingProductIndex, 1);
      }
      return {
        ...state,
        loading: false,
        products: { count: state.products.count - 1, items: itemsCopy },
      };
    }
    case UPDATE_PRODUCT_SUCCESS: {
      const matchingProductIndex = state.products.items.findIndex(({ id }) => id === payload.id);
      const itemsCopy = [...state.products.items];
      if (matchingProductIndex > -1) {
        itemsCopy.splice(matchingProductIndex, 1, {
          ...payload,
          order: state.products.items[matchingProductIndex].order,
        });
      }
      return {
        ...state,
        loading: false,
        products: { count: state.products.count, items: itemsCopy },
      };
    }
    case LOAD_CATEGORIES_SUCCESS: {
      return {
        ...state,
        categories: payload,
        loading: false,
        error: {},
      };
    }
    case LOAD_MANUFACTURERS_SUCCESS: {
      return {
        ...state,
        manufacturers: payload,
        loading: false,
        error: {},
      };
    }
    case UPDATE_MANUFACTURER_FAILURE: {
      return {
        ...state,
        loading: false,
        error: { newName: { ...payload } },
      };
    }
    case LOAD_MANUFACTURERS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: { manufacturers: { ...payload } },
      };
    }
    case CREATE_MANUFACTURER_FAILURE: {
      return {
        ...state,
        loading: false,
        error: { name: { ...payload } },
      };
    }
    case LOAD_ALL_PRODUCTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        products: {
          count: payload.count,
          items: [...state.products.items, ...payload.items],
        },
      };
    }
    case CREATE_CATEGORY_FAILURE:
    case UPDATE_CATEGORY_FAILURE:
    case LOAD_CATEGORIES_FAILURE:
    case CREATE_PRODUCT_FAILURE:
    case UPDATE_PRODUCT_FAILURE:
    case LOAD_ALL_PRODUCTS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: { product: { ...payload } },
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default productReducer;
