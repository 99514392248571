import PropTypes from 'prop-types';

const FAQ = ({ list, id }) => (
  <section id={id}>
    <div className="content-wrapper">
      <h1 className="text-3xl md:text-4xl font-bold mt-3 mb-10 text-ti-space-grey text-center mb-20">
        FREQUENTLY ASKED QUESTIONS
      </h1>
      {list?.map(({ question, answer, linkText, link }) => {
        const linkSpan = (
          <span key={`${question}-span`}>
            <a className="text-ti-link underline cursor-pointer" target="_blank" rel="noopener noreferrer" href={link}>
              {linkText}
            </a>
          </span>
        );
        const answerText = answer.split(`~`)?.map((text, index) => (index === 1 ? linkSpan : text));
        return (
          <div className="mb-10" key={question}>
            <h2 className="text-2xl font-semibold mb-5">{question}</h2>
            <p className="leading-relaxed whitespace-pre-line">{answerText}</p>
          </div>
        );
      })}
    </div>
  </section>
);

FAQ.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  id: PropTypes.string.isRequired,
};

export default FAQ;
