import { useCallback, useEffect, useState } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Squash as Hamburger } from 'hamburger-react';
import { AiOutlineShopping, AiOutlineShoppingCart } from 'react-icons/ai';
import cn from 'classnames';
import PropTypes from 'prop-types';
import Drawer from './Drawer';
import useWindowSize from '../../hooks/useWindowSize';
import NavigationElement from './NavigationElement';
import throttle from 'lodash/throttle';

const Header = ({ navItems }) => {
  const router = useRouter();
  const { width } = useWindowSize();
  const [expanded, setExpanded] = useState(false);

  const handleExpand = throttle(
    (expandedValue) => {
      const target = document.getElementById('page-container');
      const body = document.body;

      if (expandedValue && target && body.style.overflow !== 'hidden') {
        target.style.top = `-${window.scrollY}px`;
        body.style.overflowY = 'scroll';
        body.style.overflowX = 'auto';
        body.style.position = 'fixed';
        body.style.width = '100%';
      } else if (!expandedValue && target && body.style.overflow !== 'hidden') {
        const scrollY = target.style.top;
        body.style.overflowY = '';
        body.style.overflowX = '';
        body.style.position = '';
        body.style.width = '';
        target.style.top = '';
        window.scrollTo(0, parseInt(scrollY || '0') * -1);
      }

      setExpanded(expandedValue);
    },
    [1000],
  );

  const throttledExpand = useCallback(
    (expandedValue) => {
      if (expandedValue !== expanded) {
        handleExpand(expandedValue);
      }
    },
    [expanded],
  );

  const [pageLoaded, setPageLoaded] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);

  const handleShowDrawer = () => {
    setShowDrawer(!showDrawer);
  };

  const goToContactSection = () => {
    setShowDrawer(false);
    setTimeout(() => {
      const scrollTarget = document.getElementById('contact-section');
      if (router.pathname === '/' && scrollTarget) {
        scrollTarget.scrollIntoView({ behavior: 'smooth' });
        router.push(
          {
            pathname: '/',
          },
          undefined,
          { shallow: true },
        );
      } else {
        router.push('/#contact-section');
      }
    }, [300]);
  };

  const closeDrawer = () => {
    setShowDrawer(false);
  };

  const confirmMouseLeave = (e) => {
    const announcementBanner = document.getElementById('announcement-banner');
    if (
      e.relatedTarget !== announcementBanner &&
      e.target?.parentNode?.parentNode !== e.relatedTarget?.parentNode?.parentNode
    ) {
      throttledExpand(false);
    }
  };

  useEffect(() => {
    if (width > 1280 && showDrawer) {
      closeDrawer();
    }
  }, [showDrawer, width]);

  useEffect(() => {
    if (router?.isReady && !pageLoaded) {
      setTimeout(() => setPageLoaded(true), 300);
    }
  }, [router]);

  return (
    <div
      id="terrestrial-imaging-header"
      className="sticky top-0 w-full transition duration-200 bg-ti-space-grey shadow shadow-inner h-15"
      style={{ zIndex: 10000 }}
    >
      <div id="header-container" className="content-wrapper b-md:px-5 py-0 initial h-full flex items-center">
        {!pageLoaded && (
          <div className="flex items-center justify-center mx-auto">
            <Link href="/">
              <a className="py-3 flex items-center text-white">
                <div style={{ height: 20, width: 20 }}>
                  <Image
                    alt="Terrestrial Imaging Home"
                    src="/images/ti icon gold.png"
                    width={20}
                    height={20}
                    priority
                    layout="responsive"
                    objectFit="contain"
                  />
                </div>
                <p className="ml-2 font-bold">
                  Terrestrial <span className="text-ti-green">Imaging</span>
                </p>
              </a>
            </Link>
          </div>
        )}
        {pageLoaded && (
          <div className="fadein w-full">
            <div id="header-nav-bar" className="relative flex justify-center items-center text-white relative">
              <div onMouseEnter={() => throttledExpand(false)} className="mr-auto w-full lg:w-auto flex items-center">
                {width < 1280 && (
                  <div
                    className={cn('w-20 px-2', {
                      'ti-button ripple': width >= 1280,
                    })}
                  >
                    <div
                      className="mr-auto rounded-full can-hover:hover:bg-opacity-30 can-hover:hover:bg-ti-green w-12 h-12 flex items-center justify-center ripple"
                      onClick={handleShowDrawer}
                    >
                      <Hamburger size={18} toggled={showDrawer} />
                    </div>
                  </div>
                )}
                <Link href="/">
                  <a className="mx-auto lg:ml-0 lg:mr-auto  py-3 flex items-center">
                    <div style={{ height: 20, width: 20 }}>
                      <Image
                        alt="Terrestrial Imaging Home"
                        src="/images/ti icon gold.png"
                        width={20}
                        height={20}
                        priority
                        layout="responsive"
                        objectFit="contain"
                      />
                    </div>
                    <p className="ml-2 lg:absolute lg:ml-7 font-bold text-sm text-white">Terrestrial Imaging</p>
                  </a>
                </Link>
              </div>
              {width >= 1280 && (
                <ul className="flex items-center justify-center" onMouseLeave={confirmMouseLeave}>
                  {navItems.map((item) => (
                    <NavigationElement
                      key={`navigationelement-${item.category}`}
                      data={item}
                      setExpanded={throttledExpand}
                      expanded={expanded}
                      goToContactSection={goToContactSection}
                    />
                  ))}
                  <li>
                    <button className="px-5 py-5 text-sm relative font-semibold w-content" onClick={goToContactSection}>
                      Contact Us
                    </button>
                  </li>
                </ul>
              )}
              <div onMouseEnter={() => throttledExpand(false)} className="ml-auto flex items-center">
                <a
                  href="https://store-terrestrialimaging-com.3dcartstores.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={cn('w-24 px-2 lg:ml-auto whitespace-pre flex items-center justify-center', {
                    'ti-button ripple': width >= 1280,
                  })}
                >
                  {width >= 1280 ? (
                    <span className="flex items-center jsutify-center">
                      Store <AiOutlineShoppingCart className="ml-1" />
                    </span>
                  ) : (
                    <div className="ml-auto rounded-full can-hover:hover:bg-opacity-30 can-hover:hover:bg-ti-green w-12 h-12 flex items-center justify-center ripple">
                      <AiOutlineShopping className="text-2xl" />
                    </div>
                  )}
                </a>
              </div>
            </div>
          </div>
        )}
      </div>
      {pageLoaded && width < 1280 && (
        <Drawer
          show={showDrawer}
          handleShow={handleShowDrawer}
          navItems={navItems}
          router={router}
          goToContactSection={goToContactSection}
        />
      )}
    </div>
  );
};

Header.propTypes = {
  navItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default Header;
