import { useCallback, useEffect, useState } from 'react';
import Image from 'next/image';
import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io';
import Fade from 'react-reveal/Fade';
import cn from 'classnames';
import { useInView } from 'react-intersection-observer';
import { useRouter } from 'next/router';

const HeroCarousel = ({ features }) => {
  const [view, setView] = useState(0);
  const [manualScrolling, setManualScrolling] = useState(false);
  const [slideInterval, setSlideInterval] = useState(null);
  const router = useRouter();
  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: false,
  });
  const clearIntervalFunc = useCallback(() => {
    if (slideInterval) {
      clearInterval(slideInterval);
    }
    setSlideInterval(null);
  }, [slideInterval]);

  const changeView = (index) => (e) => {
    e?.stopPropagation();
    setManualScrolling(false);
    let target = null;
    if (index >= 0) {
      target = index === features.length ? 0 : index === -1 ? features.length - 1 : index;
    } else {
      target = view + 1 === features.length ? 0 : view + 1;
    }
    setView(target);
    resetInterval();
  };

  const resetInterval = () => {
    clearIntervalFunc();
    const nextSlide = setInterval(() => {
      document?.getElementById('gallery-next-button')?.click();
    }, [8000]);
    setSlideInterval(nextSlide);
  };

  const isVisible = (element) => {
    const rect = element.getBoundingClientRect();

    return (
      rect.top + rect.height / 2 > 0 && // top
      rect.left + rect.width / 2 > 0 && // left
      rect.top + rect.height / 2 < (window.innerHeight || document.documentElement.clientHeight) && // bottom
      rect.left + rect.width / 2 < (window.innerWidth || document.documentElement.clientWidth) // right
    );
  };

  const onscroll = useCallback(() => {
    if (manualScrolling) {
      const visibleDiv = [...document.querySelectorAll('[id^="slide-"]')].find((ele) => isVisible(ele));
      if (visibleDiv) {
        setView(+visibleDiv.getAttribute('id').split('-')[1]);
      }
    }
  }, [manualScrolling]);

  useEffect(() => {
    if (!manualScrolling) {
      document.getElementById('gallery').scrollLeft = document.getElementById(`slide-${view}`).offsetLeft;
    }
  }, [view, manualScrolling]);

  useEffect(() => {
    if (inView) {
      resetInterval();
    } else {
      clearIntervalFunc();
    }
  }, [inView]);

  useEffect(() => {
    const slider = document.getElementById('gallery');
    let isDown = false;
    let startX;
    let scrollLeft;

    const mousedown = (e) => {
      isDown = true;
      slider.classList.add('active');
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
      setManualScrolling(true);
    };

    const mouseleave = () => {
      isDown = false;
      slider.classList.remove('active');
    };

    const mouseup = () => {
      isDown = false;
      slider.classList.remove('active');
    };

    const mousemove = (e) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - slider.offsetLeft;
      const walk = (x - startX) * 1.5; //scroll-fast
      slider.scrollLeft = scrollLeft - walk;
    };

    slider.addEventListener('mousedown', mousedown);
    slider.addEventListener('mouseleave', mouseleave);
    slider.addEventListener('mouseup', mouseup);
    slider.addEventListener('mousemove', mousemove);

    return () => {
      clearIntervalFunc();
      slider.removeEventListener('mousedown', mousedown);
      slider.removeEventListener('mouseleave', mouseleave);
      slider.removeEventListener('mouseup', mouseup);
      slider.removeEventListener('mousemove', mousemove);
    };
  }, []);

  return (
    <div className="relative w-full" ref={ref} onMouseOver={clearIntervalFunc} onMouseLeave={resetInterval}>
      <button
        className="can-hover:hover:bg-opacity-90 shadow-xl flex items-center text-white bg-ti-dim-grey bg-opacity-60 p-1 absolute left-0 top-1/2 bottom-1/2 h-8 mx-4 rounded-full z-40 can-hover:hover:cursor-pointer"
        onClick={changeView(view - 1)}
      >
        <IoIosArrowBack className="text-2xl" />
      </button>
      <button
        className="can-hover:hover:bg-opacity-90 shadow-xl flex items-center text-white bg-ti-dim-grey bg-opacity-60 p-1 absolute right-0 top-1/2 bottom-1/2 h-8 mx-4 rounded-full z-40 can-hover:hover:cursor-pointer"
        onClick={changeView(view + 1)}
        id="gallery-next-button"
      >
        <IoIosArrowForward className="text-2xl" />
      </button>
      <div
        id="gallery"
        className="gallerycss no-scrollbar bg-black"
        style={{
          gridAutoColumns: '100%',
          gridGap: '0px',
        }}
        onScroll={onscroll}
      >
        {features.map(({ backgroundImage, backgroundImageAlt, backgroundImageSmall, blurDataURL, link }, index) => (
          <Fade timeout={1000} duration={1000} when={view === index} key={backgroundImage}>
            <div id={`slide-${index}`} className="slidecss cursor-grab" role="button" onClick={() => router.push(link)}>
              <div className="relative w-full h-full">
                <div className="hidden sm:block">
                  <Image
                    src={backgroundImage}
                    alt={backgroundImageAlt}
                    placeholder="blur"
                    blurDataURL={blurDataURL}
                    width={1920}
                    height={600}
                    layout="responsive"
                    // objectPosition="center"
                    // objectFit="cover"
                    // layout="fill"
                    priority={index === 0}
                  />
                </div>
                <div className="block sm:hidden">
                  <Image
                    src={backgroundImageSmall}
                    alt={backgroundImageAlt}
                    placeholder="blur"
                    blurDataURL={blurDataURL}
                    width={425}
                    height={425}
                    layout="responsive"
                    priority={index === 0}
                  />
                </div>

                {/* <div
                    className="absolute w-full h-full"
                    style={{ background: `rgba(0,0,0,${backgroundOpacity || 0.1})`, zIndex: 1 }}
                  />
                  <div className="z-10 p-5 flex flex-wrap items-center w-full h-full cursor-grab relative">
                    <div
                      className={cn('flex items-center flex-col ', {
                        'md:w-1/2 m-auto w-full': src,
                        'md:ml-20 mr-auto md:w-content w-full': !src,
                      })}
                    >
                      <p
                        style={{ color: title?.color, textShadow: '2px 2px 0px rgba(0,0,0,0.3)' }}
                        className="whitespace-pre text-center text-2xl md:text-4xl lg:text-6xl font-medium mx-auto !leading-snug w-full"
                      >
                        {title?.text}
                      </p>
                      <p
                        style={{ color: subTitle?.color, textShadow: '2px 2px 0px rgba(0,0,0,0.3)' }}
                        className="text-center mt-5 text-sm md:text-base lg:text-2xl mx-auto italic xs:whitespace-pre-wrap whitespace-pre w-full"
                      >
                        {subTitle?.text}
                      </p>
                      <div className="flex mt-5">
                        {action1 && (
                          <Action
                            {...action1}
                            className="xs:text-sm can-hover:hover:bg-opacity-60"
                            style={{ backgroundColor: action1?.backgroundColor, color: action1?.textColor }}
                          />
                        )}
                        {action2 && (
                          <Action
                            {...action2}
                            className="xs:text-sm ml-6 text-white"
                            style={{ textShadow: '2px 2px 0px rgba(0,0,0,0.2)' }}
                          />
                        )}
                      </div>
                    </div>
                    {src && (
                      <div className="h-full w-full md:w-1/2 relative py-10">
                        <Image
                          alt={alt}
                          src={src}
                          priority={index === 0}
                          layout="responsive"
                          width={1387}
                          height={499}
                        />
                      </div>
                    )}
                  </div> */}
              </div>
            </div>
          </Fade>
        ))}
      </div>
      <div className="absolute bottom-0 w-full flex items-center justify-center pb-1 gap-x-2 z-10">
        {features.map((_, index) => (
          <button
            key={`dot-${index}`}
            id={`slider-pagination-${index}`}
            className={cn('h-5 w-5 rounded-md hero-pagination-dot shadow-md cursor-pointer', {
              '!active-hero-pagination-dot': index === view && slideInterval,
            })}
            onClick={changeView(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default HeroCarousel;
