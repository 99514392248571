import DynamicElement from '../common/dynamicElement';
import PropTypes from 'prop-types';
import Image from 'next/image';
import Action from '../common/action';
import cn from 'classnames';
import { useRouter } from 'next/router';

const ImageSquares = ({ id, title, titleElement, features, floatText = false }) => {
  const router = useRouter();

  const goTo = (link) => () => {
    router.push(link);
  };

  return (
    <section id={id} className="bg-white">
      <div className="content-wrapper sm:px-3 max-w-screen-2xl flex flex-col">
        <DynamicElement
          element={titleElement}
          content={title}
          className="text-ti-black mb-5 text-xl sm:text-2xl md:text-3xl lg:text-4xl leading-normal text-center md:text-left opacity-60"
        />
        <div
          className={`grid grid-cols-1 grid-rows-${features.length} md:grid-cols-${features.length} md:grid-rows-1 w-full gap-2`}
        >
          {features.map(({ title: featureTitle, image, alt, description, colors, link, isNew, blurDataURL }) => (
            <div
              onClick={goTo(link)}
              key={`${featureTitle}-category`}
              className={cn('w-full col-span-1 row-span-1 can-hover:hover:hovering-drone relative cursor-pointer', {
                shadow: !floatText,
              })}
              style={
                colors
                  ? {
                      background: `radial-gradient(#${colors.inner}, #${colors.outer})`,
                    }
                  : {}
              }
            >
              <div
                className={cn('flex flex-col items-center justify-center', {
                  'absolute z-10 text-white bg-black bg-opacity-60 rounded-xl p-2 top-2 sm:top-auto sm:bottom-2 left-2':
                    floatText,
                })}
              >
                <p
                  className={`text-lg lg:text-xl md:text-${
                    features.length > 2 ? 'sm' : 'lg'
                  } sm:text-xl font-bold text`}
                >
                  {featureTitle}
                </p>
                <p
                  className={`my-2 font-semibold hidden sm:block lg:text-base md:text-${
                    features.length > 2 ? 'sm' : 'normal'
                  } sm:text-base`}
                >
                  {description}
                </p>
              </div>
              <Image
                src={image}
                alt={alt}
                height={533}
                width={800}
                layout="responsive"
                objectFit="contain"
                placeholder="blur"
                blurDataURL={blurDataURL}
                lazyBoundary="500px"
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ImageSquares;
