import Head from 'next/head';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';

const DocumentHead = ({ description, title, keywords, robots, canonicalPref }) => {
  const router = useRouter();
  const canonicalURL = process.env.NEXT_PUBLIC_URL + (canonicalPref ? canonicalPref : router.asPath.slice(1));

  return (
    <Head>
      <link rel="icon" href="/favicon.png" />
      <title>{title}</title>
      <meta name="description" content={description} />
      {keywords && <meta name="keywords" content={keywords} />}
      <meta content="width=device-width, initial-scale=1" name="viewport" />
      <meta itemProp="image" content="/images/site-image.jpg" />
      <link rel="canonical" href={canonicalURL} />
      <meta property="og:site_name" content="Terrestrial Imaging" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={canonicalURL} />
      <meta property="og:title" content={title} key="title" />
      <meta property="og:image" content="/images/site-image.jpg" />
      <meta property="og:description" content={description} key="description" />
      <meta name="robots" content={robots} />
    </Head>
  );
};

DocumentHead.defaultProps = {
  keywords: null,
};

DocumentHead.propTypes = {
  description: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  keywords: PropTypes.string,
  robots: PropTypes.string.isRequired,
};

export default DocumentHead;
