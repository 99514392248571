import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import DynamicElement from '../dynamicElement';

const fontCaseFunc = {
  upper: 'toUpperCase',
  lower: 'toLowerCase',
};

const SpanGenerator = ({ content, textClassName, spanClassName, fontCase, contentType, ...rest }) => {
  const func = fontCaseFunc?.[fontCase];
  const foundStrings = {};
  const newContent = useMemo(
    () =>
      content.split('|')?.map((mappedContent) => {
        foundStrings[mappedContent] = foundStrings[mappedContent] ? foundStrings[mappedContent] + 1 : 1;
        const tempContent = mappedContent?.[func]?.() ?? mappedContent;
        if (tempContent[0] === '~') {
          return (
            <span key={`${mappedContent}-${foundStrings[mappedContent]}`} className={spanClassName}>
              {tempContent.slice(1)}
            </span>
          );
        }
        return tempContent;
      }),
    [content, fontCase],
  );

  return <DynamicElement element={contentType} className={textClassName} content={newContent} {...rest} />;
};

SpanGenerator.defaultProps = {
  textClassName: '',
  spanClassName: '',
  fontCase: '',
  contentType: 'p',
};

SpanGenerator.propTypes = {
  content: PropTypes.string.isRequired,
  textClassName: PropTypes.string,
  spanClassName: PropTypes.string,
  fontCase: PropTypes.string,
  contentType: PropTypes.string,
};

export default SpanGenerator;
