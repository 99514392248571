import Link from 'next/link';
import { isMobile } from 'react-device-detect';

const classnName = 'font-semibold border-b-2 border-ti-green can-hover:hover:text-ti-green cursor-pointer';

const Hyperlink = ({ sentence, hyperLinks }) => {
  if (!hyperLinks || !hyperLinks.length) {
    return sentence;
  }

  const markedSentence = hyperLinks.reduce((accu, { text }, index) => accu.replace(text, `|${index}|`), sentence);
  return markedSentence.split('|')?.map((split) => {
    if (!split.trim().length || isNaN(split)) {
      return split;
    }

    const { type, text, link, external } = hyperLinks[+split];

    if (!link) {
      return (
        <span key={text} className="font-semibold border-b-2 border-ti-green">
          {text}
        </span>
      );
    }

    if (type === 'link') {
      if (external) {
        return (
          <a key={text} href={link} target="_blank" rel="noopener noreferrer" className={classnName}>
            {text}
          </a>
        );
      } else {
        return (
          <Link href={link} key={text}>
            <a className={classnName}>{text}</a>
          </Link>
        );
      }
    }

    if (type === 'phone') {
      return isMobile ? (
        <a key={text} href={`tel:${link}`} className={classnName}>
          {text}
        </a>
      ) : (
        <span key={text} className="font-semibold border-b-2 border-ti-green">
          {text}
        </span>
      );
    }

    if (type === 'email') {
      return (
        <a key={text} href={`mailto: ${text}`} className={classnName}>
          {text}
        </a>
      );
    }

    if (type === 'download') {
      return (
        <a key={text} href={link} download className={classnName}>
          {text}
        </a>
      );
    }
  });
};

export default Hyperlink;
