import PropTypes from 'prop-types';
import cn from 'classnames';
import { useInView } from 'react-intersection-observer';
import Image from 'next/image';
import Hyperlink from '../common/hyperlink';
import DynamicElement from '../common/dynamicElement';

const Steps = ({ title, subTitle, steps, orientation, titleElement, subTitleElement, id }) => {
  const { ref: landingRef, inView: landingInView } = useInView({
    threshold: 0.5,
    triggerOnce: false,
  });

  const { ref: cloudRef, inView: cloudInView } = useInView({
    rootMargin: '500px',
    triggerOnce: false,
  });
  const possibleOpacities = {
    0: 'text-opacity-0',
    10: 'text-opacity-10',
    20: 'text-opacity-20',
    30: 'text-opacity-30',
    40: 'text-opacity-40',
    50: 'text-opacity-50',
    60: 'text-opacity-60',
    70: 'text-opacity-70',
    80: 'text-opacity-80',
    90: 'text-opacity-90',
    100: 'text-opacity-100',
  };

  return (
    <section id={id} className="from-ti-grey via-white to-ti-grey bg-gradient-to-r">
      <div className="content-wrapper">
        <DynamicElement element={titleElement} content={title} className="font-bold text-4xl" />
        <DynamicElement element={subTitleElement} content={subTitle} className="text-lg mt-4 mb-16" />
        <div className="flex">
          <div
            className={cn({
              'grid grid-rows-3 grid-cols-1 md:grid-rows-1 md:grid-cols-3': orientation === 'horizontal',
              'w-full md:w-6/12': orientation !== 'horizontal',
            })}
          >
            {steps?.map(({ title: stepTitle, text, hyperLinks, titleElement: stepTitleElement = 'p' }, index) => (
              <div className="py-3" key={stepTitle}>
                <p className={`text-9xl font-bold text-ti-space-grey ${possibleOpacities[100 - index * 20]}`}>
                  {index + 1}
                  <span className="text-ti-green">.</span>
                </p>
                <DynamicElement element={stepTitleElement} content={stepTitle} className="font-bold text-2xl mt-6" />
                <hr className="w-2/12 border-2 border-ti-green my-6" />
                <p className="md:w-10/12 text-lg leading-loose">
                  <Hyperlink sentence={text} hyperLinks={hyperLinks} />
                </p>
              </div>
            ))}
          </div>
          {orientation !== 'horizontal' && (
            <div className="hidden md:block md:w-6/12 relative" id="flight-zone">
              <div
                className={cn('sticky top-24 z-10', { 'hovering-drone': cloudInView || landingInView })}
                id="flying-drone"
              >
                <Image
                  alt="Terrestrial Imaging Flying Drone"
                  src="/images/misc/drone.png"
                  width={150}
                  height={150}
                  className={cn({ 'shaking-drone': cloudInView || landingInView })}
                />
              </div>
              <div className="absolute top-2/4" ref={cloudRef}>
                <Image alt="Terrestrial Imaging Cloud" src="/images/misc/cloud.png" width={400} height={200} />
              </div>
              <Image
                alt="Terrestrial Imaging Cloud"
                src="/images/misc/cloud.png"
                className="absolute top-1/3 left-36"
                width={500}
                height={200}
              />
              <div id="landing-zone" className="absolute bottom-0 z-20" ref={landingRef}>
                <Image
                  alt="Terrestrial Imaging Pilot Flying A Drone"
                  src="/images/misc/man-flying-drone.png"
                  width={500}
                  height={400}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

Steps.defaultProps = {
  orientation: 'horizontal',
  titleElement: 'p',
  subTitleElement: 'p',
};

Steps.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  steps: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  orientation: PropTypes.string,
  titleElement: PropTypes.string,
  subTitleElement: PropTypes.string,
  id: PropTypes.string.isRequired,
};

export default Steps;
