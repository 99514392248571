import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import Image from 'next/image';
import Action from '../common/action';
import DynamicElement from '../common/dynamicElement';
import Gif from '../common/picture/Gif';
import { useInView } from 'react-intersection-observer';
import Fade from 'react-reveal/Fade';

const TextRightImageLeft = ({
  image,
  alt,
  action,
  message,
  title,
  width,
  backgroundColor,
  textColor,
  titleElement,
  placeHolderImage,
  id,
  ...rest
}) => {
  const { ref: viewRef, inView } = useInView({
    threshold: 0,
    rootMargin: '100px',
    triggerOnce: true,
  });
  return (
    <Fade bottom>
      <section id={id} className={cn({ [backgroundColor]: backgroundColor })}>
        <div className="content-wrapper flex flex-col md:flex-row">
          <div
            className="w-full md:w-1/2 order-2 md:order-1 relative flex items-center justify-center mt-10 md:mt-0 "
            ref={viewRef}
          >
            {inView && (
              <>
                {image.includes('.gif') ? (
                  <Gif src={image} alt={alt} placeHolderImage={placeHolderImage} {...rest} />
                ) : image.includes('.webm') ? (
                  <video controls src={image} loop autoPlay muted />
                ) : (
                  <div className="min-h-200 sm:min-h-400 relative w-full">
                    <Image
                      src={image}
                      alt={alt}
                      objectPosition="center"
                      layout="fill"
                      objectFit="contain"
                      {...rest}
                      placeholder="blur"
                      blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mMszs6sBwAEaAHIT/ldiAAAAABJRU5ErkJggg=="
                      lazyBoundary="500px"
                    />
                  </div>
                )}
              </>
            )}
          </div>
          <div
            className={cn('w-full md:w-1/2 flex items-center text-center md:text-right px-5 order-1 md:order-2', {
              [textColor]: textColor,
            })}
          >
            <div>
              <DynamicElement
                element={titleElement}
                content={title}
                className="text-2xl sm:text-4xl md:text-5xl font-medium mb-10 leading-snug sm:leading-snug md:leading-snug"
              />
              <p className="leading-loose text-base sm:text-md">{message}</p>
              {action && <Action {...action} className="m-auto md:ml-auto md:mr-0 mt-5" />}
            </div>
          </div>
        </div>
      </section>
    </Fade>
  );
};

TextRightImageLeft.defaultProps = {
  width: 'auto',
  textColor: 'text-ti-black',
  backgroundColor: 'bg-white',
  titleElement: 'p',
  action: null,
};

TextRightImageLeft.propTypes = {
  image: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  action: PropTypes.shape({}),
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  titleElement: PropTypes.string,
  id: PropTypes.string.isRequired,
};

export default TextRightImageLeft;
