import * as components from '../components';

const pageMapper = (pageMap) => {
  const seenComponents = {};

  return (
    pageMap?.map(({ component, ...props }) => {
      const Component = components[component];
      seenComponents[component] = seenComponents[component] ? seenComponents[component] + 1 : 1;
      return (
        Component && (
          <Component
            {...props}
            id={`${component}-${seenComponents[component]}`}
            key={`${component}-${seenComponents[component]}`}
          />
        )
      );
    }) ?? null
  );
};

export default pageMapper;
