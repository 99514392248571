import DynamicElement from '../common/dynamicElement';
import PropTypes from 'prop-types';
import Image from 'next/image';
import Action from '../common/action';

const ImageCard = ({ id, title, categories, titleElement, footer, footerElement, priority }) => {
  return (
    <section id={id} className="bg-white">
      <div id="image-card-section-container" className="content-wrapper pb-0">
        <DynamicElement
          element={titleElement}
          content={title}
          className="px-4 text-ti-black text-xl sm:text-2xl md:text-3xl lg:text-4xl leading-normal text-center md:text-left opacity-60"
        />
        <div className="grid grid-cols-1 grid-rows-4 sm:grid-cols-2 sm:grid-rows-2 lg:grid-cols-4 lg:grid-rows-1 w-full">
          {categories.map(({ title: categoryTitle, image, alt, action, description, blurDataURL }) => (
            <div key={`${categoryTitle}-category`} className="w-full col-span-1 row-span-1 p-4">
              <div className="bg-white text-black relative">
                <Image
                  src={image}
                  alt={alt}
                  priority={priority || false}
                  layout="responsive"
                  height={222}
                  width={288}
                  placeholder="blur"
                  blurDataURL={blurDataURL}
                  lazyBoundary="500px"
                />
                <div className="bg-white rounded-b-xl w-full py-5">
                  <p className="text-xl font-semibold text-left">{categoryTitle}</p>
                  <p className="my-5">{description}</p>
                  <div className="flex justify-center">
                    {action && (
                      <Action {...action} className="font-semibold text-sm text-left mr-auto ml-0 text-ti-link" />
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

ImageCard.propTypes = {
  title: PropTypes.string.isRequired,
  cards: PropTypes.arrayOf(PropTypes.shape({})),
};

export default ImageCard;
