import { useState, useEffect, createContext, useCallback } from 'react';
import { useRouter } from 'next/router';
import queryString from 'query-string';
import kebabCase from 'lodash/kebabCase';
import { supabase } from '../utils/supabaseClient';
import axios from 'axios';
import querstring from 'query-string';
import { DESTROY_SESSION } from '../actions/types';
import { useIdleTimer } from 'react-idle-timer';
import { RiAlarmWarningFill } from 'react-icons/ri';
import throttle from 'lodash/throttle';
import Modal from 'react-modal';

export const AuthContext = createContext({
  user: null,
});

export function AuthProvider({ children, store }) {
  const router = useRouter();
  const [user, setUser] = useState(null);
  const [profilePicture, setProfilePicture] = useState(null);
  const [lastEvent, setLastEvent] = useState(null);
  const [retrievingProfilePicture, setRetrievingProfilePicture] = useState(false);
  const [tokenCheckInterval, setTokenCheckInterval] = useState(null);
  const [timerInterval, setTimerInterval] = useState(null);

  const [showPrompt, setShowPrompt] = useState(false);
  const [timer, setTimer] = useState(0);

  const { getRemainingTime, getLastActiveTime, start, pause } = useIdleTimer({
    timeout: 1000 * 60 * 20,
    promptTimeout: 1000 * 60 * 2,
    eventsThrottle: 5000,
    crossTab: true,
    leaderElection: true,
    onPrompt: () => {
      setShowPrompt(true);
      const updateTimer = setInterval(() => {
        setTimer((prevTime) => prevTime + 10);
      }, 10);
      setTimerInterval(updateTimer);
    },
    onIdle: () => {
      signOut();
    },
    onActive: () => {
      if (showPrompt) return;
      refreshToken();
    },
    onAction: () => {
      if (showPrompt) return;
    },
    throttle: 1000 * 60 * 10,
    startManually: true,
    startOnMount: false,

    // throttle: 1000 * 60 * 9,
  });

  const refreshToken = throttle(
    useCallback(async () => {
      const {
        data: { validToken, authId },
      } = await axios.get('/api/auth/checkValidToken');
      if (authId) {
        await axios.post('/api/auth/signIn', { id: authId });
        await supabase.auth.refreshSession();
        if (showPrompt) {
          setShowPrompt(false);
        }
      } else {
        signOut();
      }
      clearInterval(timerInterval);
    }),
    [showPrompt],
    5000,
  );

  const signIn = async (email, password, successCallback, failureCallback, redirect = true) => {
    try {
      if (email && password) {
        const { user, error } = await supabase.auth.signIn({ email, password });

        if (!user || error) {
          if (failureCallback) {
            failureCallback({ email: error.message });
          }
          return;
        }
        const { data: userData } = await axios.post('/api/auth/signIn', { id: user.id });
        if (userData) {
          setUser(userData.user);

          if (successCallback) {
            successCallback();
          }
          if (redirect) {
            const {
              query: { redirect: routerRedirect, ...rest },
            } = router;

            if (routerRedirect) {
              router.push({
                pathname: routerRedirect,
                query: rest,
              });
            } else {
              const enterpriseLink = `/enterprise/${kebabCase(userData.user.domain.name.toLowerCase())}`;
              router.push(
                {
                  pathname: enterpriseLink,
                },
                undefined,
                { shallow: true },
              );
            }
          }
        }
      }
    } catch (err) {
      setUser(null);

      if (failureCallback) {
        failureCallback({ email: err.message });
      }
    }
    return { error: null };
  };

  const signOut = useCallback(async () => {
    await supabase.auth.signOut();
    await store.dispatch({
      type: DESTROY_SESSION,
    });
    setUser(null);
    setShowPrompt(false);
    clearInterval(tokenCheckInterval);
    setTokenCheckInterval(null);
    pause();
    router.push('/login');
  }, [tokenCheckInterval, store]);

  const refreshUser = async () => {
    await signIn();
  };

  useEffect(() => {
    const { data: authListener } = supabase.auth.onAuthStateChange((event, session) => {
      setLastEvent(event);
      axios.post('/api/auth/setAuthToken', { session, event });
    });

    const getData = async () => await axios.get('/api/auth/checkValidToken');
    const { validToken, authId } = getData().then((res) => res.data);
    if (validToken) {
      const { data } = axios.post('/api/auth/signIn', { id: authId }).then((res) => res);
      setUser(data.user);
      setLastEvent('SIGNED_IN');
    }

    return () => {
      authListener.unsubscribe();
      clearInterval(tokenCheckInterval);
    };
  }, []);

  useEffect(() => {
    if (lastEvent === 'SIGNED_OUT') {
      setUser(null);
      store.dispatch(() => ({
        type: DESTROY_SESSION,
      }));
      if (tokenCheckInterval) {
        setTokenCheckInterval(null);
      }
      pause();
      router.push('/login');
    }
    if (lastEvent === 'SIGNED_IN') {
      setTimeout(() => {
        const intervalID = setInterval(async () => {
          const {
            data: { validToken },
          } = await axios.get('/api/auth/checkValidToken');
          if (!validToken) {
            // await signOut();
          }
        }, 3600000);
        setTokenCheckInterval(intervalID);
        start();
      }, 5000);
    }
  }, [lastEvent]);

  useEffect(() => {
    const {
      query: { type, access_token },
    } = queryString.parseUrl(router.asPath.replace('#', '?'));

    if (type === 'recovery') {
      let domainName = user?.domain?.name;
      // if (!domainName && access_token) {
      //   const decodedEmail = jwt.decode(access_token).email;
      //   const {
      //     data: { domain: userDomain, error },
      //   } = await axios.post('/api/users/getDomainByEmail', { email: decodedEmail });
      //   domainName = kebabCase(userDomain.name.toLowerCase());
      // }

      if (domainName) {
        router.push(
          `/enterprise/${kebabCase(domainName.toLowerCase())}/?view=change-password&access_token=${access_token}`,
        );
      }
    }
  }, [router, user]);

  useEffect(() => {
    if (user && profilePicture && profilePicture !== user.profilePicture)
      setUser({ ...user, profilePicture, setProfilePicture });
  }, [profilePicture, user, setProfilePicture]);

  useEffect(() => {
    if (user && !user.profilePicture && !retrievingProfilePicture) {
      setRetrievingProfilePicture(true);
      (async () => {
        const { signedURL } = await supabase.storage.from(`avatars/${user.id}`).createSignedUrl('avatar.jpg', 3600);
        setProfilePicture(signedURL);
        setRetrievingProfilePicture(false);
      })();
    }
  }, [user]);

  useEffect(() => {
    if (showPrompt && typeof window !== 'undefined') {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'scroll';
    }
  }, [showPrompt]);

  return (
    <div>
      <AuthContext.Provider value={{ user, setUser, signIn, signOut, lastEvent, refreshUser }}>
        {children}
      </AuthContext.Provider>
      <Modal
        isOpen={showPrompt}
        onRequestClose={null}
        style={{
          content: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            overflow: 'hidden',
            padding: 0,
          },
          overlay: { zIndex: 1000, backgroundColor: 'rgba(0,0,0,.5)', cursor: 'pointer' },
        }}
      >
        <div className="p-5 w-full mb-2 bg-red-500 text-white flex items-center">
          {' '}
          <RiAlarmWarningFill className="text-3xl mr-2" />
          <p className="text-3xl font-semibold"> You Have Been Inactive For Too Long!</p>
        </div>
        <div className="p-5">
          <p className="font-semibold text-xl my-2">Your session will automatically expire in 2 minutes!</p>
          <p className="text-lg mt-5">You may continue your session be confirming you are still here</p>
          <hr className="w-full my-5" />
          <p className="text-xl font-semibold">
            <span>{('0' + Math.floor((timer / 60000) % 60)).slice(-2)}:</span>
            <span>{('0' + Math.floor((timer / 1000) % 60)).slice(-2)}:</span>
            <span>{('0' + Math.floor((timer / 10) % 1000)).slice(-2)}</span>
          </p>
          <div className="w-full border-ti-space-grey border-2 rounded-md h-8 shadow">
            <div
              style={{
                width: `${(+('0' + Math.floor((timer / 1000) % 60)).slice(-2) / 120) * 100}%`,
                background: 'repeating-linear-gradient(45deg,#EF4444,#EF4444 10px,#FCA5A5 10px,#FCA5A5 20px)',
              }}
              className="h-full rounded-md"
            />
          </div>
          <div className="w-full flex items-center justify-center my-5">
            <button
              className="text-xl ti-button bg-success can-hover:hover:bg-success text-white can-hover:hover:bg-opacity-60"
              onClick={refreshToken}
            >
              I Am Still Here!
            </button>
          </div>
        </div>
        <div />
      </Modal>
    </div>
  );
}
