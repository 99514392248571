import { AiOutlineShoppingCart, AiOutlineYoutube } from 'react-icons/ai';
import { BiHelpCircle } from 'react-icons/bi';
import cn from 'classnames';
import PropTypes from 'prop-types';
import Action from '../common/action';
import Hyperlink from '../common/hyperlink';
import DynamicElement from '../common/dynamicElement';

const allGraphics = {
  AiOutlineShoppingCart,
  AiOutlineYoutube,
  BiHelpCircle,
};

const PageAnchor = ({ title, subtitle, actionIcons, id, titleElement, subTitleElement, backgroundColor = null }) => (
  <section id={id} className={cn({ [`${backgroundColor}`]: backgroundColor, 'bg-ti-grey': !backgroundColor })}>
    <div className="content-wrapper">
      <div className="text-center">
        <DynamicElement
          content={title}
          element={titleElement}
          className="text-2xl sm:text-4xl leading-snug sm:leading-snug font-semibold whitespace-pre-line"
        />
        <DynamicElement
          content={subtitle}
          element={subTitleElement}
          className="text-xl sm:text-3xl mt-5 leading-snug sm:leading-snug"
        />
      </div>
      <div className="flex items-center justify-center flex-col md:flex-row my-20">
        {actionIcons?.map(({ title: actionIconTitle, icon, description, action, hyperLinks = [] }, index) => {
          const { [icon]: Icon } = allGraphics;
          return (
            <div
              className={cn('flex flex-col text-center justify-center items-center md:px-16 w-full md:w-1/3', {
                'my-10 md:my-0': index === 1,
              })}
              key={actionIconTitle}
            >
              <Icon className="text-4xl" />
              <p className="text-xl font-semibold text-ti-black mt-5">{actionIconTitle}</p>
              <p className="text-base text-ti-space-grey mt-2 mb-8">
                <Hyperlink sentence={description} hyperLinks={hyperLinks} />
              </p>
              <Action {...action} />
            </div>
          );
        })}
      </div>
    </div>
  </section>
);

PageAnchor.defaultProps = {
  titleElement: 'p',
  subTitleElement: 'p',
};

PageAnchor.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  actionIcons: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  id: PropTypes.string.isRequired,
  titleElement: PropTypes.string,
  subTitleElement: PropTypes.string,
};

export default PageAnchor;
