import { useEffect, useMemo, useState } from 'react';
import Head from 'next/head';
import { FcCheckmark } from 'react-icons/fc';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import Image from 'next/image';
import { BiCheckShield } from 'react-icons/bi';
import cn from 'classnames';
import { isMobile } from 'react-device-detect';
import Action from '../common/action';
import useImageCarousel from '../../hooks/useImageCarousel';

const ProductCarousel = ({ options, certificates, id }) => {
  const router = useRouter();
  const [option, setOption] = useState(options[0]);
  const canonicalURL = process.env.NEXT_PUBLIC_URL + router.asPath.slice(1);
  const [hasSpecs, setHasSpecs] = useState(false);

  const {
    title,
    subtitle,
    carouselImages,
    bulletsTitle,
    bullets,
    price,
    sku,
    mpn,
    brand,
    salePitch,
    inStock,
    limitedAvailability,
    showShipping,
    action,
    hasDjiShield,
    djiShieldOptions,
    comingSoon,
    rating,
    topRating,
  } = option;
  const { Carousel } = useImageCarousel({
    items: carouselImages,
    imagePageing: true,
  });

  const changeOption = (newOptionIndex) => () => {
    setOption(options[newOptionIndex]);
    const { slug } = router.query;

    const newPath = `/${slug.join('/')}`;

    router.push(
      {
        pathname: newPath,
        query: {
          option: options[newOptionIndex].subtitle,
        },
      },
      undefined,
      { shallow: true },
    );
  };

  const scrollToTop = () =>
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });

  const ProductMetaData = useMemo(
    () => (
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            '@context': `https://schema.org/`,
            '@type': 'Product',
            name: title,
            image: carouselImages.map(({ image }) => `${process.env.NEXT_PUBLIC_URL}${image.replace('/', '')}`),
            description: JSON.stringify(salePitch),
            ...(sku ? { sku } : {}),
            ...(mpn ? { mpn } : sku ? { sku } : {}),
            brand: {
              '@type': 'Brand',
              name: brand ? brand : 'Terrestrial Imaging',
            },
            ...(isNaN(price.replace(/,/g, ''))
              ? {}
              : {
                  offers: {
                    '@type': 'Offer',
                    url: canonicalURL,
                    priceCurrency: 'USD',
                    price: Number(price.replace(/,/g, '')),
                    itemCondition: 'https://schema.org/NewCondition',
                    availability: inStock ? 'https://schema.org/InStock' : 'https://schema.org/LimitedAvailability',
                  },
                }),
            ...(rating && topRating
              ? {
                  review: {
                    '@type': 'Review',
                    reviewRating: {
                      '@type': 'Rating',
                      ratingValue: rating || 5,
                      bestRating: topRating || 5,
                    },
                    author: {
                      '@type': 'Person',
                      name: 'Chris LoPresti',
                    },
                  },
                }
              : {}),
          }),
        }}
      />
    ),
    [(title, price)],
  );

  useEffect(() => {
    const {
      query: { option: queryOption },
    } = router;

    if (queryOption) {
      const index = options.findIndex(({ subtitle: optionsSubtitle }) => optionsSubtitle === queryOption) || 0;
      if (index >= 0) {
        setOption(options[index]);
      } else {
        setOption(options[0]);
      }
    } else {
      setOption(options[0]);
    }
  }, [router]);

  const titleText = useMemo(() => {
    return (
      <div>
        <h1 className="text-xl sm:text-3xl font-semibold">{title.toUpperCase()}</h1>
        <h2 className="text-base sm:text-xl font-medium my-2 border-b-4 border-ti-green inline-block text-ti-black-light">
          {subtitle.toUpperCase()}
        </h2>
      </div>
    );
  }, [title, subtitle]);

  useEffect(() => {
    if (document.getElementById('Specs-1')) {
      setHasSpecs(true);
    }
  }, []);

  return (
    <>
      {(!isNaN(price.replace(/,/g, '')) || rating) && <Head>{ProductMetaData}</Head>}
      <section id={id}>
        {hasSpecs && (
          <div className="fixed bg-ti-space-grey bg-opacity-80 w-full z-40" style={{ top: '60px' }}>
            <div className="content-wrapper flex justify-between items-center py-1">
              <button className="text-white can-hover:hover:underline font-semibold" onClick={scrollToTop}>
                {title}
              </button>
              <Action
                className="text-white"
                showArrow={false}
                type="scrollLink"
                text="Specs"
                target="Specs-1"
                external={false}
              />
            </div>
          </div>
        )}
        <div className="content-wrapper md:px-5">
          <div className="flex flex-col md:flex-row gap-y-6 md:gap-y-0 md:gap-x-12">
            <div className="block md:hidden">{titleText}</div>
            <div className="md:w-7/12">
              <div className="md:sticky md:top-20">
                {Carousel}
                {certificates?.length > 0 && (
                  <div className="flex w-full items-center justify-center sm:justify-start">
                    {certificates.map(({ src, alt }) => (
                      <Image src={src} alt={alt} width={200} height={80} key={alt} />
                    ))}
                  </div>
                )}
              </div>
            </div>

            <div className="w-full md:w-5/12">
              <div className="hidden md:block"> {titleText}</div>
              <h3 className="font-semibold text-ti-space-grey md:mt-10">{bulletsTitle}</h3>
              <ul className="pl-8 mt-5 dotted">
                {bullets?.map((bullet) => (
                  <li key={bullet} className="text-ti-space-grey my-1">
                    {bullet}
                  </li>
                ))}
              </ul>
              <hr className="mt-8 mb-4" />
              <div className="text-sm">
                <p className="font-semibold mb-2">
                  {title}
                  {sku && <span className="font-normal ml-2">({sku})</span>}
                </p>
                {options.length > 1 &&
                  options?.map(({ subtitle: optionsSubtitle }, index) => (
                    <button
                      onClick={changeOption(index)}
                      type="button"
                      key={optionsSubtitle}
                      className={cn('ti-button text-xs mr-2 mt-2 border-2 border-ti-green', {
                        'bg-white text-ti-black': option.subtitle !== optionsSubtitle,
                        'mr-0': index === options.length - 1,
                      })}
                    >
                      {optionsSubtitle}
                    </button>
                  ))}
                <p className="font-semibold my-4 xs:flex xs:flex-col">
                  {!isNaN(price.replace(/,/g, '')) ? 'Starting at: $' : ''}
                  {price}
                  {/* {showShipping && <span className="text-ti-green xs:ml-0 ml-2">FREE STANDARD SHIPPING</span>} */}
                </p>
                <div className="flex items-center mb-4">
                  {/* {inStock && (
                    <>
                      <FcCheckmark className="mr-2 text-ti-green text-lg" />
                      <p className="text-ti-dim-grey text-xs font-medium">
                        {limitedAvailability ? 'LIMITED STOCK' : 'IN STOCK'}{' '}
                      </p>
                    </>
                  )}
                  {!inStock && ( */}
                  <>
                    {/* <p className="text-ti-dim-grey text-xs font-semibold">
                        {comingSoon ? 'COMING SOON' : 'OUT OF STOCK'}
                      </p> */}
                    <p className="mr-1 text-xs">Call: </p>
                    {isMobile ? (
                      <a className="text-xs underline font-semibold" href="tel:1-800-359-0539">
                        1-800-359-0539
                      </a>
                    ) : (
                      <span className="text-xs font-semibold">1-800-359-0539</span>
                    )}
                    <p className="ml-1 text-xs">to get assistance with placing an order</p>
                  </>
                </div>
                <p className="text-ti-space-grey text-xs">{salePitch}</p>
                <Action subject={title} {...action} className="mt-8 mb-4 w-4/6 h-16 text-xl mx-auto sm:mx-0" />
              </div>
              <hr className="mt-8 mb-4" />
              {(showShipping || hasDjiShield || djiShieldOptions) && (
                <div className="flex xs:items-start items-center xs:flex-col flex-row">
                  {hasDjiShield && (
                    <div>
                      <div className="flex">
                        <BiCheckShield className="mr-2 text-ti-green text-lg" />
                        <p className="text-ti-dim-grey text-xs font-medium mr-5">DJI SHIELD INCLUDED </p>
                      </div>
                      {djiShieldOptions && (
                        <ul className="marker:text-green dotted ml-10">
                          {djiShieldOptions.map((option) => (
                            <li key={option} className="flex">
                              <p className="text-ti-dim-grey text-xs font-medium mr-5 mt-1">{option}</p>
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  )}

                  {/* {showShipping && (
                    <div className="flex xs:mt-2 mt-0">
                      <MdLocalShipping className="mr-2 text-ti-green text-lg" />
                      <p className="text-ti-dim-grey text-xs font-medium">FREE STANDARD SHIPPING </p>
                    </div>
                  )} */}
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

ProductCarousel.defaultProps = {
  options: [
    {
      inStock: true,
      showShipping: true,
      hasDjiShield: false,
      comingSoon: false,
      limitedAvailability: false,
    },
  ],
};

ProductCarousel.propTypes = {
  id: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      subtitle: PropTypes.string.isRequired,
      carouselImages: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
      bulletsTitle: PropTypes.string.isRequired,
      bullets: PropTypes.arrayOf(PropTypes.string).isRequired,
      price: PropTypes.string.isRequired,
      sku: PropTypes.string.isRequired,
      salePitch: PropTypes.string.isRequired,
      inStock: PropTypes.bool,
      limitedAvailability: PropTypes.bool,
      showShipping: PropTypes.bool,
      action: PropTypes.shape({}).isRequired,
      hasDjiShield: PropTypes.bool,
      comingSoon: PropTypes.bool,
    }),
  ),
};

export default ProductCarousel;
