import { createElement } from 'react';
import PropTypes from 'prop-types';

const DynamicElement = ({ element, content, ...props }) => {
  if (element && element.length) {
    if (element === 'a') {
      const { link, hiddenText = '', ...restProps } = props;
      return createElement(
        element,
        { ...restProps, href: link, rel: 'noopener noreferrer', 'aria-label': hiddenText },
        content,
      );
    } else {
      return createElement(element, props, content);
    }
  }

  return null;
};

DynamicElement.propTypes = {
  element: PropTypes.string.isRequired,
  content: PropTypes.any.isRequired,
};

export default DynamicElement;
