import Image from 'next/image';
import PropTypes from 'prop-types';
import SpanGenerator from '../common/spanGenerator';
import Action from '../common/action';

const About = ({
  title,
  paragraph1,
  paragraph2,
  actionSupportText,
  action,
  actionSupportFooterText,
  id,
  titleElement,
  image,
  alt,
  priorityImage,
}) => (
  <section id={id} className="bg-ti-black">
    <div id="container-wrapper" className="content-wrapper pb-0 sm:pb-14">
      <div className="flex text-white items-center flex-col lg:flex-row">
        <div id="about-us-text-container" className="w-11/12 text-center mx-auto my-0 sm:my-10 lg:text-left">
          <SpanGenerator
            content={title}
            contentType={titleElement}
            textClassName="font-bold md:text-5xl sm:text-4xl text-2xl sm:leading-tight"
            spanClassName="text-ti-green"
            fontCase="upper"
          />
          {paragraph1 && <p className="mt-14 mb-4 lg:w-10/12 w-full text-xl">{paragraph1}</p>}
          {paragraph2 && <p className="lg:w-10/12 w-full text-xl">{paragraph2}</p>}
        </div>
        <div id="about-us-action-container" className="mt-6">
          <div className="background-overlay-6 relative rounded-full sm:rounded lg:rounded-full border-4 border-ti-green h-72 w-72 sm:w-10/12 lg:h-425 lg:w-425 mx-auto my-5 md:mt-auto text-center">
            {image && (
              <Image
                priority={priorityImage}
                src={image}
                alt={alt}
                layout="fill"
                objectFit="cover"
                className="z-10 absolute rounded-full sm:rounded lg:rounded-full h-72 w-72 sm:w-10/12 lg:h-425 lg:w-425"
                lazyBoundary="500px"
              />
            )}
            <div className="relative z-20 background-overlay-6 h-full flex flex-col justify-center p-10 lg:p-20 rounded-full sm:rounded lg:rounded-full">
              {actionSupportText && <p className="mb-8 text-base sm:text-xl">{actionSupportText}</p>}
              {action && <Action {...action} className="ti-button ripple w-52 mx-auto text-base sm:text-lg" />}
              {actionSupportFooterText && (
                <p className="font-semibold mt-5 text-base sm:text-xl">{actionSupportFooterText}</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

About.defaultProps = {
  paragraph2: null,
  background: null,
  actionSupportText: null,
  actionSupportFooterText: null,
  titleElement: 'p',
  image: null,
  alt: '',
  priorityImage: false,
};

About.propTypes = {
  title: PropTypes.string.isRequired,
  paragraph1: PropTypes.string.isRequired,
  paragraph2: PropTypes.string,
  background: PropTypes.string,
  actionSupportText: PropTypes.string,
  action: PropTypes.shape({
    type: PropTypes.string,
    link: PropTypes.string,
    text: PropTypes.string,
    external: PropTypes.bool,
  }).isRequired,
  actionSupportFooterText: PropTypes.string,
  id: PropTypes.string.isRequired,
  titleElement: PropTypes.string,
  priorityImage: PropTypes.bool,
  image: PropTypes.string,
  alt: PropTypes.string,
};

export default About;
