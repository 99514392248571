import { useMemo, Component, useEffect } from 'react';
import { connect } from 'react-redux';
import { Provider } from 'react-redux';
import navItems from '../content/navItems.json';
import PropTypes from 'prop-types';
import { PersistGate as PersistGateClient } from 'redux-persist/integration/react';
import { useStore } from 'react-redux';
import { useRouter } from 'next/router';
import pageMapper from '../utils/pageMapper';
import { wrapper } from '../store';
import useAdTracking from '../hooks/useAdTracking';
import { AuthProvider } from '../context/AuthProvider';
import { TbShoppingCartDiscount } from 'react-icons/tb';
import dynamic from 'next/dynamic';
import 'react-toastify/dist/ReactToastify.css';
import 'tailwindcss/tailwind.css';
import '../styles.css';

const Header = dynamic(() => import('../components/header'));
const Footer = dynamic(() => import('../containers/FooterContainer'));

class PersistGateServer extends Component {
  render() {
    return this.props.children;
  }
}

const MyApp = ({ Component, pageProps, cachedAd }) => {
  const router = useRouter();

  const store = useStore(pageProps.initialReduxState);
  useAdTracking(cachedAd, store.dispatch);
  const PageMap = useMemo(() => pageMapper(pageProps.pageMap, router), [pageProps.pageMap, router]);
  const renderedNavItems = useMemo(() => navItems.filter(({ render }) => render), [navItems]);

  let runtime = process.env.RUNTIME;
  let PersistGate = PersistGateServer;
  if (runtime === 'browser') {
    PersistGate = PersistGateClient;
  }

  const endPath = router.asPath.split('/');

  useEffect(() => {
    if (pageProps.pageMap && pageProps.pageMap.length === 0) {
      router.push('/');
      return;
    }
  }, [pageProps]);
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={store.__persistor}>
        <AuthProvider store={store}>
          {(!router.asPath.includes('/enterprise/') || router.asPath.includes('redirect')) && (
            <>
              {/* <a
                className="inline-block items-center justify-center text-center p-2 text-sm bg-ti-green whitespace-pre flex-wrap break-words hidden md:flex sticky top-0 z-50"
                href="https://shop.ugcs.com/pages/ugcs-license-types-pricing"
                target="_blank"
                rel="noopener noreferrer"
                id="announcement-banner"
              >
                Save <span className="font-semibold">$75</span> using the discount code{' '}
                <span className="font-semibold">TERRUGCS</span> at checkout when purchasing{' '}
                <span className="font-semibold">UgCS</span> mission planning software
                <TbShoppingCartDiscount className="ml-2" />
              </a> */}
              <Header navItems={renderedNavItems} router={router} />
            </>
          )}
          <div id="page-container" style={{ position: 'relative' }}>
            <Component {...pageProps} id={endPath[endPath.length - 1]}>
              {PageMap}
            </Component>
            {(!router.asPath.includes('/enterprise/') || router.asPath.includes('redirect')) && (
              <Footer navItems={renderedNavItems} router={router} />
            )}
          </div>
        </AuthProvider>
      </PersistGate>
    </Provider>
  );
};

MyApp.propTypes = {
  pageProps: PropTypes.shape({
    pageMap: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  Component: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({})]).isRequired,
};

const mapStateToProps = ({ user, ad }) => {
  return { cachedUser: user, cachedAd: ad };
};

const mapDispatchToProps = {};

export default wrapper.withRedux(connect(mapStateToProps, mapDispatchToProps)(MyApp));
