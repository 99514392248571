import {
  CREATE_AD_RECORD_SUCCESS,
  CLEAR_AD_RECORD,
  UPDATE_AD_RECORD_SUCCESS,
  CREATE_AD_RECORD_FAILURE,
  CREATE_AD_RECORD_REQUEST,
  UPDATE_AD_RECORD_FAILURE,
  UPDATE_AD_RECORD_REQUEST,
  LOAD_AD_RECORDS_REQUEST,
  LOAD_AD_RECORDS_SUCCESS,
  LOAD_AD_RECORDS_FAILURE,
} from '../actions/types';

const initialState = {
  identifier: null,
  loading: true,
  allAdData: [],
};

const contactFormReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'persist/REHYDRATE': {
      return {
        ...state,
        ...(payload ? payload.ad : {}),
        loading: false,
      };
    }
    case CREATE_AD_RECORD_SUCCESS: {
      return {
        ...state,
        ...payload,
        loading: false,
      };
    }
    case CLEAR_AD_RECORD: {
      return {
        ...initialState,
        ...state.allAdData,
        loading: false,
      };
    }
    case LOAD_AD_RECORDS_SUCCESS: {
      return {
        ...state,
        allAdData: payload,
        loading: false,
      };
    }
    case LOAD_AD_RECORDS_FAILURE: {
      return {
        ...state,
        errors: payload,
        loading: false,
      };
    }
    case UPDATE_AD_RECORD_SUCCESS:
    case CREATE_AD_RECORD_FAILURE:
    case UPDATE_AD_RECORD_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case LOAD_AD_RECORDS_REQUEST:
    case CREATE_AD_RECORD_REQUEST:
    case UPDATE_AD_RECORD_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default contactFormReducer;
