import { useCallback, useEffect, useState } from 'react';
import Link from 'next/link';
import cn from 'classnames';
import Image from 'next/image';
import { useRouter } from 'next/router';

const NavigationElement = ({
  data: { link, render, category, routable, subCategories },
  expanded,
  setExpanded,
  goToContactSection,
}) => {
  const [active, setActive] = useState(false);
  const [activeSubCategory, setActiveSubCategory] = useState(subCategories?.[0]?.subCategory ?? null);
  const router = useRouter();

  if (!render) {
    return null;
  }
  const mouseEntered = useCallback(() => {
    setActive(true);
    document.getElementById(`${category}-menue`).scrollTop = 0;
    if (!expanded) {
      setExpanded(true);
    }
  }, [expanded]);

  const mouseLeft = (e) => {
    const announcementBanner = document.getElementById('announcement-banner');
    if (e.relatedTarget !== announcementBanner) {
      setActive(false);
      setActiveSubCategory(subCategories?.[0]?.subCategory ?? null);
    }
  };

  const switchActiveSubCategory = (subCategory) => () => setActiveSubCategory(subCategory);

  const goToLink = (link) => () => {
    if (link.includes('http')) {
      window.open(link, '_blank', 'noopener noreferrer');
    } else {
      router.push(link);
    }
    forceClose();
  };

  const forceClose = () => {
    setExpanded(false);
    setActive(false);
  };

  const forceCloseAndGoToContactSection = () => {
    forceClose();
    goToContactSection();
  };

  const scrollToBrand = (brand, index) => () => {
    const element = document.getElementById(brand);
    if (index === 0) {
      document.getElementById(`${category}-menue`).scrollTo({ behavior: 'smooth', top: 0 });
    } else {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    if (!expanded) {
      setActive(false);
    }
  }, [expanded]);

  return (
    <li
      onMouseEnter={subCategories ? mouseEntered : null}
      onMouseLeave={subCategories ? mouseLeft : null}
      className="relative"
      key={link}
    >
      {routable ? (
        <div onClick={forceClose}>
          <Link href={link}>
            <a
              className={cn('px-5 py-5 cursor-pointer text-sm font-semibold inline-block w-content', {
                'active-layer1': active,
              })}
            >
              {category}
            </a>
          </Link>
        </div>
      ) : (
        <p
          className={cn('px-5 py-5 cursor-pointer text-sm font-semibold inline-block w-content', {
            'active-layer1': active,
          })}
        >
          {category}
        </p>
      )}
      {subCategories?.length > 0 && (
        <div
          className={cn('fixed left-0 py-5 w-full', {
            'max-h-screen pointer-events-auto bg-white z-50 shadow-lg overflow-y-scroll overflow-x-auto': active,
            'max-h-0 pointer-events-none': !active,
            'transition-[max-height] ease-in-out duration-1000': !expanded & active,
          })}
          id={`${category}-menue`}
        >
          <div
            className={cn('content-wrapper pt-0 pb-20', {
              'transition-[opacity] ease-in-out duration-300 opacity-100': expanded & active,
              'opacity-0': !active,
            })}
          >
            <ul className="flex items-center justify-end" id={`${category}-menue-subcategories`}>
              {subCategories.map(({ subCategory, link: subLink, external }) => (
                <li
                  key={`${subCategory}-header-menue`}
                  onMouseEnter={switchActiveSubCategory(subCategory)}
                  className={cn('relative', {
                    'active-layer1': activeSubCategory === subCategory,
                  })}
                >
                  <div onClick={forceClose}>
                    <Link href={external ? subLink : link + subLink}>
                      <a
                        target="blank"
                        rel="noopener noreferrer"
                        className={cn(
                          'px-5 py-5 cursor-pointer text-sm relative font-semibold inline-block text-black',
                          {
                            'active-layer1': activeSubCategory === subCategory,
                          },
                        )}
                      >
                        {subCategory}
                      </a>
                    </Link>
                  </div>
                </li>
              ))}
            </ul>
            {subCategories.map(({ subCategory, brands, link: subLink }) => (
              <div className="flex relative" key={`${subCategory}-navigation`}>
                <div
                  className={cn('sticky w-1/6', {
                    'h-content pointer-events-auto bg-white overflow-auto': active && activeSubCategory === subCategory,
                    'h-0 pointer-events-none overflow-hidden hidden': !active || activeSubCategory !== subCategory,
                  })}
                  style={{ top: '120px' }}
                >
                  <p className="text-ti-space-grey text-xl font-semibold">Jump To</p>
                  <hr className="w-3/4 my-2" />
                  <ul>
                    {brands?.map(({ brand }, index) => (
                      <li key={`jump-to-${brand}`}>
                        <button
                          className="text-sm py-1 text-ti-space-grey can-hover:hover:underline can-hover:hover:text-dji-blue cursor-pointer"
                          onClick={scrollToBrand(brand, index)}
                        >
                          {brand}
                        </button>
                      </li>
                    ))}
                    <li className="mt-2">
                      <button
                        className="ti-button can-hover:hover:bg-opacity-60 can-hover:hover:bg-dji-blue text-white bg-dji-blue text-xs p-2"
                        onClick={forceCloseAndGoToContactSection}
                      >
                        Request a Quote
                      </button>
                    </li>
                  </ul>
                </div>
                <div className="w-5/6">
                  {brands?.map(({ brand, link: brandLink, products, external }) => (
                    <div
                      key={`${brand}-header-menue`}
                      className={cn('w-full py-5', {
                        'max-h-screen pointer-events-auto bg-white overflow-auto':
                          active && activeSubCategory === subCategory,
                        'max-h-0 pointer-events-none overflow-hidden hidden':
                          !active || activeSubCategory !== subCategory,
                      })}
                      id={brand}
                    >
                      <div onClick={forceClose}>
                        <Link href={external ? brandLink : link + subLink + brandLink}>
                          <a
                            target="blank"
                            rel="noopener noreferrer"
                            className="text-black can-hover:hover:text-dji-blue text-xl can-hover:hover:underline px-5 py-5 cursor-pointer font-semibold inline-block"
                          >
                            {brand}
                          </a>
                        </Link>
                      </div>
                      <hr className="w-full my-2" />
                      <ul className="flex items-start flex-wrap">
                        {products
                          .slice(0, 4)
                          .map(({ product, link: prodLink, image, featuredAccessories, external }) => (
                            <li key={product + '-featured'} className="w-1/5 p-2">
                              <button
                                onClick={goToLink(external ? prodLink : link + subLink + brandLink + prodLink)}
                                className="text-ti-space-grey can-hover:hover:bg-opacity-60 can-hover:hover:text-dji-blue w-full h-full bg-ti-grey p-2"
                              >
                                {image && (
                                  <Image
                                    blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP8/+d/PQAJdwN7dR/+GgAAAABJRU5ErkJggg=="
                                    placeholder="blur"
                                    src={image}
                                    width={150}
                                    height={150}
                                    objectFit="contain"
                                    layout="responsive"
                                    alt={product}
                                  />
                                )}
                                <p className="pb-5 py-2 px-2 font-semibold text-xs">{product}</p>
                              </button>
                              {featuredAccessories?.length >= 0 && (
                                <ul>
                                  {featuredAccessories?.map(
                                    ({ accessory, link: accessoryLink, image: accessoryImage }) => (
                                      <li key={accessory + '-accessory'} className="w-full">
                                        <button
                                          onClick={goToLink(accessoryLink)}
                                          className="text-ti-space-grey can-hover:hover:text-dji-blue text-sm flex items-center"
                                        >
                                          <div className="w-20 h-auto relative">
                                            {image && (
                                              <Image
                                                src={accessoryImage}
                                                width={20}
                                                height={20}
                                                alt={accessory}
                                                layout="responsive"
                                                objectFit="contain"
                                              />
                                            )}
                                          </div>
                                          <p className="w-full">{accessory}</p>
                                        </button>
                                      </li>
                                    ),
                                  )}
                                </ul>
                              )}
                            </li>
                          ))}
                        <ul className="w-1/5">
                          {products.slice(4, products.length).map(({ product, link: prodLink, external }) => (
                            <li key={product + '-link'} className="w-full p-2 ">
                              <div onClick={forceClose}>
                                <Link href={external ? prodLink : link + subLink + brandLink + prodLink}>
                                  <a className="text-black can-hover:hover:text-dji-blue text-sm can-hover:hover:underline cursor-pointer font-semibold inline-block">
                                    {product}
                                  </a>
                                </Link>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </ul>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </li>
  );
};

export default NavigationElement;
