import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Image from 'next/image';
import Action from '../common/action';
import DynamicElement from '../common/dynamicElement';
import Hyperlink from '../common/hyperlink';
import Fade from 'react-reveal/Fade';

const TextCenterImageCenter = ({
  image,
  alt,
  action,
  message,
  title,
  titleElement,
  width,
  hyperLinks,
  containerClass,
  id,
}) => {
  return (
    <Fade bottom>
      <section id={id}>
        <div
          className={cn('content-wrapper flex items-center flex-col text-center text-center', {
            [containerClass]: containerClass,
          })}
        >
          <DynamicElement
            element={titleElement}
            content={title}
            className="text-xl sm:text-3xl md:text-4xl font-medium mb-10"
          />
          {image && (
            <div className="min-h-200 sm:min-h-400 relative w-full" style={{ maxWidth: width ? width : '100%' }}>
              <Image
                src={image}
                alt={alt}
                objectPosition="center"
                layout="fill"
                objectFit="contain"
                placeholder="blur"
                blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mMszs6sBwAEaAHIT/ldiAAAAABJRU5ErkJggg=="
                lazyBoundary="500px"
              />
            </div>
          )}
          <div
            className={cn('w-full', {
              'mt-10 md:mt-0': image,
            })}
          >
            <p className="leading-loose sm:text-lg text-ti-black-light mb-5 whitespace-preline">
              <Hyperlink sentence={message} hyperLinks={hyperLinks} />
            </p>
            {action && <Action {...action} className="m-auto" />}
          </div>
        </div>
      </section>
    </Fade>
  );
};

TextCenterImageCenter.defaultProps = {
  image: null,
  alt: '',
  width: null,
  hyperLinks: null,
  titleElement: 'p',
  containerClass: '',
};

TextCenterImageCenter.propTypes = {
  image: PropTypes.string,
  alt: PropTypes.string,
  action: PropTypes.shape({
    type: PropTypes.string,
    text: PropTypes.string,
    external: PropTypes.bool,
    order: PropTypes.number,
    target: PropTypes.string,
  }).isRequired,
  message: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  width: PropTypes.number,
  hyperLinks: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      link: PropTypes.string,
      external: PropTypes.bool,
      type: PropTypes.string,
    }),
  ),
  titleElement: PropTypes.string,
  containerClass: PropTypes.string,
  id: PropTypes.string.isRequired,
};

export default TextCenterImageCenter;
