import { useState, useEffect } from 'react';
import stringSimilarity from 'string-similarity';
import { useRouter } from 'next/router';
import cn from 'classnames';
import { MdClear } from 'react-icons/md';
import PropTypes from 'prop-types';
import Image from 'next/image';
import Action from '../common/action';
import DynamicElement from '../common/dynamicElement';
import FormMultiSelect from '../common/contactForm/FormMultiSelect';
import useWindowSize from '../../hooks/useWindowSize';

const ProductCards = ({ id, categories, title, titleElement, subTitle, subTitleElement }) => {
  const router = useRouter();
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredCategories, setFilteredManfs] = useState(categories);
  const [selectManfFilter, setSelectManfFilter] = useState([]);
  const { width: windowWidth } = useWindowSize();

  const clearSearch = () => setSearchTerm('');

  const handleSearchChange = ({ target: { value } }) => {
    setSearchTerm(value);
  };

  const handleManfFilter = (value) => {
    setSelectManfFilter(value);
  };

  const filterResults = (manfs) =>
    manfs.reduce((accu, { products, ...rest }) => {
      if (selectManfFilter.length && !selectManfFilter.some(({ value }) => value === rest.title)) {
        return accu;
      }

      if (!searchTerm || !searchTerm.length) {
        return [...accu, { ...rest, products }];
      }

      const productTitles = products.reduce(
        (accu2, { title: productTitle }) => [...accu2, productTitle.toLowerCase()],
        [],
      );

      const productMatches = stringSimilarity.findBestMatch(searchTerm.toLowerCase(), productTitles);
      productMatches.ratings = productMatches.ratings
        .reduce((accu3, rating, index) => [...accu3, { ...rating, originalIndex: index }], [])
        .sort(({ rating: rating1 }, { rating: rating2 }) => (rating1 >= rating2 ? 1 : -1));

      let newProducts = [];

      if (productMatches.ratings[productMatches.bestMatchIndex].rating === 1) {
        return [...accu, { ...rest, products: [products[productMatches.bestMatchIndex]] }];
      }

      productMatches.ratings.forEach(({ rating, target, originalIndex }) => {
        if (rating > 0) {
          const partialMatches = target.toLowerCase().split(' ');
          const lowerCaseSearchTerm = searchTerm.toLowerCase().trim();
          if (
            partialMatches.some(
              (subString) =>
                lowerCaseSearchTerm.includes(subString.trim()) || subString.trim().includes(lowerCaseSearchTerm),
            )
          ) {
            newProducts.push(products[originalIndex]);
          }
        }
      });

      if (productMatches.bestMatch.rating === 0) {
        newProducts = products.filter(({ title: productTitle }) => productTitle.toLowerCase().includes(searchTerm));
        return newProducts.length
          ? [
              ...accu,
              {
                ...rest,
                products: newProducts,
              },
            ]
          : accu;
      }

      return [...accu, { ...rest, products: newProducts }];
    }, []);

  useEffect(() => {
    setFilteredManfs(filterResults(categories));
  }, [searchTerm]);

  useEffect(() => {
    setFilteredManfs(filterResults(categories));
  }, [selectManfFilter]);

  useEffect(() => {
    setSearchTerm('');
    setFilteredManfs(categories);
    setSelectManfFilter([]);
  }, [router]);

  return (
    <section id={id} className="bg-ti-grey">
      <div className="content-wrapper">
        <div>
          <DynamicElement
            content={title}
            element={titleElement}
            className="text-2xl md:text-4xl font-semibold mb-5 text-center"
          />
          <DynamicElement
            content={subTitle}
            element={subTitleElement}
            className="text-xl md:text-2xl mb-20 text-center"
          />
        </div>
        <div className="flex items-center">
          <div className="relative w-full md:w-4/12">
            <label
              className="block uppercase tracking-wide text-xs font-bold mb-2 text-ti-black"
              htmlFor="search-input"
            >
              Search or filter below
              <input
                className="appearance-none block w-full text-ti-black border-2 rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:border-ti-green"
                id="search-input"
                type="text"
                autoComplete="on"
                name="search-input"
                placeholder="Search for a product"
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </label>
            <div
              onClick={clearSearch}
              className="text-ti-space-grey absolute top-1.5 bottom-0 right-0 flex items-center px-2 cursor-pointer w-16 rounded-l-none rounded-r shadow-none"
            >
              <MdClear className="m-auto" title="Clear Search" />
            </div>
          </div>
        </div>
        {categories.length > 1 && (
          <div className="relative w-full md:w-4/12">
            <FormMultiSelect
              placeholder="Filter results..."
              name="productFilter"
              id="productFilter"
              value={selectManfFilter}
              onChange={handleManfFilter}
              options={categories?.map(({ title: cateforyTitle }) => ({ value: cateforyTitle, label: cateforyTitle }))}
            />
          </div>
        )}
        <hr className="mt-10" />
        {filteredCategories?.map(
          ({ products, title: filteredCategoryTitle, titleElement: filteredCategoryTitleElement }, manfIndex) => (
            <div key={`${filteredCategoryTitle}-div`}>
              {products.length > 0 && (
                <DynamicElement
                  content={filteredCategoryTitle}
                  element={filteredCategoryTitleElement}
                  className="mt-10 text-2xl md:text-4xl font-semibold mb-10 text-center"
                />
              )}
              {products?.map(
                ({
                  title: productTitle,
                  titleElement: productTitleElement,
                  description,
                  image,
                  alt,
                  action1,
                  action2,
                }) => (
                  <div
                    key={productTitle}
                    className="flex items-center shadow-lg flex-col md:flex-row mb-10 bg-white py-10"
                  >
                    <div
                      className={cn('w-full md:w-6/12 text-center md:text-left', {
                        'md:order-2': manfIndex % 2 !== 0,
                        'md:order-1': manfIndex % 2 === 0,
                      })}
                    >
                      <DynamicElement
                        content={productTitle}
                        element={productTitleElement}
                        className="text-xl mt-10 px-5 md:px-10 sm:text-2xl md:text-4xl font-semibold mb-10"
                      />
                      <p className="hidden md:block px-10">{description}</p>
                      <div
                        className={cn('flex flex-row items-center mt-10 mb-10 md:mb-0 px-10 justify-center', {
                          'md:justify-end': manfIndex % 2 !== 0,
                          'md:justify-start': manfIndex % 2 === 0,
                        })}
                      >
                        {action1 && (
                          <>
                            <Action
                              subject={title}
                              {...action1}
                              className="text-ti-link md:text-black xs:text-sm order-2 md:order-1 hidden md:flex"
                            />
                            <Action
                              subject={title}
                              {...action1}
                              type="link"
                              className="text-ti-link md:text-black xs:text-sm order-2 md:order-1 flex md:hidden"
                            />
                          </>
                        )}
                        {action2 && (
                          <Action
                            subject={title}
                            {...action2}
                            className="text-ti-link md:text-black xs:text-sm order-1 md:order-2 mr-6 ml-0 md:ml-6 md:mr-0"
                          />
                        )}
                      </div>
                    </div>
                    <div
                      className={cn('w-full md:w-6/12 xs:px-5 sm:px-10 flex items-center justify-center', {
                        'md:order-1': manfIndex % 2 !== 0,
                        'md:order-2': manfIndex % 2 === 0,
                      })}
                    >
                      <Image src={image} alt={alt} className="m-auto" width={600} height={400} lazyBoundary="1000px" />
                    </div>
                  </div>
                ),
              )}
            </div>
          ),
        )}
        {filteredCategories.length === 0 && (
          <p className="text-xl md:text-3xl text-center mt-20">There are no results that match your search criteria</p>
        )}
      </div>
    </section>
  );
};

ProductCards.defaultProps = {
  titleElement: 'p',
  subTitle: '',
  subTitleElement: 'p',
};

ProductCards.propTypes = {
  id: PropTypes.string.isRequired,
  categories: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  title: PropTypes.string.isRequired,
  titleElement: PropTypes.string,
  subTitle: PropTypes.string,
  subTitleElement: PropTypes.string,
};

export default ProductCards;
