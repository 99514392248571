import {
  LOAD_DOMAIN_INVENTORY_REQUEST,
  LOAD_DOMAIN_INVENTORY_SUCCESS,
  LOAD_DOMAIN_INVENTORY_FAILURE,
  UPDATE_INVENTORY_REQUEST,
  UPDATE_INVENTORY_SUCCESS,
  UPDATE_INVENTORY_FAILURE,
  CREATE_INVENTORY_REQUEST,
  CREATE_INVENTORY_SUCCESS,
  CREATE_INVENTORY_FAILURE,
  LOAD_DOMAIN_FILTERED_INVENTORY_REQUEST,
  LOAD_DOMAIN_FILTERED_INVENTORY_SUCCESS,
  LOAD_DOMAIN_FILTERED_INVENTORY_FAILURE,
  CLEAR_FILTERED_INVENTORY,
  CREATE_BULK_INVENTORY_REQUEST,
  CREATE_BULK_INVENTORY_SUCCESS,
  CREATE_BULK_INVENTORY_FAILURE,
  CLEAR_ALL_INVENTORY,
  CLEAR_INVENTORY_ERRORS,
  LOAD_ITEM_AUDIT_REQUEST,
  LOAD_ITEM_AUDIT_SUCCESS,
  LOAD_ITEM_AUDIT_FAILURE,
  CLEAR_SELECTED_ITEM_AUDIT,
} from '../actions/types';

const initialState = {
  loading: false,
  errors: {},
  data: { items: [], count: 0 },
  filteredData: { items: [], count: 0 },
  incompletePOs: 0,
  recentlyAdded: [],
  selectedItemAudit: { items: [], count: 0 },
};

const inventoryReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case CLEAR_SELECTED_ITEM_AUDIT: {
      return {
        ...state,
        selectedItemAudit: { items: [], count: 0 },
      };
    }
    case LOAD_DOMAIN_FILTERED_INVENTORY_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case CLEAR_INVENTORY_ERRORS: {
      return {
        ...state,
        errors: {},
      };
    }
    case CLEAR_ALL_INVENTORY: {
      return {
        ...state,
        data: { items: [], count: 0 },
      };
    }
    case CLEAR_FILTERED_INVENTORY: {
      return {
        ...state,
        filteredData: initialState.filteredData,
      };
    }
    case LOAD_ITEM_AUDIT_REQUEST:
    case CREATE_BULK_INVENTORY_REQUEST:
    case CREATE_INVENTORY_REQUEST:
    case UPDATE_INVENTORY_REQUEST:
    case LOAD_DOMAIN_INVENTORY_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case LOAD_DOMAIN_FILTERED_INVENTORY_SUCCESS: {
      return {
        ...state,
        loading: false,
        filteredData: { items: [...state.filteredData.items, ...payload.items], count: payload.count },
      };
    }
    case LOAD_DOMAIN_INVENTORY_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: { items: [...state.data.items, ...payload.items], count: payload.count },
        errors: {},
      };
    }
    case LOAD_ITEM_AUDIT_SUCCESS: {
      return {
        ...state,
        loading: false,
        errors: {},
        selectedItemAudit: { items: [...state.selectedItemAudit.items, ...payload.items], count: payload.count },
      };
    }
    case UPDATE_INVENTORY_SUCCESS: {
      const matchingItemIndex = state.data.items.findIndex(({ id }) => id === payload.id);
      const itemsCopy = [...state.data.items];
      if (matchingItemIndex > -1) {
        itemsCopy.splice(matchingItemIndex, 1, { ...payload, order: state.data.items[matchingItemIndex].order });
      }
      return {
        ...state,
        data: { ...state.data, items: itemsCopy },
        loading: false,
        errors: {},
      };
    }
    case CREATE_INVENTORY_SUCCESS: {
      return {
        ...state,
        data: { count: state.data.count + 1, items: [payload, ...state.data.items] },
        filteredData: { count: state.data.count + 1, items: [payload, ...state.data.items] },

        loading: false,
        errors: {},
      };
    }
    case CREATE_BULK_INVENTORY_FAILURE: {
      return {
        ...state,
        loading: false,
        recentlyAdded: [],
        errors: payload,
      };
    }
    case CREATE_BULK_INVENTORY_SUCCESS: {
      return {
        ...state,
        loading: false,
        recentlyAdded: payload.addedProduct,
        errors: {},
      };
    }

    case LOAD_ITEM_AUDIT_FAILURE:
    case LOAD_DOMAIN_FILTERED_INVENTORY_FAILURE:
    case CREATE_INVENTORY_FAILURE:
    case UPDATE_INVENTORY_FAILURE:
    case LOAD_DOMAIN_INVENTORY_FAILURE: {
      return {
        ...state,
        loading: false,
        errors: payload,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default inventoryReducer;
