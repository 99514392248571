import { GiPoliceCar, GiSpyglass, GiFilmProjector, GiFarmTractor, GiArchiveResearch } from 'react-icons/gi';
import { IoIosThunderstorm, IoIosSchool } from 'react-icons/io';
import { MdSecurity } from 'react-icons/md';
import PropTypes from 'prop-types';
import DynamicElement from '../common/dynamicElement';

const allGraphics = {
  GiPoliceCar,
  IoIosThunderstorm,
  MdSecurity,
  GiSpyglass,
  GiFilmProjector,
  GiFarmTractor,
  IoIosSchool,
  GiArchiveResearch,
};

const GraphicCard = ({ title, cards, id, titleElement }) => (
  <section id={id} className="background-gradient-40">
    <div id="container-wrapper" className="content-wrapper pt-10 lg:pt-10">
      <DynamicElement
        element={titleElement}
        content={title}
        className="text-white text-center text-3xl lg:text-4xl font-bold mb-20"
      />
      <div className="grid grid-col grid-cols-2 grid-rows-4 lg:grid-cols-4 lg:grid-rows-2 gap-4 text-black flex items-center text-center">
        {cards?.map(({ title: cardTitle, graphic }) => {
          const { [graphic]: Icon } = allGraphics;
          return (
            <div className="shadow-xl py-5 lg:p-12 rounded bg-white" key={cardTitle}>
              <Icon className="m-auto text-5xl lg:text-7xl mb-10" />
              <p className="font-semibold text-base lg:text-lg">{cardTitle}</p>
            </div>
          );
        })}
      </div>
    </div>
  </section>
);

GraphicCard.defaultProps = {
  titleElement: 'p',
};

GraphicCard.propTypes = {
  title: PropTypes.string.isRequired,
  cards: PropTypes.arrayOf(PropTypes.shape({ title: PropTypes.string, graphic: PropTypes.string })).isRequired,
  id: PropTypes.string.isRequired,
  titleElement: PropTypes.string,
};

export default GraphicCard;
