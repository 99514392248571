import { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import { createAdRecord } from '../actions/adActions';

const useAdTracking = (cachedAdData, dispatch) => {
  const router = useRouter();

  const [processingUrl, setProcessingUrl] = useState(false);

  const datesAreOnSameDay = (first, second) =>
    first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth() &&
    first.getDate() === second.getDate();

  useEffect(() => {
    const { identifier, updatedAt, loading } = cachedAdData;

    if (loading) {
      return;
    }

    if (!processingUrl) {
      setProcessingUrl(true);
      const {
        query: { ad_identifier, ...restQueries },
      } = router;

      const { slug, userQueries } = restQueries;

      const cachedDate = updatedAt ? new Date(updatedAt) : null;

      if (cachedDate && ad_identifier === identifier && datesAreOnSameDay(cachedDate, new Date())) {
        router.push(
          {
            pathname: router.asPath.split('?')[0],
            query: userQueries,
          },
          undefined,
          { shallow: true },
        );
        return;
      }
      if (ad_identifier) {
        dispatch(createAdRecord(ad_identifier));
        router.push(
          {
            pathname: router.asPath.split('?')[0],
            query: userQueries,
          },
          undefined,
          { shallow: true },
        );
      }
      setProcessingUrl(false);
    }
  }, [router, processingUrl, cachedAdData]);
};

export default useAdTracking;
