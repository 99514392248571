import { useMemo } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import Image from 'next/image';
import DynamicElement from '../common/dynamicElement';
import Action from '../common/action';
import useBasicCarousel from '../../hooks/useBasicCarousel';

const IconCard = ({ title, cards, titleElement, id }) => {
  const cardContent = useMemo(
    () =>
      cards?.map(({ icon, description, title: cardTitle, titleElement: cardTitleElement = 'p', action }) => (
        <div key={icon} className="flex flex-col sm:rounded relative bg-white xs:px-10 sm:px-24 md:px-10">
          <div className="bg-ti-black rounded-full border-4 border-ti-green p-5 w-24 h-24 flex items-center justify-center mx-auto my-6">
            <Image alt={cardTitle} src={icon} height={48} width={48} lazyBoundary="1000px" />
          </div>
          <DynamicElement element={cardTitleElement} content={cardTitle} className="text-xl font-bold" />
          <p className="mt-5 mb-28 mx-auto text-lg">{description}</p>
          <Action
            {...action}
            className="ti-button ripple absolute bottom-10 left-0 right-0 mx-auto w-9/12"
            overrideBaseCss
          />
        </div>
      )),
    [cards],
  );

  const { activeItem, Carousel } = useBasicCarousel({
    items: cardContent,
    imagePageing: false,
    slidesToShow: 1,
    sliderClass: 'w-full text-center',
  });

  return (
    <section id={id} className="bg-ti-grey">
      <div id="icon-card-section-container" className="content-wrapper">
        <DynamicElement
          element={titleElement}
          content={title}
          className="font-bold sm:text-4xl text-3xl leading-normal text-center mb-16"
        />
        <div
          className={cn('md:grid grid-flow-col grid-cols-1 gap-4 text-center hidden', {
            'grid-rows-4 md:grid-cols-2 md:grid-rows-2 lg:grid-cols-4 lg:grid-rows-1': cards.length === 4,
            'grid-rows-3 md:grid-cols-3 md:grid-rows-1': cards.length === 3,
            'grid-rows-2 md:grid-cols-2 md:grid-rows-1': cards.length === 2,
          })}
        >
          {cardContent}
        </div>
        <div className="md:hidden w-full">{Carousel}</div>
      </div>
    </section>
  );
};

IconCard.defaultProps = {
  titleElement: 'p',
};

IconCard.propTypes = {
  title: PropTypes.string.isRequired,
  cards: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  titleElement: PropTypes.string,
  id: PropTypes.string.isRequired,
};

export default IconCard;
