import cn from 'classnames';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';

const FormTextArea = ({
  name,
  containerClasses,
  label,
  id,
  required,
  error,
  value,
  onChange,
  placeholder,
  rows,
  labelClass,
  disabled,
  action,
  actionIcon,
  actionTip,
  textareaClass,
  ...rest
}) => (
  <div className={containerClasses}>
    <label
      className={cn('block uppercase tracking-wide text-xs font-bold mb-2', {
        'text-black': !labelClass,
        [labelClass]: labelClass,
      })}
      htmlFor={id}
    >
      {label} {required && <span className="text-ti-green">*</span>}
    </label>
    <div className="relative">
      {action && (
        <button
          data-tip={actionTip || ''}
          className="absolute mt-2 right-5 cursor-pointer p-2 transition duration-300 rounded-full can-hover:hover:bg-ti-green can-hover:hover:shadow-lg"
          onClick={action}
        >
          {actionIcon}
        </button>
      )}
      <textarea
        disabled={disabled}
        value={value}
        onChange={onChange}
        name={name}
        rows={rows}
        placeholder={placeholder}
        className={cn(
          'focus:ring-0 border-input-grey w-full rounded border-2 resize-none p-5 outline-none focus:border-2 focus:border-ti-green text-ti-space-grey text-black',
          {
            'border-red-500': error,
            [textareaClass]: textareaClass,
          },
        )}
        {...rest}
      />
      {error && (
        <Fade bottom timeout={350}>
          <p className="text-red-500 text-xs italic my-1">{error}</p>
        </Fade>
      )}
    </div>
  </div>
);

FormTextArea.defaultProps = {
  value: null,
  error: null,
  containerClasses: '',
  required: false,
  rows: 4,
  disabled: false,
};

FormTextArea.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
  containerClasses: PropTypes.string,
  required: PropTypes.bool,
  rows: PropTypes.number,
};

export default FormTextArea;
