import PropTypes from 'prop-types';

const dealerships = [
  { manufaturer: 'DJI Enterprise', image: '/images/manufacturers/dji_enterprise.jpg' },
  { manufaturer: 'Quantum Systems', image: '/images/manufacturers/quantum.jpg' },
  { manufaturer: 'Autel', image: '/images/manufacturers/autel.jpg' },
  { manufaturer: 'Flir', image: '/images/manufacturers/flir.jpg' },
  { manufaturer: 'Intel', image: '/images/manufacturers/intel.jpg' },
  { manufaturer: 'YellowScan', image: '/images/manufacturers/yellowscan.jpg' },
  { manufaturer: 'Blue Vigil', image: '/images/manufacturers/bluevigill.jpg' },
  { manufaturer: 'AirData', image: '/images/manufacturers/airdata.jpg' },
  { manufaturer: 'Propeller', image: '/images/manufacturers/propeller.jpg' },
  { manufaturer: 'Go Professional Cases', image: '/images/manufacturers/gpc.jpg' },
  { manufaturer: 'Nanuk', image: '/images/manufacturers/nanuk.jpg' },
  { manufaturer: 'UgCS', image: '/images/manufacturers/ugcs.png' },
];

const DealerSlider = ({ id }) => {
  return (
    <section id={id} className="bg-ti-grey">
      <div className="content-wrapper">
        <h3 className="text-center font-semibold text-xl md:text-2xl lg:text-3xl mb-10 text-black">
          Terrestrial Imaging Is A Dealer For The Following Drone Manufacturers
        </h3>
        <div className="grid grid-col grid-cols-2 md:grid-cols-3 grid-rows-2 gap-4 text-black flex text-center bg-ti-grey items-center justify-center">
          {dealerships?.map(({ manufaturer, image }) => (
            <div className="shadow rounded my-5 bg-white w-full md:w-350 mx-auto relative" key={manufaturer}>
              <div className="image-container">
                <img src={image} alt={manufaturer} className="m-auto image" layout="fill" />
              </div>
              <h4 className="text-2xl font-semibold py-5 text-xs sm:text-sm md:text-lg"> {manufaturer}</h4>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

DealerSlider.propTypes = {
  id: PropTypes.string.isRequired,
};

export default DealerSlider;
