import { FiSend, FiFacebook } from 'react-icons/fi';
import { useRouter } from 'next/router';
import Link from 'next/link';
import cn from 'classnames';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { AiOutlineLinkedin, AiOutlineYoutube } from 'react-icons/ai';
import Accordion from '../common/accordion';
import Action from '../common/action';
import useWindowSize from '../../hooks/useWindowSize';
import { emailRegex } from '../../utils/regex';
import { errorToast, successToast } from '../../utils/toast';

const Footer = ({ navItems, subscribe }) => {
  const router = useRouter();
  const { width } = useWindowSize();
  const [email, setEmail] = useState('');
  const onChangeEmail = ({ target: { value } }) => {
    setEmail(value);
  };
  const goToContactSection = () => {
    const scrollTarget = document.getElementById('contact-section');
    if (router.pathname === '/' && scrollTarget) {
      scrollTarget.scrollIntoView({ behavior: 'smooth' });
      router.push(
        {
          pathname: '/',
        },
        undefined,
        { shallow: true },
      );
    } else {
      router.push('/#contact-section');
    }
  };
  const successfullSubscribe = () => {
    successToast('You have subscribed for news and updates!')();
    setEmail('');
  };
  const subscribeToList = () => {
    if (!emailRegex.test(email)) {
      errorToast('Please provide a valid email address')();
    } else {
      subscribe({
        email,
        successCallback: successfullSubscribe(),
        failureCallback: errorToast('There was an error subscribing you for news and updates'),
      });
    }
  };
  const openNewTab = (link) => () => {
    window.open(link, '_blank', 'noopener noreferrer');
  };
  return (
    <div id="terrestrial-imaging-footer" className="bg-ti-space-grey">
      <div id="container-wrapper" className="content-wrapper">
        <div className="flex flex-col md:flex-row">
          <div
            id="footer-contact-info-container"
            className="text-base md:text-lg w-full md:w-6/12 text-center border-r-0 md:border-r-2 border-ti-dim-grey text-white px-8"
          >
            <p className="text-lg md:text-xl text-ti-green font-semibold">CONTACT INFORMATION</p>
            <p className="my-5">1-800-359-0530</p>
            <p className="my-5">sales@terrestrialimaging.com</p>
            <p>375 Herbertsville Road</p>
            <p>Brick Township, NJ 08724</p>
            <div id="social-media-link-container" className="flex justify-center text-2xl md:text-3xl mt-5">
              <button
                onClick={openNewTab('https://www.linkedin.com/company/terrestrialimaging/')}
                aria-label="Terrestrial Imaging LinkedIn"
              >
                <AiOutlineLinkedin />
              </button>
              <button
                onClick={openNewTab('https://www.facebook.com/TerrestrialImaging')}
                aria-label="Terrestrial Imaging FaceBook"
              >
                <FiFacebook />
              </button>
              <button
                onClick={openNewTab('https://www.youtube.com/c/TerrestrialImagingLLC')}
                aria-label="Terrestrial Imaging YouTube"
              >
                <AiOutlineYoutube />
              </button>
            </div>
          </div>
          <div
            id="footer-contact-hours-container"
            className="text-base md:text-lg w-full mt-10 md:mt-0 md:w-6/12 text-center text-white px-8"
          >
            <p className="text-lg md:text-xl text-ti-green font-semibold">HOURS OF OPERATION</p>
            <p className="my-5">Mon - Fri | 9:00am est - 5:00pm est</p>
            <p>*By Appointment Only*</p>
            <div className="relative w-full sm:w-80 m-auto mt-5">
              <input
                className="text-black text-sm rounded appearance-none block w-full border-2 rounded py-3 px-4 pr-6 mb-1 leading-tight focus:outline-none focus:border-ti-green overflow-ellipsis"
                type="text"
                autoComplete="on"
                name="email"
                placeholder="Subscribe for news and events"
                value={email}
                onChange={onChangeEmail}
              />
              <button
                className="absolute inset-y-0 right-0 flex items-center px-2 ti-button ripple w-16 rounded-l-none rounded-r shadow-none"
                aria-label="Subscribe to email list"
                onClick={subscribeToList}
              >
                <FiSend className="m-auto" />
              </button>
            </div>
          </div>
        </div>
        <hr className="w-full mb-20 mt-20" />
        <div
          className={cn('flex justify-evenly', {
            'flex-row': width >= 1280,
            'flex-col': width < 1280,
          })}
        >
          {navItems?.map(({ category, link: categoryLink, subCategories, external = false, type = 'button' }) =>
            width >= 1280 ? (
              <div className="mx-8" key={`${category}-footer-div`}>
                {subCategories && subCategories.length > 0 ? (
                  <p key={category} className="text-white text-sm cursor-default font-semibold">
                    {category}
                  </p>
                ) : external ? (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={categoryLink}
                    key={category}
                    className="text-white text-sm font-semibold cursor-pointer"
                  >
                    {category}
                  </a>
                ) : (
                  <Action
                    onClick={category === 'Contact Us' ? goToContactSection : null}
                    type={type}
                    text={category}
                    link={categoryLink}
                    target={categoryLink}
                    external={external}
                    overrideBaseCss
                    key={category}
                    className="text-white text-sm font-semibold cursor-pointer"
                  />
                )}
                {subCategories?.length > 0 && (
                  <ul>
                    {subCategories.map(({ subCategory, link: subCategoryLink }) => (
                      <li
                        className="text-ti-space-grey-text text-sm cursor-pointer can-hover:hover:underline my-2"
                        key={`${subCategory}-footer-li`}
                      >
                        <Link
                          href={
                            subCategoryLink.includes('http') ? subCategoryLink : `${categoryLink}${subCategoryLink}`
                          }
                        >
                          <a>{subCategory}</a>
                        </Link>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            ) : subCategories && subCategories.length > 0 ? (
              <Accordion
                summary={category}
                className="text-sm border-b-2 border-ti-dim-grey text-white"
                key={`${category}-acordion`}
              >
                <ul>
                  {subCategories?.map(({ subCategory, link: subCategoryLink }) => (
                    <li
                      className="text-ti-space-grey-text text-sm cursor-pointer can-hover:hover:underline my-4"
                      key={`${subCategory}-footer-accordion`}
                    >
                      <Link
                        href={subCategoryLink.includes('http') ? subCategoryLink : `${categoryLink}${subCategoryLink}`}
                      >
                        <a>{subCategory}</a>
                      </Link>
                    </li>
                  ))}
                </ul>
              </Accordion>
            ) : external ? (
              <a
                key={`${category}-external-link`}
                target="_blank"
                rel="noopener noreferrer"
                href={categoryLink}
                className="flex justify-between items-center text-white py-5 cursor-pointer text-sm border-b-2 border-ti-dim-grey"
              >
                {category}
              </a>
            ) : (
              <Action
                key={`${category}-action`}
                onClick={category === 'Contact Us' ? goToContactSection : null}
                type={type}
                text={category}
                link={categoryLink}
                target={categoryLink}
                external={external}
                overrideBaseCss
                className="flex justify-between items-center text-white py-5 cursor-pointer text-sm border-b-2 border-ti-dim-grey"
              />
            ),
          )}
        </div>
        <hr className="w-full mb-5 mt-20" />
        <p className="text-sm text-ti-space-grey-text text-center">{`©2014-${new Date().getFullYear()} Terrestrial Imaging, LLC, All Rights Reserved. 800.FLY.0530`}</p>
      </div>
    </div>
  );
};

Footer.propTypes = {
  navItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default Footer;
