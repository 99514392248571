import {
  LOAD_TRACKED_SERVICES_REQUEST,
  LOAD_TRACKED_SERVICES_SUCCESS,
  LOAD_TRACKED_SERVICES_FAILURE,
  LOAD_TRACKABLE_SERVICE_TYPES_REQUEST,
  LOAD_TRACKABLE_SERVICE_TYPES_SUCCESS,
  LOAD_TRACKABLE_SERVICE_TYPES_FAILURE,
  CLEAR_TRACKABLE_SERVICE_TYPES,
  CREATE_TRACKING_DATA_REQUEST,
  CREATE_TRACKING_DATA_SUCCESS,
  CREATE_TRACKING_DATA_FAILURE,
} from '../actions/types';

const initialState = {
  loading: false,
  errors: null,
  trackedServices: {
    items: [],
    count: 0,
  },
  trackableServiceTypes: {
    items: [],
    count: 0,
  },
};

const paymentReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case CREATE_TRACKING_DATA_REQUEST:
    case CLEAR_TRACKABLE_SERVICE_TYPES: {
      return {
        ...state,
        trackableServiceTypes: {
          count: 0,
          items: [],
        },
      };
    }
    case LOAD_TRACKABLE_SERVICE_TYPES_REQUEST:
    case LOAD_TRACKED_SERVICES_REQUEST: {
      return { ...state, loading: true };
    }
    case CREATE_TRACKING_DATA_SUCCESS:
    case LOAD_TRACKED_SERVICES_SUCCESS: {
      return {
        ...state,
        loading: false,
        errors: null,
        trackedServices: {
          count: payload.count,
          items: payload.items,
        },
      };
    }
    case LOAD_TRACKABLE_SERVICE_TYPES_SUCCESS: {
      return {
        ...state,
        loading: false,
        errors: null,
        trackableServiceTypes: {
          count: payload.count,
          items: [...state.trackableServiceTypes.items, ...payload.items],
        },
      };
    }
    case CREATE_TRACKING_DATA_FAILURE:
    case LOAD_TRACKABLE_SERVICE_TYPES_FAILURE:
    case LOAD_TRACKED_SERVICES_FAILURE: {
      return {
        ...state,
        loading: false,
        errors: payload,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default paymentReducer;
