import { useRef, useEffect } from 'react';
import { useRouter } from 'next/router';
import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io';
import Slider from 'react-slick';
import cn from 'classnames';
import debounce from 'debounce';
import PropTypes from 'prop-types';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const NextArrow = ({ onClick }) => (
  <div
    className="can-hover:hover:bg-opacity-90 shadow-xl flex items-center text-white bg-ti-dim-grey bg-opacity-60 p-1 absolute right-0 top-1/2 bottom-1/2 h-8 mx-4 rounded-full z-40 can-hover:hover:cursor-pointer"
    onClick={onClick}
  >
    <IoIosArrowForward className="text-2xl" />
  </div>
);

NextArrow.defaultProps = {
  onClick: null,
};

NextArrow.propTypes = {
  onClick: PropTypes.func,
};

const PrevArrow = ({ onClick }) => (
  <div
    className="can-hover:hover:bg-opacity-90 shadow-xl flex items-center text-white bg-ti-dim-grey bg-opacity-60 p-1 absolute left-0 top-1/2 bottom-1/2 h-8 mx-4 rounded-full z-40 can-hover:hover:cursor-pointer"
    onClick={onClick}
  >
    <IoIosArrowBack className="text-2xl" />
  </div>
);

PrevArrow.defaultProps = {
  onClick: null,
};

PrevArrow.propTypes = {
  onClick: PropTypes.func,
};

const BasicCarousel = ({ items, activeItem, setActiveItem, sliderClass, slidesToShow }) => {
  const router = useRouter();
  const sliderRef = useRef();

  const afterChange = (slide) => {
    if (activeItem !== slide) {
      setActiveItem(slide);
    }
  };

  const slickSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 5000,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    lazyLoad: 'ondemand',
  };

  const resetSlide = () => {
    sliderRef?.current?.slickGoTo(0);
    if (activeItem !== 0) {
      setActiveItem(0);
    }
  };

  const resetSlideDebounced = debounce(resetSlide, 800);

  useEffect(() => {
    resetSlideDebounced();
  }, [router]);

  useEffect(() => {
    if (activeItem === 0) resetSlide();
  }, [activeItem]);

  return (
    <div className={cn('relative', { [sliderClass]: sliderClass })}>
      <Slider {...slickSettings} ref={sliderRef} afterChange={afterChange}>
        {items}
      </Slider>
    </div>
  );
};

BasicCarousel.defaultProps = {
  imagePageing: false,
  sliderClass: null,
  slidesToShow: 1,
};

BasicCarousel.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  activeItem: PropTypes.number.isRequired,
  setActiveItem: PropTypes.func.isRequired,
  imagePageing: PropTypes.bool,
  sliderClass: PropTypes.string,
  slidesToShow: PropTypes.number,
};

export default BasicCarousel;
