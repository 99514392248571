import PropTypes from 'prop-types';
import SpanGenerator from '../common/spanGenerator';
import Fade from 'react-reveal/Fade';

const TextTitleBanner = ({ title, text, titleElement, textElement, id }) => (
  <Fade bottom>
    <section id={id} className="bg-ti-space-grey">
      <div className="content-wrapper">
        <SpanGenerator
          content={title}
          contentType={titleElement}
          textClassName="font-semibold my-3 md:text-5xl text-4xl text-white text-center"
          spanClassName="text-ti-green"
          fontCase="upper"
        />
        <SpanGenerator
          content={text}
          contentType={textElement}
          textClassName="text-white text-xl text-center mt-10"
          spanClassName="text-ti-green"
        />
      </div>
    </section>
  </Fade>
);

TextTitleBanner.defaultProps = {
  titleElement: 'p',
  textElement: 'p',
};

TextTitleBanner.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  titleElement: PropTypes.string,
  textElement: PropTypes.string,
  id: PropTypes.string.isRequired,
};

export default TextTitleBanner;
