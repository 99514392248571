import { useCallback, useEffect, useState } from 'react';
import Image from 'next/image';
import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io';
import cn from 'classnames';
import Gif from '../picture/Gif';
import PropTypes from 'prop-types';
import Action from '../action';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const FadeImage = ({ className, onLoad, ...props }) => {
  const [isReady, setIsReady] = useState(false);

  const onLoadCallback = (e) => {
    setIsReady(true);
    typeof onLoad === 'function' && onLoad(e);
  };

  return (
    <div
      className={cn('opacity-0 transition-[opacity] ease-in-out duration-300 h-full w-full  ', {
        '!opacity-100': isReady,
      })}
    >
      <Image
        onLoadingComplete={onLoadCallback}
        {...props}
        placeholder="blur"
        blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mO88+hpPQAIaQMkhW8UjAAAAABJRU5ErkJggg=="
        layout="responsive"
        objectFit="contain"
      />
    </div>
  );
};

const ImageCarousel = ({ items, imagePageing, sliderClass }) => {
  const [view, setView] = useState(0);
  const [manualScrolling, setManualScrolling] = useState(false);

  const changeView = (index) => (e) => {
    e.stopPropagation();
    setManualScrolling(false);
    const target = index === items.length ? 0 : index === -1 ? items.length - 1 : index;
    setView(target);
  };

  const isVisible = (element) => {
    const rect = element.getBoundingClientRect();

    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  };

  const onscroll = useCallback(() => {
    if (manualScrolling) {
      const visibleDiv = [...document.querySelectorAll('[id^="slide-"]')].find((ele) => isVisible(ele));
      if (visibleDiv) {
        setView(+visibleDiv.getAttribute('id').split('-')[1]);
      }
    }
  }, [manualScrolling]);

  useEffect(() => {
    if (!manualScrolling) {
      document
        .querySelector(`#slide-${view}`)
        .scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
    }
  }, [view, manualScrolling]);

  useEffect(() => {
    const slider = document.getElementById('gallery');
    let isDown = false;
    let startX;
    let scrollLeft;

    const mousedown = (e) => {
      isDown = true;
      slider.classList.add('active');
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
      setManualScrolling(true);
    };

    const mouseleave = () => {
      isDown = false;
      slider.classList.remove('active');
    };

    const mouseup = () => {
      isDown = false;
      slider.classList.remove('active');
    };

    const mousemove = (e) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - slider.offsetLeft;
      const walk = (x - startX) * 1.5; //scroll-fast
      slider.scrollLeft = scrollLeft - walk;
    };

    slider.addEventListener('mousedown', mousedown);
    slider.addEventListener('mouseleave', mouseleave);
    slider.addEventListener('mouseup', mouseup);
    slider.addEventListener('mousemove', mousemove);

    return () => {
      slider.removeEventListener('mousedown', mousedown);
      slider.removeEventListener('mouseleave', mouseleave);
      slider.removeEventListener('mouseup', mouseup);
      slider.removeEventListener('mousemove', mousemove);
    };
  }, []);

  return (
    <div className="flex flex-wrap">
      {imagePageing && items.length > 1 && (
        <div className="w-full md:w-2/12 flex flex-row md:flex-col order-2 md:order-1 items-center">
          {items?.map(({ thumb, alt }, index) => (
            <button
              key={thumb}
              className={cn('mx-auto cursor-pointer p-2 transition duration-300 ', {
                'border-2 border-transparent transition duration-300': view !== index,
                'border-2 border-ti-green transition duration-300': view === index,
              })}
              onClick={changeView(index)}
              value={index}
              style={{ width: '80px', height: '60px' }}
            >
              <Image alt={alt} src={thumb} width={60} height={40} priority layout="responsive" objectFit="contain" />
            </button>
          ))}
        </div>
      )}

      <div
        className={cn('order-1 md:order-2 relative', {
          'w-full': !imagePageing,
          'w-full md:w-10/12': imagePageing,
        })}
      >
        {items.length > 1 && (
          <>
            <div
              className="can-hover:hover:bg-opacity-90 shadow-xl flex items-center text-white bg-ti-dim-grey bg-opacity-60 p-1 absolute left-0 top-1/2 bottom-1/2 h-8 mx-4 rounded-full z-40 can-hover:hover:cursor-pointer"
              onClick={changeView(view - 1)}
            >
              <IoIosArrowBack className="text-2xl" />
            </div>
            <div
              className="can-hover:hover:bg-opacity-90 shadow-xl flex items-center text-white bg-ti-dim-grey bg-opacity-60 p-1 absolute right-0 top-1/2 bottom-1/2 h-8 mx-4 rounded-full z-40 can-hover:hover:cursor-pointer"
              onClick={changeView(view + 1)}
            >
              <IoIosArrowForward className="text-2xl" />
            </div>
          </>
        )}
        <div id="gallery" className="gallerycss no-scrollbar scroll-smooth" onScroll={onscroll}>
          {items?.map(({ image, alt, action1, action2, warning = null, time }, index) => (
            <div key={image} id={`slide-${index}`} className="slidecss cursor-grab">
              <div className="flex flex-col items-center">
                {image.includes('.gif') ? (
                  <Gif key={`${image}-key-gif`} alt={alt} src={image} warning={warning} />
                ) : image.includes('.webm') ? (
                  <video controls src={image} loop autoPlay muted />
                ) : (
                  <FadeImage
                    key={`${image}-key`}
                    alt={alt}
                    src={image}
                    width={600}
                    height={400}
                    className="mx-auto min-h-400 cursor-grab"
                    priority={index === 0}
                  />
                )}
                <div
                  id="featured-item-image-action-container"
                  className="flex flex-col justify-center items-center mt-5 w-full"
                >
                  {action1 && <Action {...action1} className="justify-center" />}
                  {action2 && <Action {...action2} className="ml-0 text-ti-link mt-5 xs:text-sm" />}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

ImageCarousel.defaultProps = {
  imagePageing: false,
  sliderClass: null,
};

ImageCarousel.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  activeItem: PropTypes.number.isRequired,
  setActiveItem: PropTypes.func.isRequired,
  imagePageing: PropTypes.bool,
  sliderClass: PropTypes.string,
};

export default ImageCarousel;
