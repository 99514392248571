// VERIFIED REDUX TYPE ACTIONS

export const DESTROY_SESSION = 'DESTROY_SESSION';

//rma
export const CREATE_RMA_REQUEST = 'CREATE_RMA_REQUEST';
export const CREATE_RMA_SUCCESS = 'CREATE_RMA_SUCCESS';
export const CREATE_RMA_FAILURE = 'CREATE_RMA_FAILURE';
export const LOAD_RMA_MATCHES_REQUEST = 'LOAD_RMA_MATCHES_REQUEST';
export const LOAD_RMA_MATCHES_SUCCESS = 'LOAD_RMA_MATCHES_SUCCESS';
export const LOAD_RMA_MATCHES_FAILURE = 'LOAD_RMA_MATCHES_FAILURE';
export const CLEAR_ALL_RMAS = 'CLEAR_ALL_RMAS';

//service tracker
export const LOAD_TRACKED_SERVICES_REQUEST = 'LOAD_TRACKED_SERVICES_REQUEST';
export const LOAD_TRACKED_SERVICES_SUCCESS = 'LOAD_TRACKED_SERVICES_SUCCESS';
export const LOAD_TRACKED_SERVICES_FAILURE = 'LOAD_TRACKED_SERVICES_FAILURE';
export const LOAD_TRACKABLE_SERVICE_TYPES_REQUEST = 'LOAD_TRACKABLE_SERVICE_TYPES_REQUEST';
export const LOAD_TRACKABLE_SERVICE_TYPES_SUCCESS = 'LOAD_TRACKABLE_SERVICE_TYPES_SUCCESS';
export const LOAD_TRACKABLE_SERVICE_TYPES_FAILURE = 'LOAD_TRACKABLE_SERVICE_TYPES_FAILURE';
export const CLEAR_TRACKABLE_SERVICE_TYPES = 'CLEAR_TRACKABLE_SERVICE_TYPES';
export const CREATE_TRACKING_DATA_REQUEST = 'CREATE_TRACKING_DATA_REQUEST';
export const CREATE_TRACKING_DATA_SUCCESS = 'CREATE_TRACKING_DATA_SUCCESS';
export const CREATE_TRACKING_DATA_FAILURE = 'CREATE_TRACKING_DATA_FAILURE';

//assets

export const CREATE_MAINTENANCE_LOG_REQUEST = 'CREATE_MAINTENANCE_LOG_REQUEST';
export const CREATE_MAINTENANCE_LOG_SUCCESS = 'CREATE_MAINTENANCE_LOG_SUCCESS';
export const CREATE_MAINTENANCE_LOG_FAILURE = 'CREATE_MAINTENANCE_LOG_FAILURE';
export const LOAD_ASSETS_REQUEST = 'LOAD_ASSETS_REQUEST';
export const LOAD_ASSETS_SUCCESS = 'LOAD_ASSETS_SUCCESS';
export const LOAD_ASSETS_FAILURE = 'LOAD_ASSETS_FAILURE';
export const CREATE_ASSET_REQUEST = 'CREATE_ASSET_REQUEST';
export const CREATE_ASSET_SUCCESS = 'CREATE_ASSET_SUCCESS';
export const CREATE_ASSET_FAILURE = 'CREATE_ASSET_FAILURE';
export const LOAD_ASSET_STATUSES_REQUEST = 'LOAD_ASSET_STATUSES_REQUEST';
export const LOAD_ASSET_STATUSES_SUCCESS = 'LOAD_ASSET_STATUSES_SUCCESS';
export const LOAD_ASSET_STATUSES_FAILURE = 'LOAD_ASSET_STATUSES_FAILURE';
export const CLEAR_ASSET_STATUSES = 'CLEAR_ASSET_STATUSES';
export const CLEAR_ASSETS = 'CLEAR_ASSETS';
export const UPDATE_DELETE_ASSET_REQUEST = 'UPDATE_DELETE_ASSET_REQUEST';
export const UPDATE_DELETE_ASSET_SUCCESS = 'UPDATE_DELETE_ASSET_SUCCESS';
export const UPDATE_DELETE_ASSET_FAILURE = 'UPDATE_DELETE_ASSET_FAILURE';
export const UPDATE_ASSIGN_ASSET_REQUEST = 'UPDATE_ASSIGN_ASSET_REQUEST';
export const UPDATE_ASSIGN_ASSET_SUCCESS = 'UPDATE_ASSIGN_ASSET_SUCCESS';
export const UPDATE_ASSIGN_ASSET_FAILURE = 'UPDATE_ASSIGN_ASSET_FAILURE';
export const LOAD_ASSET_HISTORY_REQUEST = 'LOAD_ASSET_HISTORY_REQUEST';
export const LOAD_ASSET_HISTORY_SUCCESS = 'LOAD_ASSET_HISTORY_SUCCESS';
export const LOAD_ASSET_HISTORY_FAILURE = 'LOAD_ASSET_HISTORY_FAILURE';
export const LOAD_TICKETS_REQUEST = 'LOAD_TICKETS_REQUEST';
export const LOAD_TICKETS_SUCCESS = 'LOAD_TICKETS_SUCCESS';
export const LOAD_TICKETS_FAILURE = 'LOAD_TICKETS_FAILURE';
export const LOAD_TICKET_REQUEST = 'LOAD_TICKET_REQUEST';
export const LOAD_TICKET_SUCCESS = 'LOAD_TICKET_SUCCESS';
export const LOAD_TICKET_FAILURE = 'LOAD_TICKET_FAILURE';
export const LOAD_TICKET_HISTORY_REQUEST = 'LOAD_TICKET_HISTORY_REQUEST';
export const LOAD_TICKET_HISTORY_SUCCESS = 'LOAD_TICKET_HISTORY_SUCCESS';
export const LOAD_TICKET_HISTORY_FAILURE = 'LOAD_TICKET_HISTORY_FAILURE';
export const CLEAR_TICKETS = 'CLEAR_TICKETS';
export const CREATE_TICKET_REQUEST = 'CREATE_TICKET_REQUEST';
export const CREATE_TICKET_SUCCESS = 'CREATE_TICKET_SUCCESS';
export const CREATE_TICKET_FAILURE = 'CREATE_TICKET_FAILURE';
export const UPDATE_TICKET_REQUEST = 'UPDATE_TICKET_REQUEST';
export const UPDATE_TICKET_SUCCESS = 'UPDATE_TICKET_SUCCESS';
export const UPDATE_TICKET_FAILURE = 'UPDATE_TICKET_FAILURE';
export const LOAD_ACCESSORIES_REQUEST = 'LOAD_ACCESSORIES_REQUEST';
export const LOAD_ACCESSORIES_SUCCESS = 'LOAD_ACCESSORIES_SUCCESS';
export const LOAD_ACCESSORIES_FAILURE = 'LOAD_ACCESSORIES_FAILURE';
export const CLEAR_ACCESSORIES = 'CLEAR_ACCESSORIES';
export const CREATE_ACCESSORY_REQUEST = 'CREATE_ACCESSORY_REQUEST';
export const CREATE_ACCESSORY_SUCCESS = 'CREATE_ACCESSORY_SUCCESS';
export const CREATE_ACCESSORY_FAILURE = 'CREATE_ACCESSORY_FAILURE';
export const LOAD_ACCESSORY_HISTORY_REQUEST = 'LOAD_ACCESSORY_HISTORY_REQUEST';
export const LOAD_ACCESSORY_HISTORY_SUCCESS = 'LOAD_ACCESSORY_HISTORY_SUCCESS';
export const LOAD_ACCESSORY_HISTORY_FAILURE = 'LOAD_ACCESSORY_HISTORY_FAILURE';
export const CREATE_KIT_REQUEST = 'CREATE_KIT_REQUEST';
export const CREATE_KIT_SUCCESS = 'CREATE_KIT_SUCCESS';
export const CREATE_KIT_FAILURE = 'CREATE_KIT_FAILURE';
export const LOAD_KITS_REQUEST = 'LOAD_KITS_REQUEST';
export const LOAD_KITS_SUCCESS = 'LOAD_KITS_SUCCESS';
export const LOAD_KITS_FAILURE = 'LOAD_KITS_FAILURE';
export const CLEAR_KITS = 'CLEAR_KITS';
export const LOAD_KIT_HISTORY_REQUEST = 'LOAD_KIT_HISTORY_REQUEST';
export const LOAD_KIT_HISTORY_SUCCESS = 'LOAD_KIT_HISTORY_SUCCESS';
export const LOAD_KIT_HISTORY_FAILURE = 'LOAD_KIT_HISTORY_FAILURE';
export const UPDATE_ASSIGN_ACCESSORIES_REQUEST = 'UPDATE_ASSIGN_ACCESSORIES_REQUEST';
export const UPDATE_ASSIGN_ACCESSORIES_SUCCESS = 'UPDATE_ASSIGN_ACCESSORIES_SUCCESS';
export const UPDATE_ASSIGN_ACCESSORIES_FAILURE = 'UPDATE_ASSIGN_ACCESSORIES_FAILURE';
export const UPDATE_ASSIGN_KIT_REQUEST = 'UPDATE_ASSIGN_KIT_REQUEST';
export const UPDATE_ASSIGN_KIT_SUCCESS = 'UPDATE_ASSIGN_KIT_SUCCESS';
export const UPDATE_ASSIGN_KIT_FAILURE = 'UPDATE_ASSIGN_KIT_FAILURE';
export const LOAD_ASSET_REQUEST = 'LOAD_ASSET_REQUEST';
export const LOAD_ASSET_SUCCESS = 'LOAD_ASSET_SUCCESS';
export const LOAD_ASSET_FAILURE = 'LOAD_ASSET_FAILURE';
export const LOAD_ACCESSORY_REQUEST = 'LOAD_ACCESSORY_REQUEST';
export const LOAD_ACCESSORY_SUCCESS = 'LOAD_ACCESSORY_SUCCESS';
export const LOAD_ACCESSORY_FAILURE = 'LOAD_ACCESSORY_FAILURE';
export const LOAD_KIT_REQUEST = 'LOAD_KIT_REQUEST';
export const LOAD_KIT_SUCCESS = 'LOAD_KIT_SUCCESS';
export const LOAD_KIT_FAILURE = 'LOAD_KIT_FAILURE';
export const UPDATE_TICKET_ASSIGN_TO_SELF_REQUEST = 'UPDATE_TICKET_ASSIGN_TO_SELF_REQUEST';
export const UPDATE_TICKET_ASSIGN_TO_SELF_SUCCESS = 'UPDATE_TICKET_ASSIGN_TO_SELF_SUCCESS';
export const UPDATE_TICKET_ASSIGN_TO_SELF_FAILURE = 'UPDATE_TICKET_ASSIGN_TO_SELF_FAILURE';

//domains
export const LOAD_DOMAIN_REQUEST = 'LOAD_DOMAIN_REQUEST';
export const LOAD_DOMAIN_SUCCESS = 'LOAD_DOMAIN_SUCCESS';
export const LOAD_DOMAIN_FAILURE = 'LOAD_DOMAIN_FAILURE';
export const SET_DOMAIN = 'SET_DOMAIN';

//payments
export const LOAD_PAYMENTS_REQUEST = 'LOAD_PAYMENTS_REQUEST';
export const LOAD_PAYMENTS_SUCCESS = 'LOAD_PAYMENTS_SUCCESS';
export const LOAD_PAYMENTS_FAILURE = 'LOAD_PAYMENTS_FAILURE';
export const LOAD_PAYMENT_REQUEST = 'LOAD_PAYMENT_REQUEST';
export const LOAD_PAYMENT_SUCCESS = 'LOAD_PAYMENT_SUCCESS';
export const LOAD_PAYMENT_FAILURE = 'LOAD_PAYMENT_FAILURE';
export const UPDATE_PAYMENT_REQUEST = 'UPDATE_PAYMENT_REQUEST';
export const UPDATE_PAYMENT_SUCCESS = 'UPDATE_PAYMENT_SUCCESS';
export const UPDATE_PAYMENT_FAILURE = 'UPDATE_PAYMENT_FAILURE';
export const CREATE_PAYMENT_REMINDER_REQUEST = 'CREATE_PAYMENT_REMINDER_REQUEST';
export const CREATE_PAYMENT_REMINDER_SUCCESS = 'CREATE_PAYMENT_REMINDER_SUCCESS';
export const CREATE_PAYMENT_REMINDER_FAILURE = 'CREATE_PAYMENT_REMINDER_FAILURE';
export const UPDATE_PAY_PAYMENT_RECORD_REQUEST = 'UPDATE_PAY_PAYMENT_RECORD_REQUEST';
export const UPDATE_PAY_PAYMENT_RECORD_SUCCESS = 'UPDATE_PAY_PAYMENT_RECORD_SUCCESS';
export const UPDATE_PAY_PAYMENT_RECORD_FAILURE = 'UPDATE_PAY_PAYMENT_RECORD_FAILURE';
export const CREATE_PAYMENT_REQUEST_REQUEST = 'CREATE_PAYMENT_REQUEST_REQUEST';
export const CREATE_PAYMENT_REQUEST_SUCCESS = 'CREATE_PAYMENT_REQUEST_SUCCESS';
export const CREATE_PAYMENT_REQUEST_FAILURE = 'CREATE_PAYMENT_REQUEST_FAILURE';
export const CLEAR_PAYMENT_RECORD = 'CLEAR_PAYMENT_RECORD';
export const LOAD_PAYMENTS_STATISTICS_REQUEST = 'LOAD_PAYMENTS_STATISTICS_REQUEST';
export const LOAD_PAYMENTS_STATISTICS_SUCCESS = 'LOAD_PAYMENTS_STATISTICS_SUCCESS';
export const LOAD_PAYMENTS_STATISTICS_FAILURE = 'LOAD_PAYMENTS_STATISTICS_FAILURE';
export const UPDATE_PAYMENT_APPROVE_DECLINE_REQUEST = 'UPDATE_PAYMENT_APPROVE_DECLINE_REQUEST';
export const UPDATE_PAYMENT_APPROVE_DECLINE_SUCCESS = 'UPDATE_PAYMENT_APPROVE_DECLINE_SUCCESS';
export const UPDATE_PAYMENT_APPROVE_DECLINE_FAILURE = 'UPDATE_PAYMENT_APPROVE_DECLINE_FAILURE';
export const UPDATE_PAYMENTS_STATUS_REQUEST = 'UPDATE_PAYMENTS_STATUS_REQUEST';
export const UPDATE_PAYMENTS_STATUS_SUCCESS = 'UPDATE_PAYMENTS_STATUS_SUCCESS';
export const UPDATE_PAYMENTS_STATUS_FAILURE = 'UPDATE_PAYMENTS_STATUS_FAILURE';
export const CLEAR_ALL_PAYMENTS = 'CLEAR_ALL_PAYMENTS';

//USERS
export const LOAD_ROLES_REQUEST = 'LOAD_ROLES_REQUEST';
export const LOAD_ROLES_SUCCESS = 'LOAD_ROLES_SUCCESS';
export const LOAD_ROLES_FAILURE = 'LOAD_ROLES_FAILURE';
export const CREATE_USER_REQUEST = 'CREATE_USER_REQUEST';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE';
export const LOAD_USERS_REQUEST = 'LOAD_USERS_REQUEST';
export const LOAD_USERS_SUCCESS = 'LOAD_USERS_SUCCESS';
export const LOAD_USERS_FAILURE = 'LOAD_USERS_FAILURE';
export const LOAD_USER_BY_ID_REQUEST = 'LOAD_USER_BY_ID_REQUEST';
export const LOAD_USER_BY_ID_SUCCESS = 'LOAD_USER_BY_ID_SUCCESS';
export const LOAD_USER_BY_ID_FAILURE = 'LOAD_USER_BY_ID_FAILURE';
export const CLEAR_USERS = 'CLEAR_USERS';
export const LOAD_SEARCH_TERM_USERS_REQUEST = 'LOAD_SEARCH_TERM_USERS_REQUEST';
export const LOAD_SEARCH_TERM_USERS_SUCCESS = 'LOAD_SEARCH_TERM_USERS_SUCCESS';
export const LOAD_SEARCH_TERM_USERS_FAILURE = 'LOAD_SEARCH_TERM_USERS_FAILURE';
export const CLEAR_SEARCH_TERM_USERS = 'CLEAR_SEARCH_TERM_USERS';
export const LOAD_EMPLOYEE_SEARCH_TERM_USERS_REQUEST = 'LOAD_EMPLOYEE_SEARCH_TERM_USERS_REQUEST';
export const LOAD_EMPLOYEE_SEARCH_TERM_USERS_SUCCESS = 'LOAD_EMPLOYEE_SEARCH_TERM_USERS_SUCCESS';
export const LOAD_EMPLOYEE_SEARCH_TERM_USERS_FAILURE = 'LOAD_EMPLOYEE_SEARCH_TERM_USERS_FAILURE';
export const UPDATE_USER_EQUIPMENT_ORDER_REQUEST = 'UPDATE_USER_EQUIPMENT_ORDER_REQUEST';
export const UPDATE_USER_EQUIPMENT_ORDER_SUCCESS = 'UPDATE_USER_EQUIPMENT_ORDER_SUCCESS';
export const UPDATE_USER_EQUIPMENT_ORDER_FAILURE = 'UPDATE_USER_EQUIPMENT_ORDER_FAILURE';
export const UPDATE_USER_EQUIPMENT_GROUP_NAME_REQUEST = 'UPDATE_USER_EQUIPMENT_GROUP_NAME_REQUEST';
export const UPDATE_USER_EQUIPMENT_GROUP_NAME_SUCCESS = 'UPDATE_USER_EQUIPMENT_GROUP_NAME_SUCCESS';
export const UPDATE_USER_EQUIPMENT_GROUP_NAME_FAILURE = 'UPDATE_USER_EQUIPMENT_GROUP_NAME_FAILURE';
export const LOAD_USER_EQUIPMENT_REQUEST = 'LOAD_USER_EQUIPMENT_REQUEST';
export const LOAD_USER_EQUIPMENT_SUCCESS = 'LOAD_USER_EQUIPMENT_SUCCESS';
export const LOAD_USER_EQUIPMENT_FAILURE = 'LOAD_USER_EQUIPMENT_FAILURE';

//PRODUCT
export const CREATE_MANUFACTURER_REQUEST = 'CREATE_MANUFACTURER_REQUEST';
export const CREATE_MANUFACTURER_SUCCESS = 'CREATE_MANUFACTURER_SUCCESS';
export const CREATE_MANUFACTURER_FAILURE = 'CREATE_MANUFACTURER_FAILURE';
export const LOAD_MANUFACTURERS_REQUEST = 'LOAD_MANUFACTURERS_REQUEST';
export const LOAD_MANUFACTURERS_SUCCESS = 'LOAD_MANUFACTURERS_SUCCESS';
export const LOAD_MANUFACTURERS_FAILURE = 'LOAD_MANUFACTURERS_FAILURE';
export const UPDATE_MANUFACTURER_REQUEST = 'UPDATE_MANUFACTURER_REQUEST';
export const UPDATE_MANUFACTURER_SUCCESS = 'UPDATE_MANUFACTURER_SUCCESS';
export const UPDATE_MANUFACTURER_FAILURE = 'UPDATE_MANUFACTURER_FAILURE';
export const CREATE_PRODUCT_REQUEST = 'CREATE_PRODUCT_REQUEST';
export const CREATE_PRODUCT_SUCCESS = 'CREATE_PRODUCT_SUCCESS';
export const CREATE_PRODUCT_FAILURE = 'CREATE_PRODUCT_FAILURE';
export const LOAD_ALL_PRODUCTS_REQUEST = 'LOAD_ALL_PRODUCTS_REQUEST';
export const LOAD_ALL_PRODUCTS_SUCCESS = 'LOAD_ALL_PRODUCTS_SUCCESS';
export const LOAD_ALL_PRODUCTS_FAILURE = 'LOAD_ALL_PRODUCTS_FAILURE';
export const UPDATE_PRODUCT_REQUEST = 'UPDATE_PRODUCT_REQUEST';
export const UPDATE_PRODUCT_SUCCESS = 'UPDATE_PRODUCT_SUCCESS';
export const UPDATE_PRODUCT_FAILURE = 'UPDATE_PRODUCT_FAILURE';
export const CLEAR_ALL_PRODUCTS = 'CLEAR_ALL_PRODUCTS';
export const CREATE_CATEGORY_REQUEST = 'CREATE_CATEGORY_REQUEST';
export const CREATE_CATEGORY_SUCCESS = 'CREATE_CATEGORY_SUCCESS';
export const CREATE_CATEGORY_FAILURE = 'CREATE_CATEGORY_FAILURE';
export const UPDATE_CATEGORY_REQUEST = 'UPDATE_CATEGORY_REQUEST';
export const UPDATE_CATEGORY_SUCCESS = 'UPDATE_CATEGORY_SUCCESS';
export const UPDATE_CATEGORY_FAILURE = 'UPDATE_CATEGORY_FAILURE';
export const LOAD_CATEGORIES_REQUEST = 'LOAD_CATEGORIES_REQUEST';
export const LOAD_CATEGORIES_SUCCESS = 'LOAD_CATEGORIES_SUCCESS';
export const LOAD_CATEGORIES_FAILURE = 'LOAD_CATEGORIES_FAILURE';

// INVENTORY
export const LOAD_DOMAIN_INVENTORY_REQUEST = 'LOAD_DOMAIN_INVENTORY_REQUEST';
export const LOAD_DOMAIN_INVENTORY_SUCCESS = 'LOAD_DOMAIN_INVENTORY_SUCCESS';
export const LOAD_DOMAIN_INVENTORY_FAILURE = 'LOAD_DOMAIN_INVENTORY_FAILURE';
export const UPDATE_INVENTORY_REQUEST = 'UPDATE_INVENTORY_REQUEST';
export const UPDATE_INVENTORY_SUCCESS = 'UPDATE_INVENTORY_SUCCESS';
export const UPDATE_INVENTORY_FAILURE = 'UPDATE_INVENTORY_FAILURE';
export const CREATE_INVENTORY_REQUEST = 'CREATE_INVENTORY_REQUEST';
export const CREATE_INVENTORY_SUCCESS = 'CREATE_INVENTORY_SUCCESS';
export const CREATE_INVENTORY_FAILURE = 'CREATE_INVENTORY_FAILURE';
export const CLEAR_FILTERED_INVENTORY = 'CLEAR_FILTERED_INVENTORY';
export const LOAD_DOMAIN_FILTERED_INVENTORY_REQUEST = 'LOAD_DOMAIN_FILTERED_INVENTORY_REQUEST';
export const LOAD_DOMAIN_FILTERED_INVENTORY_SUCCESS = 'LOAD_DOMAIN_FILTERED_INVENTORY_SUCCESS';
export const LOAD_DOMAIN_FILTERED_INVENTORY_FAILURE = 'LOAD_DOMAIN_FILTERED_INVENTORY_FAILURE';
export const CREATE_BULK_INVENTORY_REQUEST = 'CREATE_BULK_INVENTORY_REQUEST';
export const CREATE_BULK_INVENTORY_SUCCESS = 'CREATE_BULK_INVENTORY_SUCCESS';
export const CREATE_BULK_INVENTORY_FAILURE = 'CREATE_BULK_INVENTORY_FAILURE';
export const CLEAR_ALL_INVENTORY = 'CLEAR_ALL_INVENTORY';
export const CLEAR_INVENTORY_ERRORS = 'CLEAR_INVENTORY_ERRORS';
export const LOAD_ITEM_AUDIT_REQUEST = 'LOAD_ITEM_AUDIT_REQUEST';
export const LOAD_ITEM_AUDIT_SUCCESS = 'LOAD_ITEM_AUDIT_SUCCESS';
export const LOAD_ITEM_AUDIT_FAILURE = 'LOAD_ITEM_AUDIT_FAILURE';
export const CLEAR_SELECTED_ITEM_AUDIT = 'CLEAR_SELECTED_ITEM_AUDIT';

// SALES ORDERS

export const LOAD_OPEN_SALES_ORDERS_REQUEST = 'LOAD_OPEN_SALES_ORDERS_REQUEST';
export const LOAD_OPEN_SALES_ORDERS_SUCCESS = 'LOAD_OPEN_SALES_ORDERS_SUCCESS';
export const LOAD_OPEN_SALES_ORDERS_FAILURE = 'LOAD_OPEN_SALES_ORDERS_FAILURE';
export const CLEAR_OPEN_SALES_ORDERS = 'CLEAR_OPEN_SALES_ORDERS';
export const LOAD_SINGLE_SALES_ORDER_REQUEST = 'LOAD_SINGLE_SALES_ORDER_REQUEST';
export const LOAD_SINGLE_SALES_ORDER_SUCCESS = 'LOAD_SINGLE_SALES_ORDER_SUCCESS';
export const LOAD_SINGLE_SALES_ORDER_FAILURE = 'LOAD_SINGLE_SALES_ORDER_FAILURE';
export const CREATE_SALES_ORDER_REQUEST = 'CREATE_SALES_ORDER_REQUEST';
export const CREATE_SALES_ORDER_SUCCESS = 'CREATE_SALES_ORDER_SUCCESS';
export const CREATE_SALES_ORDER_FAILURE = 'CREATE_SALES_ORDER_FAILURE';
export const LOAD_INCOMPLETE_DOMAIN_SO_REQUEST = 'LOAD_INCOMPLETE_DOMAIN_SO_REQUEST';
export const LOAD_INCOMPLETE_DOMAIN_SO_SUCCESS = 'LOAD_INCOMPLETE_DOMAIN_SO_SUCCESS';
export const LOAD_INCOMPLETE_DOMAIN_SO_FAILURE = 'LOAD_INCOMPLETE_DOMAIN_SO_FAILURE';
export const UPDATE_SALES_ORDER_REQUEST = 'UPDATE_SALES_ORDER_REQUEST';
export const UPDATE_SALES_ORDER_SUCCESS = 'UPDATE_SALES_ORDER_SUCCESS';
export const UPDATE_SALES_ORDER_FAILURE = 'UPDATE_SALES_ORDER_FAILURE';
export const CLEAR_SALES_ORDER = 'CLEAR_SALES_ORDER';
export const CLEAR_ALL_SALES_ORDERS = 'CLEAR_ALL_SALES_ORDERS';
export const LOAD_DOMAIN_SO_REQUEST = 'LOAD_DOMAIN_SO_REQUEST';
export const LOAD_DOMAIN_SO_SUCCESS = 'LOAD_DOMAIN_SO_SUCCESS';
export const LOAD_DOMAIN_SO_FAILURE = 'LOAD_DOMAIN_SO_FAILURE';
export const UPDATE_DELETE_SO_REQUEST = 'UPDATE_DELETE_SO_REQUEST';
export const UPDATE_DELETE_SO_SUCCESS = 'UPDATE_DELETE_SO_SUCCESS';
export const UPDATE_DELETE_SO_FAILURE = 'UPDATE_DELETE_SO_FAILURE';
export const CLEAR_ALL_OPEN_SALES_ORDERS = 'CLEAR_ALL_OPEN_SALES_ORDERS';
export const UPDATE_DELETE_SO_ITEM_REQUEST = 'UPDATE_DELETE_SO_ITEM_REQUEST';
export const UPDATE_DELETE_SO_ITEM_SUCCESS = 'UPDATE_DELETE_SO_ITEM_SUCCESS';
export const UPDATE_DELETE_SO_ITEM_FAILURE = 'UPDATE_DELETE_SO_ITEM_FAILURE';
export const UPDATE_SO_ITEM_REQUEST = 'UPDATE_SO_ITEM_REQUEST';
export const UPDATE_SO_ITEM_SUCCESS = 'UPDATE_SO_ITEM_SUCCESS';
export const UPDATE_SO_ITEM_FAILURE = 'UPDATE_SO_ITEM_FAILURE';
export const UPDATE_SO_REQUEST = 'UPDATE_SO_REQUEST';
export const UPDATE_SO_SUCCESS = 'UPDATE_SO_SUCCESS';
export const UPDATE_SO_FAILURE = 'UPDATE_SO_FAILURE';
export const LOAD_CUSTOMER_SALES_ORDERS_REQUEST = 'LOAD_CUSTOMER_SALES_ORDERS_REQUEST';
export const LOAD_CUSTOMER_SALES_ORDERS_SUCCESS = 'LOAD_CUSTOMER_SALES_ORDERS_SUCCESS';
export const LOAD_CUSTOMER_SALES_ORDERS_FAILURE = 'LOAD_CUSTOMER_SALES_ORDERS_FAILURE';

// PURCHASE ORDERS

export const LOAD_VENDERS_REQUEST = 'LOAD_VENDERS_REQUEST';
export const LOAD_VENDERS_SUCCESS = 'LOAD_VENDERS_SUCCESS';
export const LOAD_VENDERS_FAILURE = 'LOAD_VENDERS_FAILURE';
export const CLEAR_ALL_VENDERS = 'CLEAR_ALL_VENDERS';
export const LOAD_PURCHASE_ORDER_BY_PO_REQUEST = 'LOAD_PURCHASE_ORDER_BY_PO_REQUEST';
export const LOAD_PURCHASE_ORDER_BY_PO_SUCCESS = 'LOAD_PURCHASE_ORDER_BY_PO_SUCCESS';
export const LOAD_PURCHASE_ORDER_BY_PO_FAILURE = 'LOAD_PURCHASE_ORDER_BY_PO_FAILURE';
export const UPDATE_PURCHASE_ORDER_BY_IDENTIFIER_REQUEST = 'UPDATE_PURCHASE_ORDER_BY_IDENTIFIER_REQUEST';
export const UPDATE_PURCHASE_ORDER_BY_IDENTIFIER_SUCCESS = 'UPDATE_PURCHASE_ORDER_BY_IDENTIFIER_SUCCESS';
export const UPDATE_PURCHASE_ORDER_BY_IDENTIFIER_FAILURE = 'UPDATE_PURCHASE_ORDER_BY_IDENTIFIER_FAILURE';
export const CLEAR_PURCHASE_ORDER = 'CLEAR_PURCHASE_ORDER';
export const CLEAR_ALL_PURCHASE_ORDERS = 'CLEAR_ALL_PURCHASE_ORDERS';
export const LOAD_OPEN_PURCHASE_ORDERS_REQUEST = 'LOAD_OPEN_PURCHASE_ORDERS_REQUEST';
export const LOAD_OPEN_PURCHASE_ORDERS_SUCCESS = 'LOAD_OPEN_PURCHASE_ORDERS_SUCCESS';
export const LOAD_OPEN_PURCHASE_ORDERS_FAILURE = 'LOAD_OPEN_PURCHASE_ORDERS_FAILURE';
export const CLEAR_OPEN_PURCHASE_ORDERS = 'CLEAR_OPEN_PURCHASE_ORDERS';
export const LOAD_DOMAIN_PO_REQUEST = 'LOAD_DOMAIN_PO_REQUEST';
export const LOAD_DOMAIN_PO_SUCCESS = 'LOAD_DOMAIN_PO_SUCCESS';
export const LOAD_DOMAIN_PO_FAILURE = 'LOAD_DOMAIN_PO_FAILURE';
export const LOAD_INCOMPLETE_DOMAIN_PO_REQUEST = 'LOAD_INCOMPLETE_DOMAIN_PO_REQUEST';
export const LOAD_INCOMPLETE_DOMAIN_PO_SUCCESS = 'LOAD_INCOMPLETE_DOMAIN_PO_SUCCESS';
export const LOAD_INCOMPLETE_DOMAIN_PO_FAILURE = 'LOAD_INCOMPLETE_DOMAIN_PO_FAILURE';
export const LOAD_TOTAL_DOMAIN_PO_REQUEST = 'LOAD_TOTAL_DOMAIN_PO_REQUEST';
export const LOAD_TOTAL_DOMAIN_PO_SUCCESS = 'LOAD_TOTAL_DOMAIN_PO_SUCCESS';
export const LOAD_TOTAL_DOMAIN_PO_FAILURE = 'LOAD_TOTAL_DOMAIN_PO_FAILURE';
export const UPDATE_DELETE_PO_REQUEST = 'UPDATE_DELETE_PO_REQUEST';
export const UPDATE_DELETE_PO_SUCCESS = 'UPDATE_DELETE_PO_SUCCESS';
export const UPDATE_DELETE_PO_FAILURE = 'UPDATE_DELETE_PO_FAILURE';
export const UPDATE_PO_REQUEST = 'UPDATE_PO_REQUEST';
export const UPDATE_PO_SUCCESS = 'UPDATE_PO_SUCCESS';
export const UPDATE_PO_FAILURE = 'UPDATE_PO_FAILURE';
export const CREATE_PROCESS_PO_REQUEST = 'CREATE_PROCESS_PO_REQUEST';
export const CREATE_PROCESS_PO_SUCCESS = 'CREATE_PROCESS_PO_SUCCESS';
export const CREATE_PROCESS_PO_FAILURE = 'CREATE_PROCESS_PO_FAILURE';
export const CREATE_VENDER_REQUEST = 'CREATE_VENDER_REQUEST';
export const CREATE_VENDER_SUCCESS = 'CREATE_VENDER_SUCCESS';
export const CREATE_VENDER_FAILURE = 'CREATE_VENDER_FAILURE';
export const UPDATE_VENDER_REQUEST = 'UPDATE_VENDER_REQUEST';
export const UPDATE_VENDER_SUCCESS = 'UPDATE_VENDER_SUCCESS';
export const UPDATE_VENDER_FAILURE = 'UPDATE_VENDER_FAILURE';
export const UPDATE_PO_ITEM_REQUEST = 'UPDATE_PO_ITEM_REQUEST';
export const UPDATE_PO_ITEM_SUCCESS = 'UPDATE_PO_ITEM_SUCCESS';
export const UPDATE_PO_ITEM_FAILURE = 'UPDATE_PO_ITEM_FAILURE';
export const UPDATE_DELETE_PO_ITEM_REQUEST = 'UPDATE_DELETE_PO_ITEM_REQUEST';
export const UPDATE_DELETE_PO_ITEM_SUCCESS = 'UPDATE_DELETE_PO_ITEM_SUCCESS';
export const UPDATE_DELETE_PO_ITEM_FAILURE = 'UPDATE_DELETE_PO_ITEM_FAILURE';

// NOT RE VERIFIED

export const CREATE_FORM_SUBMIT_REQUEST = 'CREATE_FORM_SUBMIT_REQUEST';
export const CREATE_FORM_SUBMIT_SUCCESS = 'CREATE_FORM_SUBMIT_SUCCESS';
export const CREATE_FORM_SUBMIT_FAILURE = 'CREATE_FORM_SUBMIT_FAILURE';

export const PERSIST_FORM_DATA = 'PERSIST_FORM_DATA';
export const CLEAR_FORM_DATA = 'CLEAR_FORM_DATA';

export const CREATE_SUBSCRIBE_REQUEST = 'CREATE_SUBSCRIBE_REQUEST';
export const CREATE_SUBSCRIBE_SUCCESS = 'CREATE_SUBSCRIBE_SUCCESS';
export const CREATE_SUBSCRIBE_FAILURE = 'CREATE_SUBSCRIBE_FAILURE';

export const LOAD_EVENT_CODES_REQUEST = 'LOAD_EVENT_CODES_REQUEST';
export const LOAD_EVENT_CODES_SUCCESS = 'LOAD_EVENT_CODES_SUCCESS';
export const LOAD_EVENT_CODES_FAILURE = 'LOAD_EVENT_CODES_FAILURE';
export const LOAD_EVENTS_REQUEST = 'LOAD_EVENTS_REQUEST';
export const LOAD_EVENTS_SUCCESS = 'LOAD_EVENTS_SUCCESS';
export const LOAD_EVENTS_FAILURE = 'LOAD_EVENTS_FAILURE';
export const CREATE_EVENT_REQUEST = 'CREATE_EVENT_REQUEST';
export const CREATE_EVENT_SUCCESS = 'CREATE_EVENT_SUCCESS';
export const CREATE_EVENT_FAILURE = 'CREATE_EVENT_FAILURE';
export const CREATE_DELETE_EVENT_REQUEST = 'CREATE_DELETE_EVENT_REQUEST';
export const CREATE_DELETE_EVENT_SUCCESS = 'CREATE_DELETE_EVENT_SUCCESS';
export const CREATE_DELETE_EVENT_FAILURE = 'CREATE_DELETE_EVENT_FAILURE';
export const CREATE_EVENT_CODE_REQUEST = 'CREATE_EVENT_CODE_REQUEST';
export const CREATE_EVENT_CODE_SUCCESS = 'CREATE_EVENT_CODE_SUCCESS';
export const CREATE_EVENT_CODE_FAILURE = 'CREATE_EVENT_CODE_FAILURE';
export const UPDATE_EVENT_REQUEST = 'UPDATE_EVENT_REQUEST';
export const UPDATE_EVENT_SUCCESS = 'UPDATE_EVENT_SUCCESS';
export const UPDATE_EVENT_FAILURE = 'UPDATE_EVENT_FAILURE';
export const CREATE_EVENT_EMAIL_REQUEST = 'CREATE_EVENT_EMAIL_REQUEST';
export const CREATE_EVENT_EMAIL_SUCCESS = 'CREATE_EVENT_EMAIL_SUCCESS';
export const CREATE_EVENT_EMAIL_FAILURE = 'CREATE_EVENT_EMAIL_FAILURE';

export const CREATE_AD_RECORD_REQUEST = 'CREATE_AD_RECORD_REQUEST';
export const CREATE_AD_RECORD_SUCCESS = 'CREATE_AD_RECORD_SUCCESS';
export const CREATE_AD_RECORD_FAILURE = 'CREATE_AD_RECORD_FAILURE';
export const UPDATE_AD_RECORD_REQUEST = 'UPDATE_AD_RECORD_REQUEST';
export const UPDATE_AD_RECORD_SUCCESS = 'UPDATE_AD_RECORD_SUCCESS';
export const UPDATE_AD_RECORD_FAILURE = 'UPDATE_AD_RECORD_FAILURE';
export const LOAD_AD_RECORDS_REQUEST = 'LOAD_AD_RECORDS_REQUEST';
export const LOAD_AD_RECORDS_SUCCESS = 'LOAD_AD_RECORDS_SUCCESS';
export const LOAD_AD_RECORDS_FAILURE = 'LOAD_AD_RECORDS_FAILURE';
export const CLEAR_AD_RECORD = 'CLEAR_AD_RECORD';

export const LOAD_GUESTS_REQUEST = 'LOAD_GUESTS_REQUEST';
export const LOAD_GUESTS_SUCCESS = 'LOAD_GUESTS_SUCCESS';
export const LOAD_GUESTS_FAILURE = 'LOAD_GUESTS_FAILURE';

export const UPDATE_USER_PRIMARY_NUMBER_REQUEST = 'UPDATE_USER_PRIMARY_NUMBER_REQUEST';
export const UPDATE_USER_PRIMARY_NUMBER_SUCCESS = 'UPDATE_USER_PRIMARY_NUMBER_SUCCESS';
export const UPDATE_USER_PRIMARY_NUMBER_FAILURE = 'UPDATE_USER_PRIMARY_NUMBER_FAILURE';
export const UPDATE_USER_SHIPPING_REQUEST = 'UPDATE_USER_SHIPPING_REQUEST';
export const UPDATE_USER_SHIPPING_SUCCESS = 'UPDATE_USER_SHIPPING_SUCCESS';
export const UPDATE_USER_SHIPPING_FAILURE = 'UPDATE_USER_SHIPPING_FAILURE';
export const UPDATE_USER_PRIMARY_SHIPPING_REQUEST = 'UPDATE_USER_PRIMARY_SHIPPING_REQUEST';
export const UPDATE_USER_PRIMARY_SHIPPING_SUCCESS = 'UPDATE_USER_PRIMARY_SHIPPING_SUCCESS';
export const UPDATE_USER_PRIMARY_SHIPPING_FAILURE = 'UPDATE_USER_PRIMARY_SHIPPING_FAILURE';
export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';
export const CREATE_DELETE_TEMP_PASSWORD_REQUEST = 'CREATE_DELETE_TEMP_PASSWORD_REQUEST';
export const CREATE_DELETE_TEMP_PASSWORD_SUCCESS = 'CREATE_DELETE_TEMP_PASSWORD_SUCCESS';
export const CREATE_DELETE_TEMP_PASSWORD_FAILURE = 'CREATE_DELETE_TEMP_PASSWORD_FAILURE';
export const CREATE_NEW_ADDRESS_REQUEST = 'CREATE_NEW_ADDRESS_REQUEST';
export const CREATE_NEW_ADDRESS_SUCCESS = 'CREATE_NEW_ADDRESS_SUCCESS';
export const CREATE_NEW_ADDRESS_FAILURE = 'CREATE_NEW_ADDRESS_FAILURE';
export const CREATE_NEW_PHONE_REQUEST = 'CREATE_NEW_PHONE_REQUEST';
export const CREATE_NEW_PHONE_SUCCESS = 'CREATE_NEW_PHONE_SUCCESS';
export const CREATE_NEW_PHONE_FAILURE = 'CREATE_NEW_PHONE_FAILURE';
export const UPDATE_DELETE_ADDRESS_REQUEST = 'UPDATE_DELETE_ADDRESS_REQUEST';
export const UPDATE_DELETE_ADDRESS_SUCCESS = 'UPDATE_DELETE_ADDRESS_SUCCESS';
export const UPDATE_DELETE_ADDRESS_FAILURE = 'UPDATE_DELETE_ADDRESS_FAILURE';
export const UPDATE_DELETE_PHONE_REQUEST = 'UPDATE_DELETE_PHONE_REQUEST';
export const UPDATE_DELETE_PHONE_SUCCESS = 'UPDATE_DELETE_PHONE_SUCCESS';
export const UPDATE_DELETE_PHONE_FAILURE = 'UPDATE_DELETE_PHONE_FAILURE';
export const UPDATE_USER_PREFERRED_EMAIL_REQUEST = 'UPDATE_USER_PREFERRED_EMAIL_REQUEST';
export const UPDATE_USER_PREFERRED_EMAIL_SUCCESS = 'UPDATE_USER_PREFERRED_EMAIL_SUCCESS';
export const UPDATE_USER_PREFERRED_EMAIL_FAILURE = 'UPDATE_USER_PREFERRED_EMAIL_FAILURE';
export const CREATE_NEW_EMAIL_ADDRESS_REQUEST = 'CREATE_NEW_EMAIL_ADDRESS_REQUEST';
export const CREATE_NEW_EMAIL_ADDRESS_SUCCESS = 'CREATE_NEW_EMAIL_ADDRESS_SUCCESS';
export const CREATE_NEW_EMAIL_ADDRESS_FAILURE = 'CREATE_NEW_EMAIL_ADDRESS_FAILURE';
export const UPDATE_DELETE_EMAIL_REQUEST = 'UPDATE_DELETE_EMAIL_REQUEST';
export const UPDATE_DELETE_EMAIL_SUCCESS = 'UPDATE_DELETE_EMAIL_SUCCESS';
export const UPDATE_DELETE_EMAIL_FAILURE = 'UPDATE_DELETE_EMAIL_FAILURE';

//shield renew
export const LOAD_SHIELD_RENEW_ELIGIBLE_EQUIPMENT_REQUEST = 'LOAD_SHIELD_RENEW_ELIGIBLE_EQUIPMENT_REQUEST';
export const LOAD_SHIELD_RENEW_ELIGIBLE_EQUIPMENT_SUCCESS = 'LOAD_SHIELD_RENEW_ELIGIBLE_EQUIPMENT_SUCCESS';
export const LOAD_SHIELD_RENEW_ELIGIBLE_EQUIPMENT_FAILURE = 'LOAD_SHIELD_RENEW_ELIGIBLE_EQUIPMENT_FAILURE';
export const CLEAR_ELIGIBLE_EQUIPMENT = 'CLEAR_ELIGIBLE_EQUIPMENT';
