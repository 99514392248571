import { useState, useEffect } from 'react';
import Link from 'next/link';
import Modal from 'react-modal';
import useWindowSize from '../../hooks/useWindowSize';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { successToast } from '../../utils/toast';

const Announcement = ({ header, title, body, actions }) => {
  const [showModal, setShowModal] = useState(false);

  const { width } = useWindowSize();

  const closeModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    const storageData = localStorage.getItem('announcementData');
    if (!storageData) {
      setTimeout(() => {
        setShowModal(true);
      }, 2000);
      localStorage.setItem('announcementData', JSON.stringify({ date: new Date() }));
      return;
    }

    const announcementData = JSON.parse(storageData);
    const oldDate = new Date(announcementData.date);
    oldDate.setDate(oldDate.getDate() + 1);

    if (new Date() > oldDate) {
      setTimeout(() => {
        setShowModal(true);
      }, 2000);
      localStorage.setItem('announcementData', JSON.stringify({ date: new Date() }));
    }

    return () => {
      localStorage.setItem('announcementData', JSON.stringify({ date: new Date() }));
    };
  }, []);

  return (
    <Modal
      isOpen={showModal}
      style={{
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          width: width >= 1280 ? '40%' : width >= 960 ? '70%' : '85%',
          padding: '0',
          border: 'none',
          maxWidth: '1000px',
          zIndex: 9999999,
          transition: 'opacity .5s',
          position: 'absolute',
          overflow: 'none',
        },
        overlay: {
          background: 'rgba(0,0,0,.7)',
          zIndex: 9999998,
        },
      }}
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
      shouldReturnFocusAfterClose={true}
      onRequestClose={closeModal}
    >
      <div className="flex items-center justify-end px-5 absolute w-full">
        <button className="border-r border-t border-l border-b rounded-full w-8 h-8 ml-auto mt-2" onClick={closeModal}>
          x
        </button>
      </div>
      <div className="overflow-none grid sm:grid-cols-2 p-10">
        <div className="px-5 py-5 sm:py-0">
          <Link href="/software/mission-planning/ugcs" id="pop-up-image" className="cursor-pointer">
            <img src="/images/software/mission-planning/ugcs/UgCS-banner-600x500.jpg" className="cursor-pointer" />
          </Link>
        </div>
        <div id="pop-up-content" className="border-t sm:border-t-0 sm:border-l px-5 py-5 sm:py-0">
          <p className="font-bold text-2xl text-center">UgCS Mission Planning Software</p>
          <p className="my-5 text-center">
            When purchasing UgCS, make sure to use Terrestrial Imaging's discount code <strong>TERRUGCS</strong> for
            <strong> $75.00 off!</strong>
          </p>
          <div className="flex items-center flex-col gap-y-5">
            <CopyToClipboard text="TERRUGCS" onCopy={successToast('Successfully Copied TERRUGCS Discount Code!')}>
              <button className="font-semibold border-2 border-dashed border-ti-space-grey p-2 w-full can-hover:hover:bg-ti-grey">
                TERRUGCS
              </button>
            </CopyToClipboard>
            <a
              href="https://shop.ugcs.com/discount/TERRUGCS"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Buy UgCS with a discount!"
              className="ti-button w-full"
            >
              Buy Now
            </a>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default Announcement;
