import {
  LOAD_OPEN_SALES_ORDERS_REQUEST,
  LOAD_OPEN_SALES_ORDERS_SUCCESS,
  LOAD_OPEN_SALES_ORDERS_FAILURE,
  LOAD_SINGLE_SALES_ORDER_REQUEST,
  LOAD_SINGLE_SALES_ORDER_SUCCESS,
  LOAD_SINGLE_SALES_ORDER_FAILURE,
  CLEAR_OPEN_SALES_ORDERS,
  LOAD_INCOMPLETE_DOMAIN_SO_REQUEST,
  LOAD_INCOMPLETE_DOMAIN_SO_SUCCESS,
  LOAD_INCOMPLETE_DOMAIN_SO_FAILURE,
  CREATE_SALES_ORDER_REQUEST,
  CREATE_SALES_ORDER_SUCCESS,
  CREATE_SALES_ORDER_FAILURE,
  CLEAR_SALES_ORDER,
  LOAD_DOMAIN_SO_REQUEST,
  LOAD_DOMAIN_SO_SUCCESS,
  LOAD_DOMAIN_SO_FAILURE,
  CLEAR_ALL_SALES_ORDERS,
  UPDATE_DELETE_SO_REQUEST,
  UPDATE_DELETE_SO_SUCCESS,
  UPDATE_DELETE_SO_FAILURE,
  CLEAR_ALL_OPEN_SALES_ORDERS,
  UPDATE_SALES_ORDER_REQUEST,
  UPDATE_SALES_ORDER_SUCCESS,
  UPDATE_SALES_ORDER_FAILURE,
  UPDATE_DELETE_SO_ITEM_REQUEST,
  UPDATE_DELETE_SO_ITEM_SUCCESS,
  UPDATE_DELETE_SO_ITEM_FAILURE,
  UPDATE_SO_ITEM_REQUEST,
  UPDATE_SO_ITEM_SUCCESS,
  UPDATE_SO_ITEM_FAILURE,
  UPDATE_SO_REQUEST,
  UPDATE_SO_SUCCESS,
  UPDATE_SO_FAILURE,
  LOAD_CUSTOMER_SALES_ORDERS_REQUEST,
  LOAD_CUSTOMER_SALES_ORDERS_SUCCESS,
  LOAD_CUSTOMER_SALES_ORDERS_FAILURE,
} from '../actions/types';
import uniqBy from 'lodash/uniqBy';

const initialState = {
  salesOrder: null,
  allOpenOrders: { items: [], count: 0 },
  loading: false,
  error: {},
  incompleteSOs: 0,
  salesOrders: {
    items: [],
    count: 0,
    totalSales: 0,
  },
};

const paymentReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case CLEAR_ALL_SALES_ORDERS: {
      return {
        ...state,
        salesOrders: {
          items: [],
          count: 0,
        },
      };
    }
    case CLEAR_ALL_OPEN_SALES_ORDERS: {
      return {
        ...state,
        allOpenOrders: {
          items: [],
          count: 0,
        },
      };
    }
    case CLEAR_SALES_ORDER: {
      return { ...state, salesOrder: null };
    }
    case CLEAR_OPEN_SALES_ORDERS: {
      return {
        ...state,
        allOpenOrders: initialState.allOpenOrders,
      };
    }
    case LOAD_CUSTOMER_SALES_ORDERS_REQUEST:
    case UPDATE_DELETE_SO_ITEM_REQUEST:
    case UPDATE_SO_REQUEST:
    case UPDATE_SO_ITEM_REQUEST:
    case UPDATE_SALES_ORDER_REQUEST:
    case LOAD_DOMAIN_SO_REQUEST:
    case CREATE_SALES_ORDER_REQUEST:
    case LOAD_INCOMPLETE_DOMAIN_SO_REQUEST:
    case LOAD_SINGLE_SALES_ORDER_REQUEST:
    case LOAD_OPEN_SALES_ORDERS_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case UPDATE_DELETE_SO_SUCCESS: {
      return {
        ...state,
        salesOrders: {
          items: state.salesOrders.items.filter(({ id }) => id !== payload.id),
          count: state.salesOrders.count - 1,
        },
        loading: false,
      };
    }
    case LOAD_CUSTOMER_SALES_ORDERS_SUCCESS:
    case LOAD_DOMAIN_SO_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: {},
        salesOrders: {
          items: [...state.salesOrders.items, ...payload.items],
          count: payload.count,
          totalSales: payload.totalSales,
        },
      };
    }
    case LOAD_OPEN_SALES_ORDERS_SUCCESS: {
      return {
        ...state,
        allOpenOrders: {
          items: [...state.allOpenOrders.items, ...payload.items],
          count: payload.count,
          totalSales: state.salesOrders.totalSales,
        },
        errors: {},
        loading: false,
      };
    }
    case UPDATE_SO_SUCCESS:
    case UPDATE_DELETE_SO_ITEM_SUCCESS:
    case UPDATE_SO_ITEM_SUCCESS:
    case LOAD_SINGLE_SALES_ORDER_SUCCESS: {
      const matchingSalesOrderIndex = state?.salesOrders?.items?.findIndex(({ id }) => id === payload.id);
      let newSalesOrders = [...state.salesOrders.items];

      if (matchingSalesOrderIndex > -1) {
        newSalesOrders.splice(matchingSalesOrderIndex, 1, {
          ...payload,
          order: state.salesOrders.items[matchingSalesOrderIndex].order,
        });
      } else {
        newSalesOrders = [...newSalesOrders, payload];
      }

      return {
        ...state,
        salesOrder: payload,
        salesOrders: {
          items: newSalesOrders,
          count: matchingSalesOrderIndex > -1 ? state.salesOrders.count : state.salesOrders.count + 1,
        },
        errors: {},
        loading: false,
      };
    }
    case UPDATE_SALES_ORDER_SUCCESS: {
      return {
        ...state,
        salesOrder: { ...payload, order: state.salesOrder.order },
        salesOrders: {
          items: uniqBy([...state.salesOrders.items, payload], 'id')?.filter(({ status }) => status !== 'closed'),
          count: state.salesOrders.count,
        },
        errors: {},
        loading: false,
      };
    }
    case CREATE_SALES_ORDER_SUCCESS: {
      return {
        ...state,
        allOpenOrders: {
          items: [...state.allOpenOrders.items, payload],
          count: state.allOpenOrders.count + 1,
          totalSales: state.salesOrders.totalSales,
        },
        errors: {},
        loading: false,
      };
    }
    case LOAD_CUSTOMER_SALES_ORDERS_FAILURE:
    case UPDATE_SO_FAILURE:
    case UPDATE_SO_ITEM_FAILURE:
    case UPDATE_DELETE_SO_ITEM_FAILURE:
    case UPDATE_SALES_ORDER_FAILURE:
    case UPDATE_DELETE_SO_FAILURE:
    case LOAD_DOMAIN_SO_FAILURE:
    case CREATE_SALES_ORDER_FAILURE:
    case LOAD_INCOMPLETE_DOMAIN_SO_FAILURE:
    case LOAD_SINGLE_SALES_ORDER_FAILURE:
    case LOAD_OPEN_SALES_ORDERS_FAILURE: {
      return {
        ...state,
        errors: payload,
        loading: false,
      };
    }
    case LOAD_INCOMPLETE_DOMAIN_SO_SUCCESS: {
      return {
        ...state,
        errors: {},
        incompleteSOs: payload,
        loading: false,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default paymentReducer;
