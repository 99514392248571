import { useEffect, useState } from 'react';
import { BsChevronRight, BsChevronLeft } from 'react-icons/bs';
import cn from 'classnames';
import Slide from 'react-reveal/Slide';

const HorizontalAccordion = ({ summary, children, handleClick, childContainerClasses, forceClose }) => {
  const [showChildren, setShowChildren] = useState(false);

  const handleShowChildren = () => {
    if (children && !showChildren) {
      setShowChildren(true);
    }
  };

  const goBack = () => {
    setShowChildren(false);
  };

  useEffect(() => {
    if (forceClose) {
      setShowChildren(false);
    }
  }, [forceClose]);

  return (
    <>
      <button
        className="w-full text-left flex flex-wrap p-2 text-ti-space-grey items-center"
        onClick={children ? handleShowChildren : handleClick}
      >
        {summary} {children && <BsChevronRight className="ml-auto" />}
        <hr className="w-full my-2" />
      </button>
      {children && (
        <Slide right when={showChildren} timeout={350}>
          <div
            className={cn('absolute top-0 w-full h-screen bg-white z-20', {
              hidden: !showChildren,
              [childContainerClasses]: childContainerClasses,
            })}
          >
            <button className="w-full text-left flex flex-wrap p-2 text-ti-space-grey items-center" onClick={goBack}>
              <BsChevronLeft className="mr-2" /> Back
              <hr className="w-full my-2" />
            </button>
            {children}
          </div>
        </Slide>
      )}
    </>
  );
};

export default HorizontalAccordion;
