import { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { BiCameraHome, BiBandAid } from 'react-icons/bi';
import { MdMyLocation } from 'react-icons/md';
import { AiOutlineFieldTime } from 'react-icons/ai';
import { GiDeliveryDrone } from 'react-icons/gi';
import { RiComputerLine } from 'react-icons/ri';
import useImageCarousel from '../../hooks/useImageCarousel';
import useBasicCarousel from '../../hooks/useBasicCarousel';
import DynamicElement from '../common/dynamicElement';

const allGraphics = {
  BiCameraHome,
  MdMyLocation,
  BiBandAid,
  AiOutlineFieldTime,
  GiDeliveryDrone,
  RiComputerLine,
};

const FeaturedList = ({ list, id, titleElement }) => {
  const { activeItem, Carousel } = useImageCarousel({
    items: list,
    imagePageing: false,
    sliderClass: 'w-full lg:w-7/12 order-1 lg:order-2',
  });

  const factList = useMemo(
    () =>
      list[activeItem].info?.map(({ icon, title, description }) => {
        const Icon = allGraphics[icon];
        return (
          <div
            className="bg-ti-grey my-2 py-6 px-12 md:px-6 text-center md:mx-5 lg:text-left min-h-220"
            key={description}
          >
            <div className="fadein">
              <Icon className="mb-2 text-2xl m-auto lg:mr-auto lg:ml-0" />
              <p className="font-semibold my-5">{title}</p>
              <p>{description}</p>
            </div>
          </div>
        );
      }),

    [activeItem, list],
  );

  const { Carousel: BasicCarousel, setActiveItem: setActiveFact } = useBasicCarousel({
    items: factList,
    slidesToShow: 1,
    sliderClass: 'w-full text-center',
  });

  useEffect(() => {
    setTimeout(() => setActiveFact(0), 100);
  }, [activeItem]);

  return (
    <section id={id}>
      <div id="container-wrapper" className="content-wrapper pb-0">
        <p className="text-center font-bold">FEATURED</p>
        <DynamicElement
          element={titleElement}
          content={list[activeItem].title}
          className="text-center font-bold my-5 text-4xl fadein leading-tight"
          key={list[activeItem].title}
        />
        <p className="text-center w-full lg:w-8/12 mx-auto text-xl fadein min-h-120" key={list[activeItem].description}>
          {list[activeItem].description}
        </p>
        <div className="flex gap-x-12 flex-col lg:flex-row items-center mt-10 mb-20">
          <div
            id="featured-item-details-container"
            className="order-2 lg:order-1 w-full lg:w-5/12 sm:flex flex-col md:flex-row lg:flex-col mt-20 lg:mt-0 hidden"
          >
            {factList}
          </div>
          <div className="order-2 lg:order-1 w-full mt-20 sm:hidden">{BasicCarousel}</div>
          {Carousel}
        </div>
      </div>
    </section>
  );
};

FeaturedList.defaultProps = {
  titleElement: 'p',
};

FeaturedList.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.is,
      info: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    }),
  ).isRequired,
  id: PropTypes.string.isRequired,
  titleElement: PropTypes.string,
};

export default FeaturedList;
