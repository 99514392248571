import { useMemo } from 'react';
import { ImYoutube } from 'react-icons/im';
import { IoIosArrowForward } from 'react-icons/io';
import PropTypes from 'prop-types';
import Image from 'next/image';
import DynamicElement from '../common/dynamicElement';
import Fade from 'react-reveal/Fade';

const TextLeftTweetRight = ({ message, title, background, id, titleElement }) => {
  const TextContainer = useMemo(
    () => (
      <div className="text-center md:text-left mb-10 md:mb-0">
        <p className="font-semibold">DroMight™</p>
        <DynamicElement
          content={title}
          element={titleElement}
          className="text-3xl md:text-4xl font-bold mt-3 mb-10 text-ti-space-grey"
        />
        <p className="leading-loose text-ti-space-grey w-full md:w-5/6 mb-5">{message}</p>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://store-terrestrialimaging-com.3dcartstores.com/DroMight"
          className="text-ti-link underline cursor-pointer flex items-center justify-center md:justify-start"
        >
          Shop All DroMight™ Products
          <IoIosArrowForward />
        </a>
      </div>
    ),
    [],
  );

  const goToTweet = () => {
    window.open('https://twitter.com/JackBox/status/1402310067261636611?s=20', '_blank', 'noopener noreferrer');
  };

  return (
    <Fade bottom>
      <section id={id} className={background}>
        <div className="content-wrapper flex flex-col md:flex-row">
          <div className="md:min-h-576 w-full md:w-6/12 md:text-left px-5 flex flex-col justify-around order-2 md:order-1">
            <div className="hidden md:block">{TextContainer}</div>
            <div>
              <p className="font-semibold mb-5">Our videos featuring the Talon Drop System:</p>
              <a
                className="font-semibold flex items-center bg-white rounded-xl shadow-lg p-5 w-full md:w-5/6 my-2 text-black"
                href="https://www.youtube.com/watch?v=W1_D1Ea3glU"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span>
                  <ImYoutube className="text-4xl text-youTube mr-5" />
                </span>
                Installing the Dromight™ Talon Drop System
              </a>
              <a
                className="font-semibold flex items-center bg-white rounded-xl shadow-lg p-5 w-full md:w-5/6 my-2 text-black"
                href="https://www.youtube.com/watch?v=TLVFtpJMwvE&t=15s"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span>
                  <ImYoutube className="text-4xl text-youTube mr-5" />
                </span>
                Dromight™ Talon Drop System Demo
              </a>
            </div>
          </div>
          <div className="w-full md:w-6/12 px-5 order-1 md:order-2 mb-10 md:mb-0 flex items-center flex-col md:flex-row">
            <div className="block md:hidden">{TextContainer}</div>
            <Image
              src="/images/misc/talon-tweet.png"
              alt="Dromight Talon Used By Jack In The Box To Film A Commercial"
              className="bg-white rounded-xl shadow-lg md:shadow-xl m-auto cursor-pointer"
              width={600}
              height={600}
              onClick={goToTweet}
              lazyBoundary="500px"
            />
          </div>
        </div>
      </section>
    </Fade>
  );
};

TextLeftTweetRight.defaultProps = {
  background: 'ti-grey',
  titleElement: 'p',
};

TextLeftTweetRight.propTypes = {
  message: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  background: PropTypes.string,
  id: PropTypes.string.isRequired,
  titleElement: PropTypes.string,
};

export default TextLeftTweetRight;
