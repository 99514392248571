import {
  CREATE_EVENT_FAILURE,
  CREATE_EVENT_REQUEST,
  CREATE_EVENT_SUCCESS,
  LOAD_EVENTS_FAILURE,
  LOAD_EVENTS_REQUEST,
  LOAD_EVENTS_SUCCESS,
  LOAD_EVENT_CODES_FAILURE,
  LOAD_EVENT_CODES_REQUEST,
  LOAD_EVENT_CODES_SUCCESS,
  UPDATE_EVENT_FAILURE,
  UPDATE_EVENT_REQUEST,
  UPDATE_EVENT_SUCCESS,
  CREATE_DELETE_EVENT_REQUEST,
  CREATE_DELETE_EVENT_SUCCESS,
  CREATE_DELETE_EVENT_FAILURE,
  CREATE_EVENT_CODE_REQUEST,
  CREATE_EVENT_CODE_SUCCESS,
  CREATE_EVENT_CODE_FAILURE,
} from '../actions/types';

const initialState = {
  loading: false,
  eventCodes: [],
  events: [],
  errors: {},
};

const eventsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'persist/REHYDRATE': {
      return {
        ...state,
        ...(payload ? payload.events : {}),
      };
    }
    case CREATE_EVENT_CODE_REQUEST:
    case CREATE_DELETE_EVENT_REQUEST:
    case CREATE_EVENT_REQUEST:
    case UPDATE_EVENT_REQUEST:
    case LOAD_EVENT_CODES_REQUEST:
    case LOAD_EVENTS_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case LOAD_EVENTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        errors: {},
        events: payload,
      };
    }
    case CREATE_EVENT_CODE_FAILURE:
    case CREATE_DELETE_EVENT_FAILURE:
    case CREATE_EVENT_FAILURE:
    case UPDATE_EVENT_FAILURE:
    case LOAD_EVENT_CODES_FAILURE:
    case LOAD_EVENTS_FAILURE: {
      return {
        ...state,
        loading: false,
        errors: payload,
      };
    }
    case LOAD_EVENT_CODES_SUCCESS: {
      return {
        ...state,
        loading: false,
        errors: {},
        eventCodes: payload,
      };
    }
    case UPDATE_EVENT_SUCCESS: {
      return {
        ...state,
        loading: false,
        errors: {},
        events: [payload, ...state.events.filter(({ id }) => id !== payload.id)],
      };
    }
    case CREATE_EVENT_SUCCESS: {
      return {
        ...state,
        loading: false,
        errors: {},
        events: [payload, ...state.events],
      };
    }
    case CREATE_DELETE_EVENT_SUCCESS: {
      return {
        ...state,
        loading: false,
        errors: {},
        events: state.events.filter(({ id }) => id !== payload.id),
      };
    }
    case CREATE_EVENT_CODE_SUCCESS: {
      return {
        ...state,
        loading: false,
        errors: {},
        eventCodes: [payload, ...state.eventCodes],
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default eventsReducer;
