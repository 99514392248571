import uniqueId from 'lodash/uniqueId';
import { useCallback } from 'react';
import { AiFillStar } from 'react-icons/ai';
import Action from '../common/action/Action';

const Review = ({ title, review, stars, reviewdBy, id }) => {
  const renderedStars = useCallback(
    () => Array.from({ length: stars }, () => <AiFillStar key={uniqueId()} className="text-ti-green" />),
    [],
  );
  return (
    <section id={id} className="bg-ti-space-grey">
      <Action
        className="fixed -right-6 top-1/2 bg-ti-green text-black p-2 rotate-90 flex items-center z-40 font-normal rounded-md"
        showArrow={false}
        type="scrollLink"
        text="TI Review"
        target="Review-1"
        external={false}
      />
      <div className="content-wrapper">
        <h3 className="text-4xl text-ti-space-grey mb-5 font-semibold text-ti-green">Terrestrial Imaging Review</h3>
        <p className="text-3xl text-white mb-5">{title}</p>
        <p className="text-white">{review}</p>
        <div className="flex items-center jsutify-center my-2">{renderedStars()}</div>
        <p className="text-white text-sm">Reviewd By: {reviewdBy}</p>
      </div>
    </section>
  );
};

export default Review;
