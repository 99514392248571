import { useEffect } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

const Overlay = ({ blurry, show, handleShow, zIndexNum, overlayClasses }) => {
  useEffect(() => {
    const target = document.getElementById('page-container');
    const announcementBanner = document.getElementById('announcement-banner');
    const body = document.body;
    if (show && target && body.style.overflow !== 'hidden') {
      target.style.top = `-${window.scrollY + announcementBanner?.style?.height ?? 0}px`;
      body.style.overflowY = 'scroll';
      body.style.position = 'fixed';
      body.style.width = '100%';
    } else if (!show && target && body.style.overflow !== 'hidden') {
      const scrollY = target.style.top;
      body.style.overflowY = 'scroll';
      body.style.position = '';
      body.style.width = '';
      target.style.top = '';

      window.scrollTo(0, parseInt(scrollY || '0') * -1);
    }
  }, [show]);

  useEffect(() => {
    return () => {
      const target = document.getElementById('page-container');
      const body = document.body;
      body.style.overflowY = 'scroll';
      body.style.position = '';
      body.style.width = '';
      if (target) {
        const scrollY = target?.style?.top;

        target.style.top = '';
        window.scrollTo(0, parseInt(scrollY || '0') * -1);
      } else {
        window.scrollTo(0, 0);
      }
    };
  }, []);

  return (
    show && (
      <div
        onScroll={(e) => {
          e.stopPropagation();
          return;
        }}
        id="overlay"
        className={cn('fixed overflow-y-scroll top-0 left-0 bottom-0 right-0', {
          overlay: show,
          blurry,
          [overlayClasses]: overlayClasses,
        })}
        onClick={handleShow}
        style={{ zIndex: zIndexNum ? zIndexNum : 40 }}
      />
    )
  );
};

Overlay.defaultProps = {
  blurry: false,
};

Overlay.propTypes = {
  blurry: PropTypes.bool,
  show: PropTypes.bool.isRequired,
  handleShow: PropTypes.func.isRequired,
};

export default Overlay;
