import dynamic from 'next/dynamic';
import { isMobile } from 'react-device-detect';

const ReactFreezeframe = dynamic(() => import('react-freezeframe'), { ssr: false });

const Gif = ({ src, alt, warning, ...props }) => (
  <div key={src}>
    {warning && <p className="text-white italic text-sm">{warning}</p>}
    <ReactFreezeframe
      src={src}
      alt={alt}
      {...props}
      options={{
        trigger: isMobile ? 'click' : 'hover',
        overlay: true,
      }}
    />
  </div>
);

export default Gif;
