import { useState } from 'react';
import cn from 'classnames';
import PropTypes, { oneOfType } from 'prop-types';
import { FiEyeOff, FiEye } from 'react-icons/fi';
import { BsQuestionCircle } from 'react-icons/bs';
import Fade from 'react-reveal/Fade';

const FormInput = ({
  autoComplete,
  label,
  name,
  placeholder,
  id,
  value,
  onChange,
  error,
  containerClasses,
  warningContainerClass,
  disabled,
  required,
  type,
  Icon,
  maskField,
  labelClass,
  action,
  actionIcon,
  actionTip,
  disabledAction,
  helperText,
  actionContainerClass,
  inputClasses,
  ...rest
}) => {
  const [masked, setMasked] = useState(maskField);

  const handleToggleMask = () => {
    setMasked(!masked);
  };

  return (
    <>
      <div className={`relative ${containerClasses}`}>
        {label && (
          <label
            className={cn('block uppercase tracking-wide text-xs font-bold mb-2 text-left flex items-center', {
              'text-ti-black-mid': !labelClass,
              [labelClass]: labelClass,
            })}
            htmlFor={id}
          >
            {label} {required && <span className="text-ti-green">*</span>}
            {helperText && (
              <div
                data-tip={helperText}
                className="ml-2 cursor-pointer p-2 transition duration-300 rounded-full can-hover:hover:text-white can-hover:hover:bg-ti-dim-grey can-hover:hover:shadow-lg"
                onClick={action}
              >
                <BsQuestionCircle />
              </div>
            )}
          </label>
        )}
        {Icon && <div className="absolute mt-4 ml-4">{Icon}</div>}
        {maskField && (
          <div
            data-tip={masked ? 'Show Credit Card Number' : 'Mask Credit Card Number'}
            className="absolute mt-2 mr-8 right-0 cursor-pointer p-2 transition duration-300 rounded-full can-hover:hover:bg-ti-green can-hover:hover:shadow-lg"
            role="button"
            aria-label={masked ? 'Show Credit Card Number' : 'Mask Credit Card Number'}
            onClick={handleToggleMask}
          >
            {!masked ? <FiEyeOff /> : <FiEye />}
          </div>
        )}
        {action && (
          <div data-tip={actionTip || ''}>
            <button
              className={`ti-button bg-transparent can-hover:hover:bg-ti-green absolute mt-2 right-2 cursor-pointer p-2 transition duration-300 rounded-full ${actionContainerClass}`}
              onClick={action}
              disabled={disabledAction}
            >
              {actionIcon}
            </button>
          </div>
        )}
        <input
          disabled={disabled}
          className={cn(
            `focus:ring-0 border-input-grey outline-none text-sm pl-4 text-black appearance-none block w-full border-2 rounded py-3 leading-tight focus:outline-none focus:border-ti-green overflow-ellipsis`,
            {
              'border-red-500': error,
              'px-4': !Icon,
              'pr-4 pl-10': Icon,
              'pr-12': action,
              [inputClasses]: inputClasses,
            },
          )}
          id={id}
          autoComplete={autoComplete}
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          type={masked ? 'password' : type}
          required={required}
          {...rest}
        />
        {error && (
          <Fade bottom timeout={350}>
            <p
              className={cn('text-red-500 text-xs italic my-1', {
                [warningContainerClass]: warningContainerClass,
              })}
            >
              {error}
            </p>
          </Fade>
        )}
      </div>
    </>
  );
};

FormInput.defaultProps = {
  autoComplete: 'off',
  value: null,
  error: null,
  containerClasses: '',
  warningContainerClass: null,
  disabled: false,
  required: false,
  Icon: null,
  type: 'text',
  maskField: false,
  name: '',
  label: null,
  disabledAction: false,
};

FormInput.propTypes = {
  autoComplete: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: oneOfType([PropTypes.string, PropTypes.number]),
  id: PropTypes.string.isRequired,
  value: oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
  containerClasses: PropTypes.string,
  disabled: PropTypes.bool,
  maskField: PropTypes.bool,
  required: PropTypes.bool,
  disabledAction: PropTypes.bool,
  Icon: PropTypes.element,
  warningContainerClass: PropTypes.string,
  text: PropTypes.string,
};

export default FormInput;
