import {
  LOAD_ROLES_REQUEST,
  LOAD_ROLES_SUCCESS,
  LOAD_ROLES_FAILURE,
  CREATE_USER_REQUEST,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAILURE,
  LOAD_USERS_REQUEST,
  LOAD_USERS_SUCCESS,
  LOAD_USERS_FAILURE,
  LOAD_USER_BY_ID_REQUEST,
  LOAD_USER_BY_ID_SUCCESS,
  LOAD_USER_BY_ID_FAILURE,
  CLEAR_USERS,
  LOAD_SEARCH_TERM_USERS_REQUEST,
  LOAD_SEARCH_TERM_USERS_SUCCESS,
  LOAD_SEARCH_TERM_USERS_FAILURE,
  LOAD_EMPLOYEE_SEARCH_TERM_USERS_REQUEST,
  LOAD_EMPLOYEE_SEARCH_TERM_USERS_SUCCESS,
  LOAD_EMPLOYEE_SEARCH_TERM_USERS_FAILURE,
  CLEAR_SEARCH_TERM_USERS,
  // UPDATE_USER_REQUEST,
  // UPDATE_USER_SUCCESS,
  // UPDATE_USER_FAILURE,
  // UPDATE_USER_PRIMARY_SHIPPING_REQUEST,
  // UPDATE_USER_PRIMARY_SHIPPING_SUCCESS,
  // UPDATE_USER_PRIMARY_SHIPPING_FAILURE,
  // UPDATE_USER_SHIPPING_REQUEST,
  // UPDATE_USER_SHIPPING_SUCCESS,
  // UPDATE_USER_SHIPPING_FAILURE,
  // UPDATE_USER_PRIMARY_NUMBER_REQUEST,
  // UPDATE_USER_PRIMARY_NUMBER_SUCCESS,
  // UPDATE_USER_PRIMARY_NUMBER_FAILURE,
  // CREATE_NEW_ADDRESS_REQUEST,
  // CREATE_NEW_ADDRESS_SUCCESS,
  // CREATE_NEW_ADDRESS_FAILURE,
  // CREATE_NEW_PHONE_REQUEST,
  // CREATE_NEW_PHONE_SUCCESS,
  // CREATE_NEW_PHONE_FAILURE,
  // UPDATE_DELETE_ADDRESS_REQUEST,
  // UPDATE_DELETE_ADDRESS_SUCCESS,
  // UPDATE_DELETE_ADDRESS_FAILURE,
  // UPDATE_DELETE_PHONE_REQUEST,
  // UPDATE_DELETE_PHONE_SUCCESS,
  // UPDATE_DELETE_PHONE_FAILURE,
  // UPDATE_USER_PREFERRED_EMAIL_REQUEST,
  // UPDATE_USER_PREFERRED_EMAIL_SUCCESS,
  // UPDATE_USER_PREFERRED_EMAIL_FAILURE,
  // CREATE_NEW_EMAIL_ADDRESS_REQUEST,
  // CREATE_NEW_EMAIL_ADDRESS_SUCCESS,
  // CREATE_NEW_EMAIL_ADDRESS_FAILURE,
  // UPDATE_DELETE_EMAIL_REQUEST,
  // UPDATE_DELETE_EMAIL_SUCCESS,
  // UPDATE_DELETE_EMAIL_FAILURE,
  LOAD_USER_EQUIPMENT_REQUEST,
  LOAD_USER_EQUIPMENT_SUCCESS,
  LOAD_USER_EQUIPMENT_FAILURE,
} from '../actions/types';

const initialState = {
  error: {},
  roles: [],
  loading: false,
  users: { users: [], count: 0 },
  employeeSearchTermUsers: { users: [], count: 0 },
  searchTermUsers: { users: [], count: 0 },
  selectedUser: null,
};

const usersReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case CLEAR_SEARCH_TERM_USERS: {
      return {
        ...initialState,
        users: state.users,
        roles: state.roles,
        loading: state.loading,
      };
    }
    case CLEAR_USERS: {
      return {
        ...initialState,
        roles: state.roles,
        loading: state.loading,
      };
    }
    case LOAD_USER_EQUIPMENT_REQUEST:
    case LOAD_EMPLOYEE_SEARCH_TERM_USERS_REQUEST:
    case LOAD_SEARCH_TERM_USERS_REQUEST:
    case LOAD_USER_BY_ID_REQUEST:
    case LOAD_USERS_REQUEST:
    case CREATE_USER_REQUEST:
    case LOAD_ROLES_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case CREATE_USER_SUCCESS: {
      return {
        ...state,
        users: initialState.users,
        loading: false,
      };
    }
    case LOAD_ROLES_SUCCESS: {
      return {
        ...state,
        roles: payload,
        loading: false,
        error: {},
      };
    }
    case LOAD_SEARCH_TERM_USERS_SUCCESS: {
      return {
        ...state,
        searchTermUsers: { users: [...state.searchTermUsers.users, ...payload.users], count: payload.count },
        loading: false,
        error: {},
      };
    }
    case LOAD_EMPLOYEE_SEARCH_TERM_USERS_SUCCESS: {
      return {
        ...state,
        employeeSearchTermUsers: {
          users: [...state.employeeSearchTermUsers.users, ...payload.users],
          count: payload.count,
        },
        loading: false,
        error: {},
      };
    }
    case LOAD_USERS_SUCCESS: {
      return {
        ...state,
        users: { users: [...state.users.users, ...payload.users], count: payload.count },
        loading: false,
        error: {},
      };
    }
    case LOAD_USER_EQUIPMENT_SUCCESS: {
      return {
        ...state,
        selectedUser: {
          ...(state?.selectedUser ? state.selectedUser : {}),
          personalInventory: {
            items: [
              ...(state?.selectedUser?.personalInventory?.items ? state?.selectedUser?.personalInventory?.items : []),
              ...payload.items,
            ].sort((a, b) => a.order - b.order),
            count: payload.count,
          },
        },
        loading: false,
        error: {},
      };
    }
    case LOAD_USER_BY_ID_SUCCESS: {
      return {
        ...state,
        selectedUser: { ...(state?.selectedUser ? state.selectedUser : {}), ...payload },
        loading: false,
        error: {},
      };
    }
    case LOAD_USER_EQUIPMENT_FAILURE:
    case LOAD_EMPLOYEE_SEARCH_TERM_USERS_FAILURE:
    case LOAD_SEARCH_TERM_USERS_FAILURE:
    case LOAD_USER_BY_ID_FAILURE:
    case LOAD_USERS_FAILURE:
    case CREATE_USER_FAILURE:
    case LOAD_ROLES_FAILURE: {
      return {
        ...state,
        loading: false,
        error: payload,
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
};

export default usersReducer;
