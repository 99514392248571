import {
  CREATE_FORM_SUBMIT_REQUEST,
  CREATE_FORM_SUBMIT_SUCCESS,
  CREATE_FORM_SUBMIT_FAILURE,
  PERSIST_FORM_DATA,
} from '../actions/types';

const initialState = {
  submitting: false,
  submitted: false,
};

const contactFormReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'persist/REHYDRATE': {
      return {
        ...state,
        ...(payload ? payload.contactForm : {}),
      };
    }
    case PERSIST_FORM_DATA: {
      return {
        ...state,
        submitting: false,
        formData: payload,
      };
    }
    case CREATE_FORM_SUBMIT_REQUEST: {
      return {
        ...state,
        submitting: true,
        formData: payload,
      };
    }
    case CREATE_FORM_SUBMIT_SUCCESS: {
      return {
        ...state,
        submitting: false,
        submitted: true,
        formData: null,
      };
    }
    case CREATE_FORM_SUBMIT_FAILURE: {
      return {
        ...state,
        submitting: false,
        submitted: false,
        error: payload,
      };
    }
    default: {
      return {
        ...state,
        submitting: false,
        submitted: false,
      };
    }
  }
};

export default contactFormReducer;
