import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import Overlay from '../common/overlay';
import useWindowSize from '../../hooks/useWindowSize';
import cn from 'classnames';
import HorizontalAccordion from '../common/horizontalAccordion';

const Drawer = ({ show, handleShow, navItems, goToContactSection }) => {
  const router = useRouter();
  const { width } = useWindowSize();
  const [forceClose, setForceClose] = useState(false);

  const goTo = (link) => () => {
    if (link.includes('http')) {
      window.open(link, '_blank', 'noopener noreferrer');
    } else {
      router.push(link);
    }
    handleShow();
  };

  const closeDrawerAndGoToContactSection = () => {
    handleShow();
    goToContactSection();
  };

  useEffect(() => {
    if (!show) {
      setForceClose(true);
    } else {
      setForceClose(false);
    }
  }, [show]);

  return (
    <>
      <div
        id="drawer"
        className={cn('fixed px-10 z-50 bg-white', {
          'h-full overflow-x-hidden ease duration-300 break-words shadow transform -translate-x-full hidden': !show,
          'h-full overflow-x-hidden ease duration-700 break-words shadow transform translate-x-0 visible': show,
          'w-1/2': width >= 600,
          'w-full': width < 600,
        })}
      >
        <div id="drawer-scroller">
          <ul className="pt-6 relative">
            {navItems?.map(
              ({ category, link: catLink, render, subCategories }) =>
                render && (
                  <li key={`${category}-drawer-menue`}>
                    <HorizontalAccordion
                      forceClose={forceClose}
                      childContainerClasses="pt-6"
                      summary={category}
                      handleClick={goTo(catLink)}
                    >
                      {subCategories?.length > 0 && (
                        <ul className="pb-20">
                          {subCategories.map(({ subCategory, brands, link: subCatLink }) => (
                            <li key={`${subCategory}-drawer-menue`}>
                              <HorizontalAccordion
                                forceClose={forceClose}
                                childContainerClasses="pt-6"
                                summary={subCategory}
                                handleClick={goTo(catLink + subCatLink)}
                              >
                                <ul className="pb-20">
                                  {brands?.map(({ brand, products, link: brandLink }) => (
                                    <li key={`${brand}-drawer-menue`}>
                                      <HorizontalAccordion
                                        forceClose={forceClose}
                                        childContainerClasses="pt-6"
                                        summary={brand}
                                        handleClick={goTo(catLink + subCatLink + brandLink)}
                                      >
                                        {products.length > 0 && (
                                          <ul className="pb-20">
                                            {products?.map(({ product, link: prodLink }) => (
                                              <li key={`${product}-drawer-menue`}>
                                                <HorizontalAccordion
                                                  forceClose={forceClose}
                                                  childContainerClasses="pt-6"
                                                  summary={product}
                                                  handleClick={goTo(catLink + subCatLink + brandLink + prodLink)}
                                                />
                                              </li>
                                            ))}
                                            <li>
                                              <button
                                                className=" w-full ti-button can-hover:hover:bg-opacity-60 can-hover:hover:bg-dji-blue text-white bg-dji-blue text-sm p-2"
                                                onClick={closeDrawerAndGoToContactSection}
                                              >
                                                Request a Quote
                                              </button>
                                            </li>
                                            <li>
                                              <a
                                                href="https://store.terrestrialimaging.com/"
                                                target="_blank"
                                                className="ti-button text-sm p-2 w-full my-5 inline-block"
                                              >
                                                Visit The Store
                                              </a>
                                            </li>
                                          </ul>
                                        )}
                                      </HorizontalAccordion>
                                    </li>
                                  ))}
                                  <li>
                                    <button
                                      className=" w-full ti-button can-hover:hover:bg-opacity-60 can-hover:hover:bg-dji-blue text-white bg-dji-blue text-sm p-2"
                                      onClick={closeDrawerAndGoToContactSection}
                                    >
                                      Request a Quote
                                    </button>
                                  </li>
                                  <li>
                                    <a
                                      href="https://store.terrestrialimaging.com/"
                                      target="_blank"
                                      className="ti-button text-sm p-2 w-full my-5 inline-block"
                                    >
                                      Visit The Store
                                    </a>
                                  </li>
                                </ul>
                              </HorizontalAccordion>
                            </li>
                          ))}
                          <li>
                            <button
                              className=" w-full ti-button can-hover:hover:bg-opacity-60 can-hover:hover:bg-dji-blue text-white bg-dji-blue text-sm p-2"
                              onClick={closeDrawerAndGoToContactSection}
                            >
                              Request a Quote
                            </button>
                          </li>
                          <li>
                            <a
                              href="https://store.terrestrialimaging.com/"
                              target="_blank"
                              className="ti-button text-sm p-2 w-full my-5 inline-block"
                            >
                              Visit The Store
                            </a>
                          </li>
                        </ul>
                      )}
                    </HorizontalAccordion>
                  </li>
                ),
            )}
            <li>
              <button
                className=" w-full ti-button can-hover:hover:bg-opacity-60 can-hover:hover:bg-dji-blue text-white bg-dji-blue text-sm p-2"
                onClick={closeDrawerAndGoToContactSection}
              >
                Request a Quote
              </button>
            </li>
            <li>
              <a
                href="https://store.terrestrialimaging.com/"
                target="_blank"
                className="ti-button text-sm p-2 w-full my-5 inline-block"
              >
                Visit The Store
              </a>
            </li>
          </ul>
        </div>
      </div>
      <Overlay show={show} handleShow={handleShow} overlayClasses="!absolute h-screen !top-15" />
    </>
  );
};

export default Drawer;
